<script setup lang="ts">

import GFBalanceSheet from '@/components/Sections/Government/Finances/Elements/GFBalanceSheet.vue'
import GFBalance from '@/components/Sections/Government/Finances/Elements/GFBalance.vue'
import GFCreditHistory from '@/components/Sections/Government/Finances/Elements/GFCreditHistory.vue'
import { useGameStore } from '@/stores/gameStore.ts'

const gameStore = useGameStore()

</script>

<template>
  <div>
    <div class="pr-2">
      <img :src="gameStore.cdn + 'images/interface/finances.webp'" alt="Finances" class="rounded">
    </div>
    <div class="flex flex-col lg:flex-row pt-2">
      <div class="pr-2 md:pr-0 w-full lg:w-1/2">
        <GFBalanceSheet></GFBalanceSheet>
      </div>
      <div class="pr-2 w-full lg:w-1/2 pl-0 lg:pl-2">
        <GFBalance></GFBalance>
        <GFCreditHistory></GFCreditHistory>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>