<script setup lang="ts">

import { useAssetStore } from '@/stores/assetStore.ts'
import { defineProps, computed } from 'vue'
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import { CDN } from '@/models/cdn.ts'
import NamedNumbers from '@/components/Elements/Generic/NamedNumbers.vue'
import GenericIconWithColoredNumber from '@/components/Elements/Images/Icons/GenericIconWithColoredNumber.vue'
import { useGameStore } from '@/stores/gameStore.ts'

/**
 * Props
 */
const props = defineProps({
  ministerCode: {
    type: String,
    required: true
  }
})

/**
 * Stores / models
 */
const assetStore = useAssetStore()
const gameStore = useGameStore()

/**
 * Computed
 */
const data = computed(() => {
  return assetStore.findMinisterByCode(props.ministerCode)!
})

const ministryData = computed(() => {
  return assetStore.findMinistryByMinisterCode(props.ministerCode)!
})

const fired = computed(() => {
  const data = gameStore.playerMinisters.get(props.ministerCode)
  if (data) {
    return data.fired == 1
  } else {
    return false
  }
})

/**
 * Functions
 */
const openMinister = () => {
  gameStore.modalType = 'ministryCandidate'
  gameStore.modalData = {
    code: ministryData.value.code
  }
}

</script>

<template>
  <StandardCard :title="data.name" top-padding="0" @click="openMinister" class="cursor-pointer">
    <div class="relative">
      <img
          :src="CDN.ministerImage(data.code)"
          alt="Minister image"
          :class="{ 'rounded': true, 'grayscale': fired }"
      />
      <div v-if="fired" class="absolute inset-0 flex items-center justify-center z-10">
        <div class="text-5xl font-bold text-red-500" style="text-shadow: 0 0 8px white;">
          FIRED
        </div>
      </div>
    </div>

    <div class="pt-4 pb-2 flex justify-center">
      <NamedNumbers text="Salary">
        <GenericIconWithColoredNumber v-if="!fired" :amount="-data.salary" type="credit" />
        <span v-else>N/A</span>
      </NamedNumbers>
    </div>
  </StandardCard>

</template>

<style scoped lang="scss">

</style>