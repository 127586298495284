<script setup lang="ts">

import { usePlanetStore } from '@/stores/planetStore.ts'
import { computed, defineProps, ref, onMounted, watch } from 'vue'
import Loader from '@/components/Elements/Loaders/Loader.vue'
import Backend from '@/models/backend.ts'
import ContentItem from '@/components/Sections/Planets/PlanetTabs/StatsTab/ContentItem.vue'
import { useAssetStore } from '@/stores/assetStore.ts'
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'

/**
 * Props
 */
const props = defineProps({
  planetId: {
    type: Number,
    required: true
  }
});

/**
 * Stores / models
 */
const planetStore = usePlanetStore()
const assetStore = useAssetStore()

/**
 * Variables
 */
const loading = ref(true)
const stats = ref<Record<string, any>>({}); // Use a specific type instead of `Record<string, any>` if possible

/**
 * Computed
 */
const planetData = computed(() => planetStore.findPlanetById(props.planetId)!)

const title = computed(() => {
  if(planetStore.planetStatsTab === 'food') {
    return 'Food Yield'
  } else if (planetStore.planetStatsTab === 'production') {
    return 'Production'
  } else if (planetStore.planetStatsTab === 'science') {
    return 'Science'
  } else if (planetStore.planetStatsTab === 'credit') {
    return 'Credits'
  } else if (planetStore.planetStatsTab === 'energy') {
    return 'Energy'
  } else if (planetStore.planetStatsTab === 'energy_storage') {
    return 'Energy Storage'
  } else if (planetStore.planetStatsTab === 'ore_storage') {
    return 'Ore Storage'
  } else if (planetStore.planetStatsTab === 'scanning_range') {
    return 'Scanning Range'
  } else if (planetStore.planetStatsTab === 'defense_attack_ships') {
    return 'Active Defense'
  } else if (planetStore.planetStatsTab === 'shields') {
    return 'Passive Defense'
  } else if (planetStore.planetStatsTab === 'primary_ore') {
    return assetStore.findOreById(planetData.value.primaryOreId).oreName
  } else if (planetStore.planetStatsTab === 'secondary_ore') {
    return assetStore.findOreById(planetData.value.secondaryOreId).oreName
  }
  return 'Unknown'
})

const description = computed(() => {
  if(planetStore.planetStatsTab === 'food') {
    return 'Food allows the population of your planet to grow, but is also required to maintain a large population.'
  } else if (planetStore.planetStatsTab === 'production') {
    return 'Production allows you to build ships and buildings, which are required to expand and grow your empire.'
  } else if (planetStore.planetStatsTab === 'science') {
    return 'Science allows you to research new technologies which gives you new capabilities.'
  } else if (planetStore.planetStatsTab === 'credit') {
    return 'Credits are needed to fund ship maintenance, salaries etc.'
  } else if (planetStore.planetStatsTab === 'energy') {
    return 'Energy is mainly used to power your planetary shields.'
  } else if (planetStore.planetStatsTab === 'energy_storage') {
    return 'Energy Storage allows you to store energy, making your shields more effective.'
  } else if (planetStore.planetStatsTab === 'ore_storage') {
    return 'Ore Storage allows you to store any ore from your empire.'
  } else if (planetStore.planetStatsTab === 'scanning_range') {
    return 'Scanning Range permits you to detect ships within range of your planet.'
  } else if (planetStore.planetStatsTab === 'defense_attack_ships') {
    return 'Attack strength is used to counter attack invading ships.'
  } else if (planetStore.planetStatsTab === 'shields') {
    return 'Defence Shields reduce damage from attackers.'
  } else if (planetStore.planetStatsTab === 'primary_ore') {
    return 'The primary ore extraction rate per week.'
  } else if (planetStore.planetStatsTab === 'secondary_ore') {
    return 'The secondary ore extraction rate per week.'
  }
  return 'Unknown'
})

const total = computed(() => {
  if(planetStore.planetStatsTab === 'food') {
    return stats.value.data.population_growth_per_turn
  } else if (planetStore.planetStatsTab === 'production') {
    return stats.value.data.production_per_turn
  } else if (planetStore.planetStatsTab === 'science') {
    return stats.value.data.science_per_turn
  } else if (planetStore.planetStatsTab === 'credit') {
    return stats.value.data.credits_per_turn
  } else if (planetStore.planetStatsTab === 'energy') {
    return stats.value.data.energy_per_turn
  } else if (planetStore.planetStatsTab === 'energy_storage') {
    return stats.value.data.energy_storage_capacity
  } else if (planetStore.planetStatsTab === 'ore_storage') {
    return stats.value.data.ore_storage_capacity
  } else if (planetStore.planetStatsTab === 'scanning_range') {
    return stats.value.data.scan_range
  } else if (planetStore.planetStatsTab === 'defense_attack_ships') {
    return stats.value.data.attack_ships
  } else if (planetStore.planetStatsTab === 'shields') {
    return stats.value.data.shields
  } else if (planetStore.planetStatsTab === 'primary_ore') {
    return stats.value.data.ore_per_turn
  } else if (planetStore.planetStatsTab === 'secondary_ore') {
    return stats.value.data.ore_per_turn
  }
  return 0
})

/**
 * Functions
 */
const loadData = function (): void {
  loading.value = true;
  const backend = new Backend();

  backend.loadPlanetStats(props.planetId, planetStore.planetStatsTab)
    .then((response: { data: Record<string, any> }) => { // Adjust type for `data` as per actual structure
      loading.value = false;
      stats.value = response.data;
      console.log('Loaded planet stats:', stats.value);
    })
    .catch((error: unknown) => {
      loading.value = false;
      console.error('Failed to load planet stats:', error);
    });
};

/**
 * Watchers
 */
watch(() => planetStore.planetStatsTab, () => {
  loadData()
})

watch(() => props.planetId, () => {
  loadData()
})

watch(() => [planetData.value.populationFoodPercentage,planetData.value.populationProductionPercentage,planetData.value.populationSciencePercentage,planetData.value.taxPercentage], () => {
  loadData()
})

/**
 * Lifecycle
 */
onMounted(() => {
  loadData()
})

</script>

<template>

  <div class="flex-grow">
    <StandardCard :title="title" topPadding="0">
    <div>
      <div class="text-sm text-subtext"> {{ description }}</div>
    </div>
    <div v-if="loading" class="flex justify-center">
      <Loader></Loader>
    </div>
    <div v-else class="">
      <div class="grid grid-cols-[1fr_auto] text-subtext bg-slate-900 mt-2 rounded p-1">
        <!--- Food Specific --->
        <ContentItem v-if="stats.data.from_workforce_farmers" :amount="stats.data.from_workforce_farmers"  text="Farmers"/>
        <ContentItem v-if="stats.data.from_population_maintenance" :amount="stats.data.from_population_maintenance"  text="Population Upkeep"/>
        <!--- Production Specific --->
        <ContentItem v-if="stats.data.from_workforce_workers" :amount="stats.data.from_workforce_workers"  text="Workers"/>
        <!--- Science Specific --->
        <ContentItem v-if="stats.data.from_workforce_scientists" :amount="stats.data.from_workforce_scientists"  text="Scientists"/>

        <!--- From Planet--->
        <ContentItem v-if="stats.data.from_planet" :amount="stats.data.from_planet"  text="Base"/>

        <!--- Buildings --->
        <template v-if="stats.data.from_buildings.length>0">
          <template v-for="building in stats.data.from_buildings" :key="building.id">
            <ContentItem :amount="building.amount"
                             :text="assetStore.getBuildingNameByCode(building.building_code)"
            ></ContentItem>
          </template>
        </template>

        <!--- Tax --->
        <ContentItem v-if="stats.data.from_tax" :amount="stats.data.from_tax"  text="Tax"/>

        <!--- Type Bonus/Penalty --->
        <ContentItem v-if="stats.data.type_bonus" :amount="stats.data.type_bonus"  text="Best Planet Type for Species"/>
        <ContentItem v-if="stats.data.type_penalty" :amount="stats.data.type_penalty"  text="Worst Planet Type for Species"/>

        <!--- Gravity Bonus/Penalty --->
        <ContentItem v-if="stats.data.gravity_bonus" :amount="stats.data.gravity_bonus"  text="Best Gravity for Species"/>
        <ContentItem v-if="stats.data.high_gravity_penalty" :amount="stats.data.high_gravity_penalty"  text="Gravity too high for Species"/>
        <ContentItem v-if="stats.data.low_gravity_penalty" :amount="stats.data.low_gravity_penalty"  text="Gravity too low for Species"/>

        <!--- Ferion Planet Bonus --->
        <ContentItem v-if="stats.data.ferion_bonus" :amount="stats.data.ferion_bonus"  text="Ferion Planet Bonus"/>

        <!--- Total --->
      </div>
      <div class="grid grid-cols-[1fr_auto] text-subtext bg-slate-900 mt-2 rounded p-1">
        <ContentItem :amount="total"  text="Total *"/>
      </div>
      <small class="text-subtext">* Rounded to closest number.</small>
    </div>
    </StandardCard>
  </div>
</template>

<style scoped lang="scss">
.content {
  @apply bg-slate-800 mb-1 flex-grow rounded-e;
}
</style>