<script setup lang="ts">
import SmallModel from "@/components/Interface/Modal/ModelSizes/SmallModel.vue";
import {useGameStore} from "@/stores/gameStore.ts";
import Backend from '@/models/backend.ts'
import { ref } from 'vue'
import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'
import ActionButton from '@/components/Elements/Buttons/ActionButton.vue'

// Get the planet data
const gameStore = useGameStore()
const backend = new Backend()

const foodSlider = ref(100)
const scienceSlider = ref(100)
const productionSlider = ref(100)

// Handle form submission
const submit = () => {
  backend.updatePlanetPopulation(
    gameStore.modalData,
    foodSlider.value,
    scienceSlider.value,
    productionSlider.value
  ).then((result) => {
    gameStore.modalType=''
  })
}

</script>

<template>
  <SmallModel title="Set population assignments">
    <div class="p-2">
      <div class="text-subtext pb-2">Select what your population should work on for the selected planets</div>
      <div class="xl:flex justify-between pl-2 pr-2">
        <div class="flex-1 p-2 flex">
          <img class="w-10 h-10 mr-3 rounded-3xl" :src="'' + gameStore.cdn + 'images/population/64/food.webp'" alt="Farmers" title="Farmers">
          <div class="relative mb-6 flex-grow">
            <label for="labels-range-input" class="sr-only">Labels range</label>
            <input
              id="population_food"
              min="0"
              max="100"
              step="1"
              v-model="foodSlider"
              type="range"
              class="w-full h-2 rounded-lg appearance-none cursor-pointer bg-gray-700">
            <span class="text-subtext absolute start-0 -bottom-6">Farmers: <span class="text-white">{{ foodSlider }}%</span></span>
          </div>
        </div>
        <div class="flex-1 p-2 flex">
          <img class="w-10 h-10 mr-3 rounded-3xl" :src="'' + gameStore.cdn + 'images/population/64/production.webp'" alt="Workers" title="Workers">
          <div class="relative mb-6 flex-grow">
            <label for="labels-range-input" class="sr-only">Labels range</label>
            <input
              id="population_production"
              min="0"
              max="100"
              step="1"
              v-model="productionSlider"
              type="range"
              class="w-full h-2 rounded-lg appearance-none cursor-pointer bg-gray-700">
            <span class="text-subtext absolute start-0 -bottom-6">Workers: <span class="text-white">{{ productionSlider }}%</span></span>
          </div>
        </div>
        <div class="flex-1 p-2 flex">
          <img class="w-10 h-10 mr-3 rounded-3xl" :src="'' + gameStore.cdn + 'images/population/64/research.webp'" alt="Scientists" title="Scientists">
          <div class="relative mb-6 flex-grow">
            <label for="labels-range-input" class="sr-only">Labels range</label>
            <input
              id="population_science"
              min="0"
              max="100"
              step="1"
              v-model="scienceSlider"
              type="range"
              class="w-full h-2 rounded-lg appearance-none cursor-pointer bg-gray-700">
            <span class="text-subtext absolute start-0 -bottom-6 whitespace-nowrap">Scientists: <span class="text-white">{{ scienceSlider }}%</span></span>
          </div>
        </div>
      </div>
      <div class="pt-2 text-center"><ActionButton @click="submit">Submit</ActionButton></div>
    </div>
  </SmallModel>
</template>


<style scoped lang="scss">
select {
  @apply bg-slate-600 p-1 text-slate-400 focus:outline-none
}
</style>