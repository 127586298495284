import { defineStore } from 'pinia'
import { usePlayerRelationStore } from '@/stores/playerRelations.ts'
import { useMapCoordinateStore } from '@/stores/mapCoordinateStore.ts'
import { useGameStore } from '@/stores/gameStore.ts'
import { usePlanetStore } from '@/stores/planetStore.ts'
import { useShipStore } from '@/stores/shipStore.ts'
import Backend from '@/models/backend.ts'

export const useLeaderBoardStore = defineStore('newsLeaderBoardStore', {
    state: () => ({
        players: new Map<number, {
            id: number,
            playerId: number,
            planets: number,
            ships: number,
            name: string,
            relation: string,
            country: string,
            profileImage: string,
            exact: boolean,
            currentTechId: number,
            otherRequested: string
        }>(),
        dayAllyDataRetrieved: 0, //The day the ally data was last retrieved, to prevent spamming the backend
        allyData: {}
    }),
    getters: {
    },
    actions: {
        async generateLeaderBoard() {
            const gameStore = useGameStore()
            const backend = new Backend()
            if (this.dayAllyDataRetrieved != gameStore.turn) {
                await backend.getAllyData().then((data) => {
                    this.allyData = data.data;
                })
            }
            await this.buildLeaderBoard()
            this.dayAllyDataRetrieved = gameStore.turn;
        },
        async buildLeaderBoard() {
            const gameStore = useGameStore();
            const mapCoordinateStore = useMapCoordinateStore();
            const relationStore = usePlayerRelationStore();
            const planetStore = usePlanetStore();
            const shipStore = useShipStore();

            this.players.clear();

            if (gameStore.player) {
                this.players.set(gameStore.player.id, {
                    id: gameStore.player.id,
                    playerId: gameStore.player.id,
                    planets: planetStore.planets.size,
                    ships: shipStore.ships.size,
                    name: gameStore.player.nickName,
                    relation: 'self',
                    country: gameStore.player.country,
                    profileImage: gameStore.player.profileImage,
                    exact: true,
                    currentTechId: gameStore.player.currentTechId,
                    otherRequested: 'none'
                });
            }

            //Add all known players to the list
            for (const [key, value] of relationStore.relations.entries()) {
                this.players.set(key, {
                    id: key,
                    playerId: key,
                    planets: 0,
                    ships: 0,
                    name: value.name,
                    relation: value.relation,
                    country: value.country,
                    profileImage: value.profileImage,
                    exact: false,
                    currentTechId: 0,
                    otherRequested: value.otherRequested
                });
            }

            //Go over map data and generate a leaderboard list
            for (const [key, value] of mapCoordinateStore.planets.entries()) {
                if (this.players.has(value.playerId)) {
                    const player = this.players.get(value.playerId);
                    if (player && value.playerId !== gameStore.player?.id) {
                        player.planets++;
                        this.players.set(value.playerId, player);
                    }

                }
            }

            //Go over ship data and generate a leaderboard list
            for (const [key, value] of mapCoordinateStore.fleets.entries()) {
                if (this.players.has(value.playerId)) {
                    const player = this.players.get(value.playerId);
                    if (player && value.playerId !== gameStore.player?.id) {
                        player.ships += value.ships;
                        this.players.set(value.playerId, player);
                    }
                }
            }

            //apply ally data from backend. This will overwrite any existing data
            const allyArray: {
                playerId: number,
                currentTechId: number,
                planets: number,
                ships: number
            }[] = Object.values(this.allyData);
            for (const ally of allyArray) {
                if (this.players.has(ally.playerId)) {
                    const player = this.players.get(ally.playerId);
                    if (player) {
                        player.exact = true;
                        player.currentTechId = ally.currentTechId;
                        player.planets = ally.planets;
                        player.ships = ally.ships;
                        this.players.set(ally.playerId, player);
                    }
                }
            }

        }
    }
})
