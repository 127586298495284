<script setup lang="ts">
import SmallModel from "@/components/Interface/Modal/ModelSizes/SmallModel.vue";
import {useGameStore} from "@/stores/gameStore.ts";
import Backend from '@/models/backend.ts'
import { ref } from 'vue'
import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'
import ActionButton from '@/components/Elements/Buttons/ActionButton.vue'

// Get the planet data
const gameStore = useGameStore()
const backend = new Backend()

const taxSlider = ref(10)

// Handle form submission
const submit = () => {
  backend.updatePlanetTax(
    gameStore.modalData,
    taxSlider.value
  ).then((result) => {
    gameStore.modalType=''
  })
}

</script>

<template>
  <SmallModel title="Set population assignments">
    <div class="p-2">
      <div class="text-subtext pb-2">Set the tax rate for the selected planets</div>
      <div class="xl:flex justify-between pl-2 pr-2">
        <div class="flex-1 p-2 flex">
          <img class="w-10 h-10 mr-3 rounded-3xl" :src="'' + gameStore.cdn + 'images/population/64/taxman.webp'" alt="">
          <div class="relative mb-6 flex-grow">
            <label for="labels-range-input" class="sr-only">Labels range</label>
            <input
              type="range"
              id="tax"
              min="0"
              max="100"
              step="10"
              v-model="taxSlider"
              class="w-full h-2 rounded-lg appearance-none cursor-pointer bg-gray-700">
            <span class="text-subtext absolute start-0 -bottom-6">Tax: <span class="text-slate-300">{{ taxSlider }}%</span></span>
          </div>
        </div>
      </div>
      <div class="pt-2 text-center"><ActionButton @click="submit">Submit</ActionButton></div>
    </div>
  </SmallModel>
</template>


<style scoped lang="scss">
select {
  @apply bg-slate-600 p-1 text-slate-400 focus:outline-none
}
</style>