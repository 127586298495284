<script setup lang="ts">

import type NewsItem from "@/types/NewsItem.ts";
import ModalTrigger from "@/components/Interface/Modal/ModalTrigger.vue";
import { computed } from 'vue'
import { useAssetStore } from '@/stores/assetStore.ts'
import NewsItemBaseStructure from '@/components/Sections/News/Types/Base/NewsItemBaseStructure.vue'
import NewsItemBase from '@/components/Sections/News/Types/Base/NewsItemBase.vue'
import ImageRounded from '@/components/Interface/Images/ImageRounded.vue'
import { useGameStore } from '@/stores/gameStore.ts'
import { CDN } from '@/models/cdn.ts'
import ImageRoundedUrl from '@/components/Interface/Images/ImageRoundedUrl.vue'
import GenericIcon from '@/components/Elements/Images/Icons/GenericIcon.vue'
import NamedNumbers from '@/components/Elements/Generic/NamedNumbers.vue'

const assetStore = useAssetStore();

const props = defineProps<{
  newsItem: NewsItem,
  toast: boolean
}>();

const tech = computed(() => {
  return assetStore.findTechById(props.newsItem.contentData.techId)!
})

const provideIconsBuildings = computed<string[]>(() => {
  const icons: string[] = [];

  for (const benefit of tech.value.provides) {
    if (benefit.type === 'buildings') {
      const effects = assetStore.getBuildingEffectsByCode(benefit.code) as any;
      for (const effect of effects) {
        if (effect.bonus > 0) {
          const type = CDN.normalize(effect.type);
          // Prevent duplicate icons
          if (!icons.includes(type)) {
            icons.push(type);
          }
        }
      }
    }
  }

  return icons;
});

const provideIconsShipComponents = computed<string[]>(() => {
  const icons: string[] = [];

  for (const benefit of tech.value.provides) {
    if (benefit.type === 'ship_components') {
      const effects = assetStore.getShipComponentEffectsByCode(benefit.code) as any
      for (const effect of effects) {
        if (effect.modifier > 0) {
          if (!['ship_mass', 'ship_production_cost', 'ore'].includes(effect.type)) {
            const type = CDN.normalize(effect.type);
            if (!icons.includes(type)) {
              icons.push(type);
            }
          }
        }
      }
    }
  }

  return icons;
});


</script>

<template>
  <NewsItemBase
    :toast="toast"
    :toast-image="CDN.technology(128,tech.code)"
    :toast-text="'You have discovered ' + tech.name + '!'"
  >
    <ModalTrigger type="tech" :data="{'techId': newsItem.contentData.techId }" >
      <NewsItemBaseStructure
        :newsItem="props.newsItem"
        :item-image="CDN.technology(256,tech.code)"
        :header="'You have discovered ' + tech.name + '!'"
      >
        <div class="flex flex-col justify-between h-full">
          <div class="flex-grow h-7 overflow-hidden text-ellipsis custom-line-clamp mb-1">
            {{ tech.description }}
          </div>
          <div class="flex flex-row justify-end">
            <div class="pt-2 ms:pt-0">
              <NamedNumbers v-if="provideIconsBuildings.length>0" text="Buildings">
                <template v-for="(icon, index) in provideIconsBuildings" :key="icon">
                  <GenericIcon :type="icon" :class="{ 'ml-2': index > 0 }" />
                </template>
              </NamedNumbers>
            </div>
            <div class="pt-2 ms:pt-0">
              <NamedNumbers v-if="provideIconsShipComponents.length>0" text="Ship Components" class="ps-2">
                <template v-for="(icon, index) in provideIconsShipComponents" :key="icon">
                  <GenericIcon :type="icon" :class="{ 'ml-2': index > 0 }" />
                </template>
              </NamedNumbers>
            </div>
          </div>
        </div>
      </NewsItemBaseStructure>
    </ModalTrigger>
  </NewsItemBase>

</template>

<style scoped lang="scss">
.custom-line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 5; /* Limit to 5 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

</style>