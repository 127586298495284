<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import { useAssetStore } from '@/stores/assetStore.ts'
import { useGameStore } from '@/stores/gameStore.ts'
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import GenericIcon from '@/components/Elements/Images/Icons/GenericIcon.vue'
import GenericIconWithColoredNumber from '@/components/Elements/Images/Icons/GenericIconWithColoredNumber.vue'
import ActionButton from '@/components/Elements/Buttons/ActionButton.vue'
import { CDN } from '@/models/cdn.ts'

/**
 * Props
 */
const props = defineProps({
  code: {
    type: String,
    required: true
  }
})

/**
 * Stores / models
 */
const assetStore = useAssetStore()
const gameStore = useGameStore()

/**
 * Variables
 */
const side = ref<'left' | 'right' | ''>('')
const picked = ref(false)
const fired = ref(false)

/**
 * Computed
 */
const data = computed(() => {
  return assetStore.findMinistryByCode(props.code)
})

const name = computed(() => {
  if(side.value === 'left') {
    return data.value.left.name
  } else if (side.value === 'right') {
    return data.value.right.name
  } else {
    return ''
  }
})

const cost = computed(() => {
  if(side.value === 'left') {
    return data.value.left.cost
  } else if (side.value === 'right') {
    return data.value.right.cost
  } else {
    return data.value.left.cost
  }
})

const salary = computed(() => {
  if(side.value === 'left') {
    return data.value.left.salary
  } else if (side.value === 'right') {
    return data.value.right.salary
  } else {
    return data.value.left.salary
  }
})

/**
 * Functions
 */
const openPopup = () => {
  gameStore.modalType = 'ministryCandidate'
  gameStore.modalData = {
    code: props.code
  }
}

/**
 * Lifecycle
 */
onMounted(() => {
  if(gameStore.playerMinisters.has(data.value.left.code)) {
    const minister = gameStore.playerMinisters.get(data.value.left.code)!
    if(minister.fired === 1) {
      fired.value = true
    }
    side.value = 'left'
    picked.value = true
  } else if(gameStore.playerMinisters.has(data.value.right.code)) {
    const minister = gameStore.playerMinisters.get(data.value.right.code)!
    if(minister.fired === 1) {
      fired.value = true
    }
    side.value = 'right'
    picked.value = true
  }
})

/**
 * Watchers
 */
watch(() => gameStore.playerMinisters, () => {
  if(gameStore.playerMinisters.has(data.value.left.code)) {
    const minister = gameStore.playerMinisters.get(data.value.left.code)!
    if(minister.fired === 1) {
      fired.value = true
    }
    side.value = 'left'
    picked.value = true
  } else if(gameStore.playerMinisters.has(data.value.right.code)) {
    const minister = gameStore.playerMinisters.get(data.value.right.code)!
    if(minister.fired === 1) {
      fired.value = true
    }
    side.value = 'right'
    picked.value = true
  }
},{deep:true})

</script>

<template>
  <StandardCard :title="data.name" class="w-72" @click="openPopup">
  <div class="bg-slate-800 rounded relative overflow-hidden cursor-pointer">
    <!--- Left Candidate -->
    <div v-if="fired" class="absolute inset-0 flex items-center justify-center z-10">
      <div class="text-5xl font-bold text-red-500" style="text-shadow: 0 0 8px white;">
        FIRED
      </div>
    </div>
    <div v-if="gameStore.playerMinisters.has(data.left.code)" :class="{'grayscale': fired}">
      <img
        :src="CDN.ministerImage(data.left.code)"
        alt="Minister"
        class="rounded"
      />
    </div>
    <div v-else-if="gameStore.playerMinisters.has(data.right.code)" :class="{'grayscale': fired}">
      <img
        :src="CDN.ministerImage(data.right.code)"
        alt="Minister"
        class="rounded"
      />
    </div>
    <div v-else class="image-container">
      <img
        :src="CDN.ministerImage(data.left.code)"
        alt="Minister"
        class="image-left grayscale rounded"
      />
      <div class="image-right-container">
        <div class="">
          <img
            :src="CDN.ministerImage(data.right.code)"
            alt="Minister"
            class="image-right grayscale rounded"
            :class="{
            'image-right-l': side === 'right',
            'image-right-r': side === 'left'
          }"
          />
        </div>
      </div>
    </div>
    <div class="flex">
      <div v-if="name" class="flex flex-grow justify-between rounded mt-2 p-2 bg-slate-700 text-slate-300">
        <div>{{ name }}</div>
        <GenericIconWithColoredNumber v-if="salary!=0 && !fired" :amount="-salary" type="credit"/>
      </div>
      <div v-else class="flex-grow rounded mt-2 p-1 bg-slate-700">
        <ActionButton :full-width="true">
          <div class="flex justify-center">
            <div class="pr-4">Pick Candidate</div>
            <div>
              <div v-if="cost==1" class="flex">
                <GenericIcon type="vip" tooltip="Vip token" />
              </div>
              <div v-else class="flex">
                <GenericIcon type="vip" tooltip="Vip token"/><div class="pl-1">x{{ cost }}</div>
              </div>
            </div>
          </div>
        </ActionButton>
      </div>
    </div>
  </div>
  </StandardCard>
</template>

<style scoped lang="scss">
.image-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%; /* Forces a square aspect ratio */
}

.image-left,
.image-right {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  transition: clip-path 0.5s, filter 0.3s; /* Add transition for filter */
  filter: grayscale(100%); /* Grayscale by default */
}


.image-right {
  clip-path: polygon(
      50% 0,
      100% 0,
      100% 100%,
      50% 100%
  );
}

.image-right-l {
  clip-path: polygon(
      10% 0,
      100% 0,
      100% 100%,
      10% 100%
  );
}

.image-right-r {
  clip-path: polygon(
      90% 0,
      100% 0,
      100% 100%,
      90% 100%
  );
}
</style>
