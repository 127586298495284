<script setup lang="ts">

import type NewsItem from '@/types/NewsItem.ts'
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import { computed } from 'vue'

const props = defineProps<{
  itemImage: string,
  newsItem: NewsItem,
  header: string
}>();

const title = computed (() => {
  return '<div class="flex justify-between"><div class="overflow-hidden text-ellipsis whitespace-nowrap">' + props.header + '</div><div class="px-2">' + status.value + '</div></div>'
})

const status = computed (() => {
  if(props.newsItem.isRead) {
    return 'Day ' + props.newsItem.turn
  } else {
    return '<span class="text-red-500">New!</span>'
  }
})

</script>

<template>
  <StandardCard :title="title" top-padding="0" :no-padding="true" :class="{
    'shadow-even-md shadow-cyan-500': !props.newsItem.isRead,
  }">
  <div class="flex h-40">
    <div class="flex-shrink-0 border-none sm:border-e border-e-slate-700 md:p-2">
      <img
        :src="itemImage"
        class="rounded w-36 h-36 object-cover hidden md:block"
        alt="itemImage"
      />
    </div>
    <div class="flex-grow p-2 ps-1">
      <slot></slot>
    </div>
  </div>
  </StandardCard>
</template>

<style scoped lang="scss">

</style>