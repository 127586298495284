<script setup lang="ts">

import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import GenericIcon from '@/components/Elements/Images/Icons/GenericIcon.vue'
import ActionButton from '@/components/Elements/Buttons/ActionButton.vue'
import { useShipDesignCreationStore } from '@/stores/shipDesignCreationStore.ts'

/**
 * Stores / models
 */
const shipDesignCreationStore = useShipDesignCreationStore()

</script>

<template>
  <StandardCard title="How to design my ship?" top-padding="0">
    <p class="pb-2">The first step in designing a ship is choosing a frame <GenericIcon tooltip="Ship frame" type="frame"/>. Each frame has a maximum mass <GenericIcon type="mass"/> it can support.</p>
    <p>Modules also have a mass, and you can add modules to your design as long as the total mass does not exceed the frame's capacity.</p>
  </StandardCard>
  <StandardCard>
    <p class="pb-2">The second step in designing your ship is selecting an engine. Each engine comes with two key properties: maximum speed <GenericIcon tooltip="Engine speed" type="max_speed"/> and thrust <GenericIcon tooltip="Engine thrust" type="thrust"/>.</p>
    <p class="pb-2">Thrust determines how much mass the engine can propel effectively. As the ship's mass increases (due to added components, weapons, shields, etc.), the engine's thrust may become insufficient, causing the ship's maximum speed to decrease. To compensate, you can add additional engines to maintain the desired speed.</p>
    <p>However, the ship's maximum speed cannot exceed the maximum speed of the fastest engine installed. This means your fastest engine sets the speed limit for the entire design, regardless of how many engines are added.</p>
  </StandardCard>
  <StandardCard>
    <p>Remember that the more modules you add, the more expensive the ship will be to build <GenericIcon tooltip="Production cost" type="production"/>.</p>
  </StandardCard>
  <StandardCard>
    <p>Finally you can add any modules as you wish and save your design. Then you can instruct your planets to build ships from your new design.</p>
  </StandardCard>
  <StandardCard>
    <div class="flex justify-center">
      <ActionButton @click="shipDesignCreationStore.createModalTab = 'add'">Let's start designing!</ActionButton>
    </div>
  </StandardCard>
</template>

<style scoped lang="scss">

</style>