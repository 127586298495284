<template>
  <!-- Overlay -->
  <div class="relative w-full">
    <div class="fixed bottom-12 right-auto w-full md:w-auto justify-center md:absolute md:right-0 md:bottom-auto">
      <MapZoom></MapZoom>
    </div>
    <SettleLegend v-if="shipActionStore.pendingAction==='settle'"/>
    <MapTargetHud/>
    <MapSideBar/>
    <!-- Top Bar -->

  </div>
  <!-- WebGL Container -->
  <div class="webgl-container content-height-limit-map z-10" ref="container" @contextmenu.prevent></div>
  <MapToolTip :tool-tip-visible="mapStore.toolTipVisible"></MapToolTip>
  <MapPlayerNameToolTip></MapPlayerNameToolTip>
  <AreaSelectionOptions v-if="mapStore.areaActionSelecting"/>
  <div class="absolute bottom-10 left-1 hidden md:block">
    {{ mapStore.mouseXPos }} / {{ mapStore.mouseYPos }}
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted, watch, computed } from 'vue'
import * as THREE from 'three';
import { useMapStore } from '@/stores/mapStore.ts';
import type MapCoordinate from '@/types/MapCoordinate.ts';
import emitter from '@/models/eventBus.ts';
import MapToolTip from '@/components/Sections/Map/Galaxy/WebGLMap/MapObjects/MapToolTip.vue';
import MapZoom from "@/components/Sections/Map/Galaxy/WebGLMap/MapObjects/MapZoom.vue";
import { usePlanetStore } from '@/stores/planetStore.ts'
import { ShipActionPath } from "@/models/Map/MapShipActionPath.ts";
import MapTargetHud from "@/components/Sections/Map/Galaxy/WebGLMap/MapObjects/MapTargetHud.vue";
import {useShipActionStore} from "@/stores/shipActionStore.ts";
import { useRoute } from 'vue-router'
import type PlayerRelation from '@/types/PlayerRelation.ts'
import { MapMouseController } from '@/models/Map/MapMouseController.ts'
import { useShipStore } from '@/stores/shipStore.ts'
import { useMapCoordinateStore } from '@/stores/mapCoordinateStore.ts'
import { useMapShipStore } from '@/stores/mapShipStore.ts'
import { useMapPlanetOwnerStore } from '@/stores/mapPlanetOwnerStore.ts'
import { useMapPlanetStore } from '@/stores/mapPlanetStore.ts'
import { useMapStarStore } from '@/stores/mapStarStore.ts'
import { useMapPendingSettleStore } from '@/stores/mapPendingSettleStore.ts'
import AreaSelectionOptions from '@/components/Sections/Map/Galaxy/WebGLMap/MapObjects/AreaSelectionOptions.vue'
import MapPlayerNameToolTip from '@/components/Sections/Map/Galaxy/WebGLMap/MapObjects/MapPlayerNameToolTip.vue'
import { MapBlackHole } from '@/models/Map/MapBlackHole.ts'
import MapSideBar from '@/components/Sections/Map/Galaxy/WebGLMap/MapObjects/MapSideBar/MapSideBar.vue'
import SettleLegend from '@/components/Sections/Map/Galaxy/WebGLMap/MapObjects/SettleLegend.vue'
import { MapCrossHairInit } from '@/models/Map/MapCrosshair.ts'

// Store references and states
const mapStore = useMapStore();
const mapCoordinateStore = useMapCoordinateStore()
const mapShipStore = useMapShipStore()
const mapPlanetOwnerStore = useMapPlanetOwnerStore()
const mapPlanetStore = useMapPlanetStore();
const mapStarStore = useMapStarStore()
const mapPendingSettleStore = useMapPendingSettleStore()

const shipStore = useShipStore()
const planetStore = usePlanetStore();
const shipActionStore = useShipActionStore();
const container = ref<HTMLDivElement | null>(null);
const toolTipVisible = ref(false);
const route = useRoute()

let mouseController: MapMouseController
let scene: THREE.Scene;
let camera: THREE.PerspectiveCamera;
let renderer: THREE.WebGLRenderer;


/**
 * Initialize the map
 */
onMounted(async () => {

  /**
   * Create the WebGL renderer and camera
   */
  renderer = new THREE.WebGLRenderer({ antialias: true, powerPreference: 'high-performance', alpha: true});
  if (container.value) {
    renderer.setSize(container.value.clientWidth, container.value.clientHeight);
    container.value.appendChild(renderer.domElement);
  }


  camera = new THREE.PerspectiveCamera(45, container.value ? container.value.clientWidth / container.value.clientHeight : 1, 1, 500);
  camera.position.set(0, 0, 400);
  camera.lookAt(0, 0, 0);

  camera.position.x = 1000;
  camera.position.y = 1000;

  scene = new THREE.Scene();
  mapStore.scene = scene;

  /** Store the scene in the mapCoordinateStore */
  mapCoordinateStore.scene = scene;

  /** Load the Instanced Meshes */
  mapPlanetOwnerStore.initPlanetOwnerInstancedMesh(10000) // Small
  if(mapPlanetOwnerStore.planetOwnerInstancedMesh) {
    scene.add(mapPlanetOwnerStore.planetOwnerInstancedMesh);
  }

  mapPlanetStore.initPlanetInstancedMesh(10000) // Small
  if(mapPlanetStore.planetInstancedMesh) {
    scene.add(mapPlanetStore.planetInstancedMesh);
  }

  mapShipStore.initShipInstancedMesh(20000) // Enough?
  if(mapShipStore.shipInstancedMesh) {
    scene.add(mapShipStore.shipInstancedMesh);
  }

  mapStarStore.initStarInstancedMesh(1200)
  if(mapStarStore.starInstancedMesh) {
    scene.add(mapStarStore.starInstancedMesh);
  }

  mapPendingSettleStore.initObjectInstancedMesh(10000)
  if(mapPendingSettleStore.objectInstancedMesh) {
    scene.add(mapPendingSettleStore.objectInstancedMesh);
  }

  /** Tests **/
  mapShipStore.addShip(-0,-0,0xFFFFFF)
  mapPlanetOwnerStore.addPlanetOwner(-0,-0,0xFFFFFF)
  mapPlanetStore.addPlanet(-0,-0,1,5)
  mapStarStore.addStar(-0,-0,1,4)
  mapPendingSettleStore.addObject(-0,-0, 0xFFFFFF)

  /**
   * Add black hole
   */
  new MapBlackHole(scene);

  /**
   * CrossHairs
   */
  new MapCrossHairInit(scene,camera);

  /**
   * Init Ship Action Path
   */
  mapStore.shipActionPath = new ShipActionPath(scene);

  /**
   * Init Mouse Controller
   */
  if (container.value) {
    mouseController = new MapMouseController(
      container.value,
      camera,
      scene,
      renderer
    )
  }

  // Listen to mapCoordinateUpdate events from Websockets
  emitter.on('mapCoordinateUpdate', (data) => {
    mapCoordinateStore.process(data as MapCoordinate);
  });

// Listen to mapRelationUpdate events from Websockets
  emitter.on('mapRelationUpdate', (data) => {
    mapCoordinateStore.reDrawPlayerAssets((data as PlayerRelation).playerId);
  });

  /**
   * Listen to mouse events
   */
  if (container.value) {
    window.addEventListener('resize', resizeRenderer);
  }

  // Backplane for detecting coordinates in space
  const geometry = new THREE.PlaneGeometry(10000, 10000);
  const material = new THREE.MeshBasicMaterial({
    transparent: true,
    opacity: 0
  });
  const plane = new THREE.Mesh(geometry, material);
  plane.name = 'backPlane';
  plane.position.z = -0.2
  scene.add(plane);


  /**
   * Start the animation loop
   */
  let clock = new THREE.Clock();
  let targetFPS = 60; // Target FPS
  let frameDuration = 1 / targetFPS;
  let timeSinceLastFrame = 0;

  function animate() {
    requestAnimationFrame(animate);

    // Calculate the time elapsed since last frame
    let delta = clock.getDelta();
    timeSinceLastFrame += delta;

    // Only render if enough time has passed
    if (timeSinceLastFrame > frameDuration) {
      renderer.render(scene, camera);
      camera.updateProjectionMatrix();
      timeSinceLastFrame = 0;  // Reset the frame time
    }
  }

  animate();


  /**
   * Pan to the coordinates if set
   */
  if(planetStore.planets.size > 0) {
    const firstPlanet = planetStore.planets.values().next().value;
    if (firstPlanet) {
      mapStore.gotoX = firstPlanet.xPos;
      mapStore.gotoY = firstPlanet.yPos;
    }
  }

  /**
   * Load the visible sectors
   */
  mapStore.loadVisibleSectors();

  renderer.render(scene, camera);
  camera.updateProjectionMatrix()
});


/**
 * Dispose the renderer and remove event listeners
 */
onUnmounted(() => {
  if (renderer) {
    renderer.dispose();
  }
  if (container.value) {
    window.addEventListener('resize', resizeRenderer);
  }
});

/**
 * Resize the renderer when the window is resized
 */
const resizeRenderer = () => {
  if (container.value && renderer) {
    if(container.value.clientWidth === 0 || container.value.clientHeight === 0) {
      return;
    }
    renderer.setSize(container.value.clientWidth, container.value.clientHeight);
    camera.aspect = container.value.clientWidth / container.value.clientHeight;
  }
};


watch(() => shipActionStore.requestMapReset, (newValue) => {
  if (newValue) {
    mapStore.shipActionPath.resetActionPath()
  }
})

/**
 * Watch the zoom factor and update the camera
 */
watch(() => mapStore.zoomFactor, (newValue) => {
  if (camera) {
    camera.zoom = newValue;
    mapCoordinateStore.applyZoomFactorAll(newValue)
  }
});

/**
 * Watch the gotoX and gotoY values and move the camera
 */
const gotoCoordinates = computed(() => ({
  x: mapStore.gotoX,
  y: mapStore.gotoY,
}));

watch(gotoCoordinates, (newCoords) => {
  if(newCoords.x === 0 && newCoords.y === 0) {
    return;
  }
  if(!camera) {
    return;
  }
  //move camera to new coordinates
  camera.position.x = newCoords.x;
  camera.position.y = newCoords.y;
  toolTipVisible.value = false;

  mapStore.gotoX = 0;
  mapStore.gotoY = 0;
  mapStore.crossHairVisible = true;

}, { deep: true });


/**
 * Watch the mouse position and update the store
 */
watch(() => route.name, (newValue) => {
  if (newValue?.toString().startsWith('MapGalaxy')) {
    // Resize the renderer with delay
    setTimeout(() => {
      resizeRenderer();
    }, 100);
  }
});

/**
 * Rerender the path
 */
watch(() => shipActionStore.redrawPath, () => {
  if(!shipActionStore.redrawPath) {
    return;
  }
  mapStore.shipActionPath.removePendingAction();
  mapStore.shipActionPath.setActionPath();
  shipActionStore.redrawPath = false;
});

watch (() => shipActionStore.pendingAction, (newValue,oldValue) => {
  if(newValue === 'settle') {
    //Mark all planets that are targeted for settlement
    const settleTargets = shipStore.getAllCoordsTargetedForSettlement()
    for(let i = 0; i < settleTargets.length; i++){
      const target = settleTargets[i];
      const instanceId = mapPendingSettleStore.addObject(target.x, target.y,0xFFFFFF)
      mapPendingSettleStore.scaleAndPositionObject(instanceId,target.x, target.y, mapStore.zoomFactor)
    }
    //Mark all planets that can be settled
    const planets = mapCoordinateStore.getSettleablePlanets()

    for(let i = 0; i < planets.length; i++){
      const target = planets[i];
      //Check if the coordinate is already in the settleTargets array
      if(settleTargets.find((coord: {x:number,y:number}) => coord.x === target.xPos && coord.y === target.yPos)){
        continue;
      }

      //Define color
      let color = 0x000000
      if (target.compatibility === 'A' || target.compatibility === 'F') {
        color = 0x00FF00; // Light Green
      } else if (target.compatibility === 'B') {
        color = 0xADD8E6; // Light blue
      } else if (target.compatibility === 'C') {
        continue;
      } else if (target.compatibility === 'D') {
        color = 0xFFFF00; // Yellow
      } else if (target.compatibility === 'E') {
        color = 0xFF0000; // Red
      }

      const instanceId = mapPendingSettleStore.addObject(target.xPos, target.yPos,color)
      mapPendingSettleStore.scaleAndPositionObject(instanceId,target.xPos, target.yPos, mapStore.zoomFactor)
    }

  } else if(oldValue === 'settle') {
    //Hide all planets that are targeted for settlement
    mapPendingSettleStore.clearObjects()
  }
})

</script>

<style scoped>
.webgl-container {
  width: 100%;
}

</style>
