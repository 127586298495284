<script setup lang="ts">

import {computed, defineProps} from "vue";
import {usePlanetStore} from "@/stores/planetStore";
import PlanetAnimation from '@/components/Elements/Animations/Planet/PlanetAnimation.vue'
import { CDN } from '@/models/cdn.ts'
import { useGameStore } from '@/stores/gameStore.ts'
import { useAssetStore } from '@/stores/assetStore.ts'
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import BuildingRequired from '@/components/Sections/Planets/PlanetTabs/Generic/BuildingRequired.vue'
import GenericIconWithColoredNumber from '@/components/Elements/Images/Icons/GenericIconWithColoredNumber.vue'

/**
 * Props
 */
const props = defineProps({
  planetId: {
    type: Number,
    required: true
  }
});

/**
 * Stores / models
 */
const planetStore = usePlanetStore()

/**
 * Computed
 */
const planetData = computed(() => planetStore.findPlanetById(props.planetId)!)

const data = computed(() => {

  //Calculate the percentage of the planet's shields
  const percentage = Math.round((planetData.value.energy / planetData.value.energyStorageCapacity) * 100);

  //Set colors for different percentages
  let color = 'text-green-500';
  if(percentage < 50 && percentage > 25) {
    color = 'text-yellow-500';
  } else if(percentage <= 25) {
    color = 'text-red-500';
  }

  return {
    percentage: percentage,
    color: color
  }
})

const status = computed(() => {

  // check if we have the correct buildings
  if(!planetStore.hasBuilding(props.planetId, 'planetary_shields')) {
    return 'need-building';
  }
  // show the terraforming progress
  return 'ok';
})


</script>

<template>
  <StandardCard title="Planetary Shields" topPadding="2" help="Planetary Shields protect your planet. The enemy will first have to take down your shields before it can engage your troops. Shields are powered by the available energy on your planet.">
    <BuildingRequired
      v-if="status === 'need-building'"
      :buildingCode="'planetary_shields'"
      text="First build Planetary Shields on this planet. Once built, you can increase its capacity with other buildings."
      status/>
    <div v-else class="flex justify-center">

      <div class="relative m-4 rounded p-4 text-center">
        <img :src="CDN.planetShield()" alt="Energy" class="absolute shields" :style="'opacity:' + (data.percentage) + '%'"/>
        <div class="flex justify-center items-center py-4">
          <PlanetAnimation :size="96" :type="Number(planetData.type)"/>
        </div>
        <div :class="'text-center pt-2 shields ' + data.color">{{ data.percentage }}%</div>
      </div>
      <div class="p-4 flex flex-col justify-between">
        <div class="rounded p-2 text-center flex flex-col">
          <div class="pb-3 text-slate-300">Charge rate</div>
          <GenericIconWithColoredNumber :amount="planetData.energyPerTurn" type="energy" class="self-center"/>
        </div>
        <div class="rounded p-2 mt-2 text-center flex flex-col">
          <div class="pb-3 text-slate-300">Capacity</div>
          <GenericIconWithColoredNumber :amount="planetData.energyStorageCapacity" type="energy_storage" class="self-center"/>
        </div>
      </div>
    </div>
  </StandardCard>
</template>

<style scoped lang="scss">
.shields {
  width:128px;
}
</style>
