<script setup lang="ts">
import * as yup from 'yup'
import SmallModel from "@/components/Interface/Modal/ModelSizes/SmallModel.vue";
import {reactive} from "vue";
import Backend from '@/models/backend.ts'
import FormProcess from '@/models/formProcess.ts'
import FormInput from "@/components/Interface/Modal/Forms/FormInput.vue";
import FormSubmit from "@/components/Interface/Modal/Forms/FormSubmit.vue";
import FormGenericError from '@/components/Interface/Modal/Forms/FormGenericError.vue'
import ActionButton from '@/components/Elements/Buttons/ActionButton.vue'

const backend = new Backend()
const formProcess = new FormProcess()


// Define the validation schema
const schema = yup.object().shape({
  fleetName: yup
    .string()
    .min(3, () => `Use at least 3 characters`)
    .max(32, () => `Do not use more than 32 characters`)
    .required(() => `This is required`)
})

// Default form values
const formValues = reactive<{ [key: string]: string }>({
  fleetName: ''
})

// Form errors
const formErrors = reactive<{ [key: string]: string }>({
  generic: '',
  fleetName: ''
});


// Handle form submission
const submit = () => {
  schema.validate(formValues)
    .then(() => {
      formProcess.resetFormErrors(formErrors)
      backend.createFleet(formValues.fleetName)
        .then(() => formProcess.processBackendSuccessResponse())
        .catch(error => formProcess.processBackendErrorResponse(error, formErrors))
    })
    .catch((error) => formProcess.processFrontendErrorResponse(error, formErrors))
}

</script>

<template>
  <SmallModel title="Create Fleet">
    <form method="post" @submit.prevent="submit" class="p-3">
      <FormInput
          label="Fleet name"
          v-model="formValues.fleetName"
          :error="formErrors.fleetName"
      />
      <div class="flex justify-between pt-2">
        <FormGenericError :error="formErrors.generic"/>
        <ActionButton class="justify-end">Create</ActionButton>
      </div>
    </form>
  </SmallModel>
</template>


<style scoped lang="scss">

</style>