<script setup lang="ts">

import { useGameStore } from '@/stores/gameStore.ts'
import { computed, onMounted, type PropType, ref, watch } from 'vue'
import type Ore from '@/types/Ore.ts'
import { usePlayerOreStore } from '@/stores/playerOreStore.ts'
import { useAssetStore } from '@/stores/assetStore.ts'
import Backend from '@/models/backend.ts'
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import ActionButton from '@/components/Elements/Buttons/ActionButton.vue'
import ConveyorLR from '@/components/Sections/Resources/Elements/Conveyor/ConveyorLR.vue'
import { CDN } from '@/models/cdn.ts'
import GenericIcon from '@/components/Elements/Images/Icons/GenericIcon.vue'
import ColoredNumber from '@/components/Elements/Generic/ColoredNumber.vue'
import DeleteButton from '@/components/Elements/Buttons/DeleteButton.vue'

const gameStore = useGameStore()
const assetStore = useAssetStore()
const playerOreStore = usePlayerOreStore()

const props = defineProps({
  sourceOre: {
    type: Object as PropType<Ore>,
    required: true
  },
  targetOreId: {
    type: Number,
    required: true
  }
})

const amount = ref(0)

const sourceOreInStorage = computed(() => {
  return playerOreStore.getOreStored(props.sourceOre.code)
})

const targetOre = computed(() => {
  return assetStore.findOreById(props.targetOreId)
})

const targetOreInStorage = computed(() => {
  return playerOreStore.getOreStored(targetOre.value.code)
})

const conversion = computed(() => {
  return {
    capacity: amount.value,
    enabled: true,
    name: 'Trade',
    type: 'trade'
  }
})



const openModal = () => {
  gameStore.modal2Type = 'oreTradeConfig'
  gameStore.modal2Data = {
    sourceOre: props.sourceOre,
    targetOre: targetOre
  }
}

const resetOreTrade = () => {
  const backend = new Backend()
  backend.setOreTrade(props.sourceOre.id, props.targetOreId, 0).then(() => {
    gameStore.setTinySuccessToastMessage('Cancellation will take effect next "Week"')
    gameStore.modal2Type=''
  })
}

const switchOre = () => {
  gameStore.modalType = 'oreTrade'
  gameStore.modalData = props.targetOreId
}

watch (() => amount.value, () => {
  //if the amount is text, set it to 0
  if(isNaN(amount.value)) {
    amount.value = 0
  }
  if(amount.value < 0) {
    amount.value = 0
  }
  if(amount.value > 5000) {
    amount.value = 5000
  }
})

watch(() => playerOreStore.tradeData, () => {
  const data = playerOreStore.getPlayerOreTrade(props.sourceOre.id, props.targetOreId)
  if(data) {
    amount.value = data.quantity
  }
},{deep:true})

onMounted(() => {
  const data = playerOreStore.getPlayerOreTrade(props.sourceOre.id, props.targetOreId)
  if(data) {
    amount.value = data.quantity
  }
})

</script>

<template>
  <div class="relative">
    <div class="flex flex-col items-center">
      <div class="flex justify-between w-full">
        <StandardCard class="z-20">
          <div class="flex flex-col items-center w-20">
            <div class="pb-2">{{ sourceOre.oreName }}</div>
            <img alt="" style="width: 4rem;" :src="gameStore.cdn + '/images/icons/ore/' + sourceOre.id + '.png'" class="pb-2">
            <div class="bg-slate-900 p-1.5 rounded w-full flex justify-between">
              <GenericIcon :type="'ore' + sourceOre.id"/><ColoredNumber :amount="sourceOreInStorage" :shortFormat="true" class="ps-2"/>
            </div>
          </div>
        </StandardCard>

        <div class="flex items-center">
          <StandardCard class="z-20">
            <div class="flex flex-col items-center">
              <div v-if="amount>0" class="pb-2 w-full">
                <div class="bg-slate-900 p-1.5 rounded w-full flex justify-between">
                  <GenericIcon tooltip="Trade credit cost" type="credit"/><ColoredNumber :amount="-amount*targetOre.tradeConversionCost" :shortFormat="true" class="ps-2"/>
                </div>
              </div>
              <div v-if="amount>0" class="pb-2 w-full">
                <div class="bg-slate-900 p-1.5 rounded w-full flex justify-between">
                  <GenericIcon :type="'ore' + targetOre.id"/><ColoredNumber :amount="amount" :shortFormat="true" class="ps-2"/>
                </div>
              </div>
              <div class="flex">
                <ActionButton @click="openModal">Trade</ActionButton>
                <DeleteButton v-if="amount>0" class="ms-2" :for-icon="true" @click="resetOreTrade"><GenericIcon tooltip="Cancel trade" type="trash"/></DeleteButton>
              </div>
            </div>
          </StandardCard>
        </div>

        <StandardCard class="z-20">
          <div class="flex flex-col items-center w-20 cursor-pointer" @click="switchOre">
            <div class="pb-2">{{ targetOre.oreName }}</div>
            <img alt="" style="width: 4rem;" :src="gameStore.cdn + '/images/icons/ore/' + targetOre.id + '.png'" class="pb-2">
            <div class="bg-slate-900 p-1.5 rounded w-full flex justify-between">
              <GenericIcon :type="'ore' + targetOre.id"/><ColoredNumber :amount="targetOreInStorage" :shortFormat="true" class="ps-2"/>
            </div>
          </div>
        </StandardCard>
      </div>
    </div>
    <ConveyorLR
      :object-image="CDN.icon('ore' + sourceOre.id)"
      :conversion="conversion"
      :width="10"
      :height="6"
      :top="4.2"
      :left="5"
    />
    <ConveyorLR
      :object-image="CDN.icon('ore' + targetOre.id)"
      :conversion="conversion"
      :width="12"
      :height="6"
      :top="4.2"
      :right="3"
    />
  </div>
</template>

<style scoped lang="scss">


</style>