<script setup lang="ts">

import { useGameStore } from '@/stores/gameStore'
import IconCount from '@/components/Objects/IconCount.vue'
import Icon from '@/components/Objects/Icons/Icon.vue'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useAssetStore } from '@/stores/assetStore.ts'
import CurrentTech from '@/components/Sections/Research/CurrentTech.vue'
import { usePlanetStore } from '@/stores/planetStore.ts'
import { RouterLink } from 'vue-router'
import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'
import { useShipStore } from '@/stores/shipStore.ts'
import { formatNumber } from '@/models/formatNumber.ts'
import { useTableStore } from '@/stores/tableStore.ts'
import { usePlayerCreditStore } from '@/stores/playerCreditStore.ts'
import GenericIcon from '@/components/Elements/Images/Icons/GenericIcon.vue'
import GenericIconWithColoredNumber from '@/components/Elements/Images/Icons/GenericIconWithColoredNumber.vue'
import GenericIconWithNumber from '@/components/Elements/Images/Icons/GenericIconWithNumber.vue'

const gameStore = useGameStore();
const assetStore = useAssetStore();
const planetStore = usePlanetStore();
const shipStore = useShipStore();
const tableStore = useTableStore();
const playerCreditStore = usePlayerCreditStore();

const showScienceToolTip = ref(false)
const showVipToolTip = ref(false)

const techPercentage = computed(() => {
  const tech = assetStore.findTechById(Number(gameStore.player?.currentTechId))
  if(!tech) {
    return 0;
  }
  const percentage = Math.floor((Number(gameStore.player?.sciencePoints)/ tech?.cost)*100);
  if (percentage > 100) {
    return 100;
  }
  return percentage;
})

const scienceOutput = computed(() => {
  if(gameStore.websocketInitialSyncCompleted) {
    return planetStore.getTotalScienceOutput()
  }
  return 0;
});

const creditsOutput = computed(() => {
  if(gameStore.websocketInitialSyncCompleted) {
    return playerCreditStore.calculateCreditsPerTurn()
  }
  return 0;
});

const timeRemainingPercentage = ref(0);

const updateTimeRemainingPercentage = () => {
  const timeSinceTurn = (( Date.now()/1000) - gameStore.dayTurnedAt);
  const percentage = (timeSinceTurn / ((gameStore.dayInterval*60)/100));
  timeRemainingPercentage.value = Math.min(100, Math.max(0, percentage));
};

const showDayToolTip = () => {
  gameStore.modalType = 'dayWeek';
};

onMounted(() => {
  // Update immediately and then every second
  updateTimeRemainingPercentage();
  const interval = setInterval(updateTimeRemainingPercentage, 1000);

  // Clear the interval when the component is unmounted
  onUnmounted(() => {
    clearInterval(interval);
  });
});

</script>

<template>
  <div class="footer bg-slate-700 flex items-center justify-between text-slate-300 text-sm w-full">
    <span class="pl-2 pr-2 hidden sm:block">
      <span class="flex items-center justify-between">
        <img :src="'https://galexion.com/cache?url=' + gameStore.player?.profileImage + '&w=36&h=36'" class="w-8 h-8 rounded" alt="Profile image">
        <span class="ps-2">{{ gameStore.player?.nickName }}</span>
      </span>
    </span>
    <div class="flex flex-grow items-center justify-center">
      <div>
        <div class="relative me-1 overflow-hidden rounded-md cursor-pointer"
             @click="showDayToolTip">
          <div class="bg-slate-600 absolute inset-0"></div>
          <div class="bg-slate-800 absolute inset-0" :style="{ width: timeRemainingPercentage + '%' }"></div>
          <div class="p-1 ps-2 pe-2 relative z-10">
            Day {{ gameStore.turn }}
          </div>
        </div>
      </div>
      <RouterLink to="/research">
        <div class="sm:block flex-col hidden">
          <div v-if="showScienceToolTip" class="current-tech">
            <CurrentTech></CurrentTech>
          </div>
          <div class="bg-slate-700 p-1 rounded-md w-52 flex ps-2 h-7 justify-between"
            @mouseenter="showScienceToolTip = gameStore.player?.currentTechId != 0"
            @mouseleave="showScienceToolTip = false"
          >
            <div class="pr-2">
              <GenericIconWithColoredNumber :amount="scienceOutput" type="science"/>
            </div>
            <div class="rounded bg-slate-600 flex-grow h-5 mx-1">
              <div v-if="gameStore.player?.currentTechId != 0" class="bg-blue-600 h-5 text-blue-100 p-0.5 pl-2 text-center leading-none rounded whitespace-nowrap" :style="'width:' + techPercentage + '%'"> {{techPercentage}}%</div>
              <div v-else class="bg-red-600 p-0.5 text-center leading-none rounded whitespace-nowrap" :style="'width:100%'">Not researching</div>
            </div>
          </div>
        </div>
      </RouterLink>
      <RouterLink :to="'/government/finances/'">
        <div class="bg-slate-700 p-0.5 ps-2 pe-2 rounded-md ms-1 hidden sm:flex pr-2">
          <GenericIconWithColoredNumber :amount="creditsOutput" type="credit" textColor="text-slate-300"/>
        </div>
      </RouterLink>
      <RouterLink :to="'/planets/' + tableStore.planets.singleSelectedId">
      <div class="bg-slate-700 p-0.5 ps-2 pe-2 rounded-md ms-1 flex">
        <GenericIconWithNumber :amount="planetStore.planets.size" type="planet" textColor="text-slate-300"/>
      </div>
      </RouterLink>
      <RouterLink :to="'/command/ships/' + tableStore.ships.singleSelectedId">
        <div class="bg-slate-700 p-0.5 ps-2 pe-2 rounded-md ms-1 flex">
          <GenericIconWithNumber :amount="shipStore.ships.size" type="ship" textColor="text-slate-300"/>
        </div>
      </RouterLink>
      <RouterLink to="/government/cabinet" v-if="Number(gameStore.player?.vipTokens)>0">
        <div v-if="showVipToolTip" class="current-tech bg-slate-800 border-1 border-slate-700 p-2">
          Use VIP Tokens to hire Ministers for your Cabinet.<br>Ministers provide bonuses to your empire.
        </div>
        <div class="bg-slate-700 p-0.5 ps-2 pe-2 rounded-md ms-1 flex"
             @mouseenter="showVipToolTip = true"
             @mouseleave="showVipToolTip = false"
        >
          <GenericIconWithNumber :amount="Number(gameStore.player?.vipTokens)" type="vip" textColor="text-slate-300"/>
        </div>
      </RouterLink>
    </div>
  </div>
</template>

<style scoped>
.footer {
  height: 2.5rem;
}
.current-tech {
  position: absolute;
  bottom: 2.5rem;
}
</style>