<script setup lang="ts">

import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import {computed, defineProps, ref, type Ref, watch} from "vue";
import type Building from "@/types/Building";
import { usePlanetStore } from '@/stores/planetStore.ts'
import { useAssetStore } from '@/stores/assetStore.ts'
import { CDN } from '@/models/cdn.ts'
import ModalTrigger from '@/components/Interface/Modal/ModalTrigger.vue'
import DeleteButton from '@/components/Elements/Buttons/DeleteButton.vue'
import Backend from '@/models/backend.ts'
import { useGameStore } from '@/stores/gameStore.ts'
import GenericIconWithColoredNumber from '@/components/Elements/Images/Icons/GenericIconWithColoredNumber.vue'
import GenericIcon from '@/components/Elements/Images/Icons/GenericIcon.vue'

/**
 * Props
 */
const props = defineProps({
  planetId: {
    type: Number,
    required: true
  },
  type: {
    type: String,
    required: true
  }
});

/**
 * Stores / models
 */
const planetStore = usePlanetStore()
const assetStore = useAssetStore()
const gameStore = useGameStore()

/**
 * Variables
 */
const availableBuildings: Ref<Building[]> = ref([]);

/**
 * Computed
 */
const planetData = computed(() => planetStore.findPlanetById(props.planetId)!)

/**
 * Functions
 */
// Update the list of available buildings
const updateList = () => {
  availableBuildings.value = [];
  for(let i = 0; i < planetData.value.buildings.length; i++){
    let index = Number(i); // Explicit conversion, though redundant in this case
    const building = assetStore.findBuildingByCode(planetData.value.buildings[index]);
    if (!building) {
      throw new Error(`Building with ID ${planetData.value.buildings[index]} not found.`);
    }
    building.effects.forEach((effect: {type:string,bonus:number}) => {
      if (effect.type === props.type && effect.bonus > 0) {
        availableBuildings.value.push(building);
      }
    });
  }
}

// Delete a building
const deleteBuilding = (buildingCode:string) => {
  gameStore.modal2Type = 'confirmationDelete';
  gameStore.modal2Data = {
    title: 'Are you sure?',
    message: 'Are you sure you want to delete this building?',
    actionText: 'Yes',
    cancelText: 'No',
    action: () => {
      const backend = new Backend();
      backend.deleteBuildingFromPlanet(props.planetId, buildingCode);
      gameStore.setTinySuccessToastMessage('Building deleted');
    },
  };
}

/**
 * Watchers
 */
// Watch for changes in planetId and update sliders accordingly
watch(() => planetData.value.timestamp, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    updateList();
  }
}, { immediate: true });

// Watch for changes in `type` prop
watch(() => props.type, (newType, oldType) => {
  if (newType !== oldType) {
    updateList();
  }
}, { immediate: true });


</script>

<template>
  <div class="flex-grow">
      <div v-if="availableBuildings.length>0" class="">
          <template v-for="(building,index) in availableBuildings" :key="index">
            <StandardCard :title="building.buildingName" topPadding="0" bottomPadding="2">
              <div class="flex flex-col">
                <div class="flex justify-start">
                  <div class="flex flex-col flex-shrink-0">
                    <ModalTrigger type="building" :data="{'buildingCode': building.buildingCode}">
                      <img
                        :src="CDN.building(128,building.buildingCode)"
                        class="rounded h-20"
                        alt=""
                      />
                    </ModalTrigger>
                  </div>
                  <div class="flex flex-col justify-between flex-grow ps-2.5">
                    <div class="text-subtext">
                      {{ building.buildingDescription }}
                    </div>
                    <div class="flex justify-between">
                      <div class="flex justify-start">
                        <template v-for="effect in building.effects">
                          <div class="flex items-center pe-2">
                            <GenericIconWithColoredNumber :type="effect.type" :amount="effect.bonus"/>
                          </div>
                        </template>
                      </div>
                      <DeleteButton @click="deleteBuilding(building.buildingCode)" :for-icon="true">
                        <GenericIcon tooltip="Delete" type="trash" />
                      </DeleteButton>
                    </div>
                  </div>
                </div>
              </div>
            </StandardCard>
          </template>

      </div>
      <StandardCard  v-else title="No Buildings" topPadding="0">
        <div class="text-subtext text-center p-4">
          Nothing has been build yet on this planet.
        </div>
      </StandardCard>
  </div>
</template>

<style scoped lang="scss">

</style>