<script setup lang="ts">
import SmallModel from "@/components/Interface/Modal/ModelSizes/SmallModel.vue";
import {useGameStore} from "@/stores/gameStore.ts";
import {usePlayerRelationStore} from "@/stores/playerRelations.ts";
import PlayerName from "@/components/Objects/PlayerName.vue";
import {useShipActionStore} from "@/stores/shipActionStore.ts";
import type MapCoordinate from '@/types/MapCoordinate.ts'
import { onMounted, ref } from 'vue'
import Backend from '@/models/backend.ts'
import type PlayerRelation from '@/types/PlayerRelation.ts'
import ImageRoundedUrl from '@/components/Interface/Images/ImageRoundedUrl.vue'
import { useMapCoordinateStore } from '@/stores/mapCoordinateStore.ts'

// Get the planet data
const gameStore = useGameStore()
const playerRelation = usePlayerRelationStore()
const shipActionPath = useShipActionStore()
const mapCoordinateStore = useMapCoordinateStore()

//List of players that are there and are enemies
const enemyPlayers = ref(new Map<number, boolean>());

const followAttack = (targetPlayer: number) => {
  /**
   * Queue the attack action
   */
  shipActionPath.queuePendingAction(
      gameStore.modalData.xPos,
      gameStore.modalData.yPos,
      targetPlayer,
      0
  );
  // Close the modal
  gameStore.modalType = '';
}

const loadMapCoordinate = async () => {
  try {

    const data = mapCoordinateStore.getByCoordinates(gameStore.modalData.xPos, gameStore.modalData.yPos);

    //check if the ships are from an enemy
    for (const playerId of Object.keys(data.mapCoordinate.ships)) {
      const numericPlayerId = Number(playerId);
      if (!enemyPlayers.value.has(numericPlayerId) && playerRelation.isEnemy(numericPlayerId)) {
        enemyPlayers.value.set(
          numericPlayerId,
          playerRelation.findRelationByPlayerId(numericPlayerId)
        );
      }
    }

  } catch (error) {
    console.error(error);
  }
};

onMounted(() => {
  loadMapCoordinate()
});


</script>

<template>
  <SmallModel title="Which player do you wish to follow & attack?">
    <div class="p-1 grid grid-cols-3 gap-2">
      <div v-for="(relation,playerId) in enemyPlayers" :key="playerId">
        <div
          @click="followAttack(relation[1].playerId)"
          class="p-2 m-2 cursor-pointer rounded bg-slate-800">
          <div class="overflow-hidden">
            <img
              :src="relation[1].profileImage"
              class="w-full h-full object-cover rounded"
              alt=""
            />
          </div>
          <div class="text-center pt-2">
            <div>
              <PlayerName :player-id="relation[1].playerId"></PlayerName>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="enemyPlayers.size === 0">
      <div class="text-subtext p-5 text-center">Nobody to follow here</div>
    </div>
    <div v-else>
      <div class="text-subtext p-5 text-center">Your ship(s) will target one ship of the selected player to follow. Once caught up, you will automatically attack.</div>
    </div>
  </SmallModel>
</template>


<style scoped lang="scss">

</style>