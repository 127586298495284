<script setup lang="ts">

import type NewsItem from "@/types/NewsItem.ts";
import ModalTrigger from "@/components/Interface/Modal/ModalTrigger.vue";
import NewsItemBaseStructure from '@/components/Sections/News/Types/Base/NewsItemBaseStructure.vue'
import NewsItemBase from '@/components/Sections/News/Types/Base/NewsItemBase.vue'
import { CDN } from '@/models/cdn.ts'

const props = defineProps<{
  newsItem: NewsItem,
  toast: boolean
}>();

</script>

<template>
  <NewsItemBase
    :toast="toast"
    :toast-image="CDN.spy(64,props.newsItem.contentData.imageCode)"
    :toast-text="'New spy trained'"
  >
    <ModalTrigger type="spy" :data="{spyId: props.newsItem.contentData.id}" >
      <NewsItemBaseStructure
        :newsItem="props.newsItem"
        :item-image="CDN.spy(256,props.newsItem.contentData.imageCode)"
        :header="'New spy trained'"
      >
        <span class="text-slate-300">{{ props.newsItem.contentData.name }}</span> finished training and has been assigned to a Counter Espionage mission.
      </NewsItemBaseStructure>
    </ModalTrigger>
  </NewsItemBase>
</template>

<style scoped lang="scss">

</style>