<script setup lang="ts">
import { ref, onMounted,onUnmounted, computed } from 'vue';
import { useBattleReportStore } from '@/stores/battleReportStore.ts';
import PlayerName from '@/components/Objects/PlayerName.vue'
import { usePlayerRelationStore } from '@/stores/playerRelations.ts'
import { useGameStore } from '@/stores/gameStore.ts'

/**
 * Stores
 */
const battleReportStore = useBattleReportStore();
const playerRelationStore = usePlayerRelationStore();
const gameStore = useGameStore();

/**
 * Variables
 */
const brContainer = ref<HTMLDivElement | null>(null);

/**
 * Computed
 */

const attackerName = computed(() => {
  if(battleReportStore.attackingPlayerId == gameStore.player?.id) {
    return {
      relation: 'player',
      name: gameStore.player?.nickName,
      profileImage: gameStore.player?.profileImage
    }
  }
  return playerRelationStore.findRelationByPlayerId(Number(battleReportStore.attackingPlayerId));
});

const defenderName = computed(() => {
  if(battleReportStore.defendingPlayerId == gameStore.player?.id) {
    return {
      relation: 'player',
      name: gameStore.player?.nickName,
      profileImage: gameStore.player?.profileImage
    }
  }
  return playerRelationStore.findRelationByPlayerId(Number(battleReportStore.defendingPlayerId));
});

/**
 * Lifecycle
 */
onMounted(() => {
  if (brContainer.value) {
    battleReportStore.init(brContainer.value);
  }
});

onUnmounted(() => {
  battleReportStore.destroy();
});
</script>

<template>
  <div class="flex w-full justify-between text-base md:text-lg p-2 rounded-b bg-black">
    <div class="flex justify-start items-center  ">
    <img :src="'https://galexion.com/cache?url=' + attackerName.profileImage + '&amp;w=36&amp;h=36'" class="w-9 h-9 rounded me-2 object-cover" alt="Profile image">
    <div :class="{
      'whitespace-nowrap overflow-x': true,
      'text-green-500': battleReportStore.attackingPlayerId == gameStore.player?.id,
      'text-red-500': battleReportStore.defendingPlayerId == gameStore.player?.id
    }" >{{ attackerName.name }}</div>
    </div>
    <div class="flex justify-end items-center ">
    <div :class="{
      'whitespace-nowrap overflow-x-clip': true,
      'text-green-500': battleReportStore.defendingPlayerId == gameStore.player?.id,
      'text-red-500': battleReportStore.attackingPlayerId == gameStore.player?.id
    }"
    >{{ defenderName.name }}</div>
      <img :src="'https://galexion.com/cache?url=' + defenderName.profileImage + '&amp;w=36&amp;h=36'" class="w-9 h-9 rounded object-cover ms-2" alt="Profile image">
    </div>
  </div>
  <div class="battleScene z-40" ref="brContainer"></div>
</template>

<style scoped lang="scss">
.battleScene {
  width: 100%;
  height: calc(100vh - 8rem);
  background-image: url("https://cdn.galexion.com/ferion/images/backgrounds/space.webp");
}
</style>
