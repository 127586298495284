<script setup lang="ts">

import type NewsItem from "@/types/NewsItem.ts";
import NewsItemHeader from '@/components/Interface/Headers/NewsItemHeader.vue'
import ImageRounded from '@/components/Interface/Images/ImageRounded.vue'
import ToastItem from '@/components/Interface/Toast/ToastItem.vue'
import { computed } from 'vue'
import { useGameStore } from '@/stores/gameStore.ts'
import NewsItemBaseStructure from '@/components/Sections/News/Types/Base/NewsItemBaseStructure.vue'
import NewsItemBase from '@/components/Sections/News/Types/Base/NewsItemBase.vue'
import ModalTrigger from '@/components/Interface/Modal/ModalTrigger.vue'
import Position from '@/components/Interface/Position.vue'
import { CDN } from '@/models/cdn.ts'
import NamedNumbers from '@/components/Elements/Generic/NamedNumbers.vue'
import { useAssetStore } from '@/stores/assetStore.ts'

const gameStore = useGameStore()
const assetStore = useAssetStore()

const props = defineProps<{
  newsItem: NewsItem,
  toast: boolean
}>();

const sizeName = computed(() => {
  if(props.newsItem.contentData.planetSize === 1) {
    return 'Low'
  } else if(props.newsItem.contentData.planetSize === 2) {
    return 'Medium'
  } else {
    return 'High'
  }
})

const typeName = computed(() => {
  return assetStore.findPlanetTypeById(props.newsItem.contentData.planetType)!.name
})

</script>

<template>
  <NewsItemBase
    :toast="toast"
    :toast-image="CDN.planet(64,props.newsItem.contentData.planetSize,props.newsItem.contentData.planetType)"
    :toast-text="'You have settled ' + props.newsItem.contentData.planetName + '!'"
  >
      <NewsItemBaseStructure
        :newsItem="props.newsItem"
        :item-image="CDN.planet(256,props.newsItem.contentData.planetSize,props.newsItem.contentData.planetType)"
        :header="'You have settled ' + props.newsItem.contentData.planetName + '!'"
      >
        <div class="flex flex-col justify-between h-full">
          <div class="flex-grow">You have settled {{ props.newsItem.contentData.planetName }}! The colony ship has landed and has become part of the new settlement.</div>
          <div class="flex justify-end">
            <NamedNumbers text="Gravity">
              <span v-if="props.newsItem.contentData.planetSize === gameStore.player?.homeWorldPlanetSize" class="text-green-500">{{ sizeName }}</span>
              <span v-else-if="props.newsItem.contentData.planetSize === gameStore.player?.homeWorldOppositePlanetSize" class="text-red-500">{{ sizeName }}</span>
              <span v-else>{{ sizeName }}</span>
            </NamedNumbers>
            <NamedNumbers text="Environment" class="ps-2">
              <span v-if="props.newsItem.contentData.planetType === gameStore.player?.homeWorldPlanetType" class="text-green-500">{{ typeName }}</span>
              <span v-else-if="props.newsItem.contentData.planetType === gameStore.player?.homeWorldOppositePlanetType" class="text-red-500">{{ typeName }}</span>
              <span v-else>{{ typeName }}</span>
            </NamedNumbers>
          </div>
        </div>
      </NewsItemBaseStructure>
  </NewsItemBase>
</template>

<style scoped lang="scss">

</style>