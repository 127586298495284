import { defineStore } from 'pinia'
import { markRaw } from 'vue'
import * as THREE from 'three'

export const useBattleReportShipsStore = defineStore('battleReportShipsStore', {
    state: () => ({
        shipInstancedMesh: null as THREE.InstancedMesh<THREE.PlaneGeometry, THREE.ShaderMaterial> | null,
        shipAvailableInstanceIds: markRaw<number[]>([]),
        shipInstanceLookup: markRaw(new Map<number, number>()),
        instancesPendingRemoval: markRaw(new Map<number, number>()),
        running: false,
        shipAnimation: true
    }),
    actions: {
        initShipInstancedMesh(maxCount: number) {
            // 1) Create a basic PlaneGeometry
            const geometry2 = new THREE.PlaneGeometry(1, 1)

            // 2) Load your new sprite sheet
            const textureLoader = new THREE.TextureLoader()
            // Be sure the actual image can be loaded (check for CORS or 404 issues)
            const spriteTexture = textureLoader.load(
              'https://cdn.galexion.com/ferion/sprites/battlereport/ships.png'
            )

            spriteTexture.wrapS = THREE.ClampToEdgeWrapping;
            spriteTexture.wrapT = THREE.ClampToEdgeWrapping;
            spriteTexture.minFilter = THREE.LinearMipMapLinearFilter;
            spriteTexture.magFilter = THREE.LinearFilter;
            spriteTexture.anisotropy = 16; // Improve sharpness on modern GPUs


            // 3) Create custom ShaderMaterial
            const material2 = new THREE.ShaderMaterial({
                uniforms: {
                    spriteTexture: { value: spriteTexture }
                },
                depthWrite: false, // Prevent depth buffer issues
                depthTest: true,
                blending: THREE.NormalBlending, // or THREE.AdditiveBlending for glowing effect
                vertexShader: `
          // We declare all custom instancing attributes here:
          attribute vec3 instanceColor;
          attribute vec2 instanceUVOffset;
          attribute vec2 instanceUVScale;

          varying vec3 vColor;
          varying vec2 vUv;

          void main() {
            vColor = instanceColor;

            // The key line: combine built-in geometry UV
            // with per-instance offset AND scale
            vUv = instanceUVOffset + uv * instanceUVScale;

            // Standard instancing transform
            vec4 mvPosition = modelViewMatrix * instanceMatrix * vec4(position, 1.0);
            gl_Position = projectionMatrix * mvPosition;
          }
        `,
                fragmentShader: `
          uniform sampler2D spriteTexture;
          varying vec3 vColor;
          varying vec2 vUv;

          void main() {
            // Sample from sprite sheet
            vec4 texColor = texture2D(spriteTexture, vUv);

            // Multiply by instance color (white=1.0 => no tint)
            gl_FragColor = vec4(vColor * texColor.rgb, texColor.a);
          }
        `,
                vertexColors: true,
                transparent: true,
                alphaTest: 0.5
            })

            material2.side = THREE.DoubleSide;

            // 4) Create instanced attributes:
            // Colors
            const colors = new Float32Array(maxCount * 3) // (r,g,b)
            const colorAttr = new THREE.InstancedBufferAttribute(colors, 3)
            geometry2.setAttribute('instanceColor', colorAttr)

            // UV Offsets
            const uvOffsets = new Float32Array(maxCount * 2) // (uOffset, vOffset)
            const offsetAttr = new THREE.InstancedBufferAttribute(uvOffsets, 2)
            geometry2.setAttribute('instanceUVOffset', offsetAttr)

            // UV Scales
            const uvScales = new Float32Array(maxCount * 2) // (uScale, vScale)
            const scaleAttr = new THREE.InstancedBufferAttribute(uvScales, 2)
            geometry2.setAttribute('instanceUVScale', scaleAttr)

            // 5) Create the InstancedMesh
            this.shipInstancedMesh = markRaw(
              new THREE.InstancedMesh(geometry2, material2, maxCount)
            )
            this.shipInstancedMesh.name = 'ownerCircle'

            // Move everything off-screen initially
            const dummy = new THREE.Object3D()
            for (let i = 0; i < maxCount; i++) {
                dummy.position.set(99999, 99999, 99999)
                dummy.updateMatrix()
                this.shipInstancedMesh.setMatrixAt(i, dummy.matrix)
                this.shipAvailableInstanceIds.push(i)
            }

            // Force updates
            this.shipInstancedMesh.instanceMatrix.needsUpdate = true
            offsetAttr.needsUpdate = true
            scaleAttr.needsUpdate = true
            colorAttr.needsUpdate = true

            return this.shipInstancedMesh
        },

        addShip(shipId:number, x: number, y: number, type: string, flip: boolean): number {
            if (!this.shipInstancedMesh) return 0;

            const instanceId = this.getAvailableShipInstanceId();
            if (instanceId === null || instanceId === undefined) {
                return 0;
            }

            // Position and scale in the scene
            const dummy = new THREE.Object3D();
            dummy.position.set(x, y, 0);
            dummy.scale.set(10, 10, 10);

            // Flip horizontally if requested
            if (flip) {
                // Option 1: Negative scale on X to flip
                dummy.scale.x *= -1;

                // Option 2 (alternative): Rotate 180 degrees around Y
                // dummy.rotation.y = Math.PI;
            }

            dummy.updateMatrix();
            this.shipInstancedMesh.setMatrixAt(instanceId, dummy.matrix);

            // Set color (white)
            const colorAttr = this.shipInstancedMesh.geometry.attributes.instanceColor;
            if (colorAttr) {
                const c = new THREE.Color(0xffffff);
                colorAttr.setXYZ(instanceId, c.r, c.g, c.b);
                colorAttr.needsUpdate = true;
            }

            // ... (UV calculations remain the same) ...
            const totalSpriteWidth  = 512;
            const totalSpriteHeight = 128;

            const spriteData: Record<string, { x: number; y: number; width: number; height: number }> = {
                'giant_frame':       { x: 384, y: 0, width: 128, height: 128},
                'large_frame':       { x: 256, y: 0, width: 128, height: 128},
                'small_frame':       { x: 128, y: 0, width: 128, height: 128},
                'battlestar_frame':  { x: 0,   y: 0, width: 128, height: 128},
            };

            const sprite = spriteData[type] ?? spriteData['small_frame'];

            const uOffset = sprite.x / totalSpriteWidth;
            const vOffset = sprite.y / totalSpriteHeight;
            const uScale  = sprite.width  / totalSpriteWidth;
            const vScale  = sprite.height / totalSpriteHeight;

            const offsetAttr = this.shipInstancedMesh.geometry.attributes.instanceUVOffset;
            if (offsetAttr) {
                offsetAttr.setXY(instanceId, uOffset, vOffset);
                offsetAttr.needsUpdate = true;
            }

            const scaleAttr = this.shipInstancedMesh.geometry.attributes.instanceUVScale;
            if (scaleAttr) {
                scaleAttr.setXY(instanceId, uScale, vScale);
                scaleAttr.needsUpdate = true;
            }

            // Finalize
            this.shipInstancedMesh.instanceMatrix.needsUpdate = true;
            this.shipInstanceLookup.set(shipId, instanceId);

            return instanceId;
        },
        flyShipToPlanet(instanceId: number, x: number, y: number, duration: number) {
            if (!this.shipInstancedMesh) return;

            const dummy = new THREE.Object3D();
            const startTime = performance.now();

            // Retrieve existing matrix and store scale before animation
            this.shipInstancedMesh.getMatrixAt(instanceId, dummy.matrix);
            dummy.matrix.decompose(dummy.position, dummy.quaternion, dummy.scale);

            const startX = dummy.position.x;
            const startY = dummy.position.y;

            //if the ship is offscreen, skip it completely
            if(startX === 99999 && startY === 99999) return
            if(startX === -100000 && startY === -100000) return

            const startScale = dummy.scale.clone(); // Preserve initial scale

            const animate = (currentTime: number) => {
                if (!this.shipInstancedMesh) return;

                const elapsedTime = currentTime - startTime;
                const progress = Math.min(elapsedTime / duration, 1); // Clamp between 0 and 1

                // Interpolate position
                const newX = startX + (x - startX) * progress;
                const newY = startY + (y - startY) * progress;

                // Update position
                dummy.position.set(newX, newY, dummy.position.z);
                dummy.updateMatrix();

                // Apply the updated transformation
                this.shipInstancedMesh.setMatrixAt(instanceId, dummy.matrix);
                this.shipInstancedMesh.instanceMatrix.needsUpdate = true;

                if (progress < 1) {
                    requestAnimationFrame(animate);
                } else {
                    // Start landing animation
                    this.landShip(instanceId, newX, newY, startScale);
                }
            };

            requestAnimationFrame(animate);
        },

        landShip(instanceId: number, x: number, y: number, startScale: THREE.Vector3, landingDuration: number = 1000) {
            if (!this.shipInstancedMesh) return;

            this.shipAnimation = false

            const dummy = new THREE.Object3D();
            const startTime = performance.now();

            this.shipInstancedMesh.getMatrixAt(instanceId, dummy.matrix);
            dummy.matrix.decompose(dummy.position, dummy.quaternion, dummy.scale);

            const animateLanding = (currentTime: number) => {
                if (!this.shipInstancedMesh) return;

                const elapsedTime = currentTime - startTime;
                const progress = Math.min(elapsedTime / landingDuration, 1); // Clamp between 0 and 1

                // Scale down smoothly to 0
                const newScale = startScale.clone().multiplyScalar(1 - progress);

                // Update position and scale
                dummy.position.set(x, y, dummy.position.z);
                dummy.scale.set(newScale.x, newScale.y, newScale.z);
                dummy.updateMatrix();

                this.shipInstancedMesh.setMatrixAt(instanceId, dummy.matrix);
                this.shipInstancedMesh.instanceMatrix.needsUpdate = true;

                if (progress < 1) {
                    requestAnimationFrame(animateLanding);
                }
            };

            requestAnimationFrame(animateLanding);
        },
        animateShip(instanceId: number, startX: number, endX: number, duration: number) {
            if (!this.shipInstancedMesh) return;

            const dummy = new THREE.Object3D();
            const startTime = performance.now();

            // Retrieve existing matrix and store scale before animation
            this.shipInstancedMesh.getMatrixAt(instanceId, dummy.matrix);
            dummy.matrix.decompose(dummy.position, dummy.quaternion, dummy.scale); // Preserve scale

            const animate = (currentTime: number) => {
                if (!this.shipInstancedMesh) return;

                const elapsedTime = currentTime - startTime;
                const progress = Math.min(elapsedTime / duration, 1); // Clamp between 0 and 1
                const newX = startX + (endX - startX) * progress;

                // Update only the position, keep scale and rotation unchanged
                dummy.position.set(newX, dummy.position.y, dummy.position.z);
                dummy.updateMatrix();

                // Apply the updated transformation
                this.shipInstancedMesh.setMatrixAt(instanceId, dummy.matrix);
                this.shipInstancedMesh.instanceMatrix.needsUpdate = true;

                // Continue animation if not complete
                if (progress < 1) {
                    requestAnimationFrame(animate);
                } else {
                    this.startIdleMovement(instanceId, newX, dummy.position.y);
                }
            };

            requestAnimationFrame(animate);
        },

        startIdleMovement(instanceId: number, baseX: number, baseY: number) {
            if (!this.shipInstancedMesh) return;

            const dummy = new THREE.Object3D();

            const idleMove = () => {
                if (!this.shipInstancedMesh) return;

                if(!this.shipAnimation) return

                if(this.instancesPendingRemoval.has(instanceId)) {
                    // Release ID for reuse
                    this.releaseShipInstanceId(instanceId)
                    const shipId = this.instancesPendingRemoval.get(instanceId)
                    if(shipId === undefined) return
                    this.shipInstanceLookup.delete(shipId)
                    this.instancesPendingRemoval.delete(instanceId)

                    dummy.position.set(99999, 99999, 0);
                    dummy.updateMatrix();

                    this.shipInstancedMesh.setMatrixAt(instanceId, dummy.matrix);
                    this.shipInstancedMesh.instanceMatrix.needsUpdate = true;

                    return
                }

                // Generate a small random offset
                let offsetX = (Math.random() - 0.5) * 0.1;
                let offsetY = (Math.random() - 0.5) * 0.1;

                // 1:100 chance to move a bit further
                if (Math.random() < 0.1) {
                    offsetX *= 10; // Increase move range
                    offsetY *= 10;
                }

                // Retrieve the current matrix and preserve scale
                this.shipInstancedMesh.getMatrixAt(instanceId, dummy.matrix);
                dummy.matrix.decompose(dummy.position, dummy.quaternion, dummy.scale);

                const startX = dummy.position.x;
                const startY = dummy.position.y;
                const targetX = startX + offsetX; // Use current position instead of baseX
                const targetY = startY + offsetY;

                const startTime = performance.now();
                const duration = 500; // 0.5 second transition

                const animate = (currentTime: number) => {
                    if (!this.shipInstancedMesh) return;

                    const elapsedTime = currentTime - startTime;
                    const progress = Math.min(elapsedTime / duration, 1); // Clamp between 0 and 1

                    // Smooth interpolation
                    const newX = startX + (targetX - startX) * progress;
                    const newY = startY + (targetY - startY) * progress;

                    dummy.position.set(newX, newY, dummy.position.z);
                    dummy.updateMatrix();

                    this.shipInstancedMesh.setMatrixAt(instanceId, dummy.matrix);
                    this.shipInstancedMesh.instanceMatrix.needsUpdate = true;

                    if (progress < 1) {
                        requestAnimationFrame(animate);
                    } else {
                        // Update baseX and baseY to prevent snapping back
                        baseX = newX;
                        baseY = newY;

                        idleMove();
                    }
                };

                requestAnimationFrame(animate);
            };

            idleMove();
        },

        removeShip(instanceId: number,shipId:number) {
            if (!this.shipInstancedMesh) return

            this.instancesPendingRemoval.set(instanceId, shipId)
        },
        clear() {
            console.log('Clearing BattleReportShipsStore');

            // Reset each state property
            this.shipInstancedMesh = null;
            this.shipAvailableInstanceIds = markRaw([]);
            this.shipInstanceLookup = markRaw(new Map<number, number>());
            this.instancesPendingRemoval = markRaw(new Map<number, number>());
            this.running = false;
            this.shipAnimation = true;

            // You can also dispose of the instanced mesh here if needed
            if (this.shipInstancedMesh) {
                this.shipInstancedMesh = null;
            }

            // Reset instance data
            this.shipAvailableInstanceIds = markRaw([]);
            this.shipInstanceLookup.clear();
        },
        getAvailableShipInstanceId(): number {
            if (this.shipAvailableInstanceIds.length === 0) {
                throw new Error('No more available instance ids for ship objects')
            }
            return this.shipAvailableInstanceIds.pop()!
        },

        releaseShipInstanceId(instanceId: number) {
            this.shipAvailableInstanceIds.push(instanceId)
        },
        findInstanceIdByShipId(shipID: number): number | undefined {
            if (this.shipInstanceLookup.has(shipID) && this.shipInstancedMesh) {
                return this.shipInstanceLookup.get(shipID);
            }
        },
        findCoordinatesByShipId(shipID: number): { x: number; y: number } {
            if (this.shipInstanceLookup.has(shipID) && this.shipInstancedMesh) {
                const instanceId = this.shipInstanceLookup.get(shipID);
                //console.log('TEST instanceId', instanceId,'shipID',shipID)
                if (instanceId !== undefined) {
                    const dummy = new THREE.Object3D();

                    // Retrieve the matrix and extract position
                    this.shipInstancedMesh.getMatrixAt(instanceId, dummy.matrix);
                    dummy.matrix.decompose(dummy.position, dummy.quaternion, dummy.scale);

                    return { x: dummy.position.x, y: dummy.position.y };
                }
            }
            //console.log('TEST DID NOT FIND SHIP ',shipID)
            return { x: 0, y: 0 };
        }
    }
})
