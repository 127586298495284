<script setup lang="ts">

import {computed, defineProps, onMounted, ref, watch} from "vue";
import {usePlanetStore} from "@/stores/planetStore";
import Backend from "@/models/backend";
import PlanetTaxSetting from "@/components/Sections/Planets/PlanetTaxSetting.vue";
import { useGameStore } from '@/stores/gameStore'

/**
 * Props
 */
const props = defineProps({
  planetId: {
    type: Number,
    required: true
  }
});

/**
 * Stores / models
 */
const planetStore = usePlanetStore()
const gameStore = useGameStore()
const backend = new Backend()

/**
 * Variables
 */
const foodSlider = ref(0);
const scienceSlider = ref(0);
const productionSlider = ref(0);

/**
 * Computed
 */
const planetData = computed(() => planetStore.findPlanetById(props.planetId)!)

/**
 * Functions
 */
const updateSlidersForPlanet = () => {
  if (planetData.value) {
    foodSlider.value = planetData.value.populationFoodPercentage || 0;
    scienceSlider.value = planetData.value.populationSciencePercentage || 0;
    productionSlider.value = planetData.value.populationProductionPercentage || 0;
  } else {
    foodSlider.value = 0;
    scienceSlider.value = 0;
    productionSlider.value = 0;
  }
};

const adjustSliders = () => {
  backend.updatePlanetPopulation(
    [props.planetId],
    Math.floor(foodSlider.value),
    Math.floor(scienceSlider.value),
    Math.floor(productionSlider.value)
  );
};

/**
 * Watchers
 */
watch(() => props.planetId, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    updateSlidersForPlanet();
  }
}, { immediate: true });

// Watch for external updates to planetData
watch(planetData, (newPlanetData) => {
  if (newPlanetData) {
    foodSlider.value = newPlanetData.populationFoodPercentage || 0;
    scienceSlider.value = newPlanetData.populationSciencePercentage || 0;
    productionSlider.value = newPlanetData.populationProductionPercentage || 0;
  }
}, { immediate: true, deep: true });

/**
 * Lifecycle
 */
onMounted(() => {
  updateSlidersForPlanet();
});

</script>

<template>
  <div class="grid grid-cols-2 2xl:grid-cols-4">
    <div class="flex-1 p-2 flex">
      <img class="w-10 h-10 mr-3 rounded-3xl" :src="`${gameStore.cdn}images/population/64/food.webp`" alt="Farmers" title="Farmers">
      <div class="relative mb-6 flex-grow">
        <input
          id="population_food"
          @input="adjustSliders"
          min="0"
          max="100"
          step="1"
          v-model="foodSlider"
          type="range"
          class="w-full h-2 rounded-lg appearance-none cursor-pointer bg-gray-700">
        <span class="text-subtext absolute start-0 -bottom-6">Farmers: <span class="text-slate-300">{{ planetData.populationWorkingOnFood }}</span></span>
      </div>
    </div>

    <div class="flex-1 p-2 flex">
      <img class="w-10 h-10 mr-3 rounded-3xl" :src="`${gameStore.cdn}images/population/64/production.webp`" alt="Workers" title="Workers">
      <div class="relative mb-6 flex-grow">
        <input
          id="population_production"
          @input="adjustSliders"
          min="0"
          max="100"
          step="1"
          v-model="productionSlider"
          type="range"
          class="w-full h-2 rounded-lg appearance-none cursor-pointer bg-gray-700">
        <span class="text-subtext absolute start-0 -bottom-6">Workers: <span class="text-slate-300">{{ planetData.populationWorkingOnProduction }}</span></span>
      </div>
    </div>

    <div class="flex-1 p-2 flex">
      <img class="w-10 h-10 mr-3 rounded-3xl" :src="`${gameStore.cdn}images/population/64/research.webp`" alt="Scientists" title="Scientists">
      <div class="relative mb-6 flex-grow">
        <input
          id="population_science"
          @input="adjustSliders"
          min="0"
          max="100"
          step="1"
          v-model="scienceSlider"
          type="range"
          class="w-full h-2 rounded-lg appearance-none cursor-pointer bg-gray-700">
        <span class="text-subtext absolute start-0 -bottom-6 whitespace-nowrap">Scientists: <span class="text-slate-300">{{ planetData.populationWorkingOnScience }}</span></span>
      </div>
    </div>

    <div class="flex-1 p-2 flex">
      <PlanetTaxSetting :planetId="planetData.id"></PlanetTaxSetting>
    </div>
  </div>
</template>

<style scoped lang="scss">
.planetHeader {
  height: 200px;
  background-size: cover;
  background-position: top;
}
.table-td {
  height: 50px !important;
  width: 50px;
}
</style>