<script setup lang="ts">
import { computed } from 'vue'
import { useGameStore } from '@/stores/gameStore.ts'
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import { useAssetStore } from '@/stores/assetStore.ts'

const gameStore = useGameStore()
const assetStore = useAssetStore()

const homeWorldDescription = computed(() => {
  const type = assetStore.findPlanetTypeById(gameStore.player!.homeWorldPlanetType) as { id: number, name: string }
  const size = gameStore.player!.homeWorldPlanetSize
  const oppositeType = assetStore.findPlanetTypeById(gameStore.player!.homeWorldOppositePlanetType) as { name: string }
  const oppositeSize = gameStore.player!.homeWorldOppositePlanetSize

  let sizeText = size === 1 ? 'small' : 'large';
  let oppositeSizeText = oppositeSize === 1 ? 'small' : 'large';

  if (type.id === 1) {
    return `Your civilization emerged on a ${sizeText} lava planet, where rivers of molten rock
and vast volcanic fields fuel your industry and technological advancement.
<br>
<br>
<br>
For optimal expansion, colonize <span class="text-green-500">${sizeText} lava</span> worlds similar to your home.
Other planet types will reduce your productivity, with <span class="text-red-500">${oppositeSizeText} ${oppositeType.name}
worlds</span> being particularly inhospitable to your species.`
  }

  if (type.id === 4) {
    return `Your species evolved on a ${sizeText} oceanic world, where endless seas teeming
with exotic marine life shaped your resource-gathering expertise and cultural identity.
<br>
<br>
<br>
To maintain peak efficiency, prioritize <span class="text-green-500">${sizeText} oceanic</span> planets when expanding.
Your civilization struggles to adapt to other environments, especially <span class="text-red-500">${oppositeSizeText}
${oppositeType.name} worlds</span>, where population growth stagnates.`
  }

  if (type.id === 2) {
    return `Your home is a ${sizeText} desert planet, where shifting sands conceal vast mineral
deposits that became the foundation of your civilization's prosperity and resilience.
<br>
<br>
<br>
For continued success, focus colonization efforts on <span class="text-green-500">${sizeText} desert</span> worlds.
Your people adapt poorly to different environments, with <span class="text-red-500">${oppositeSizeText}
${oppositeType.name} planets</span> severely hampering your development.`
  }

  if (type.id === 5) {
    return `Your civilization thrives on a ${sizeText} gas giant, where floating cities harvest
rare elements from the swirling atmosphere that other species cannot reach or utilize.
<br>
<br>
<br>
Maintain your advantage by settling <span class="text-green-500">${sizeText} gas</span> planets with similar atmospheric composition.
Your specialized biology makes adaptation difficult, particularly on <span class="text-red-500">${oppositeSizeText}
${oppositeType.name} worlds</span> where productivity plummets.`
  }

  if (type.id === 3) {
    return `Your species flourished on a ${sizeText} temperate world, where diverse biomes
and moderate climates fostered balanced development and sustainable growth strategies.
<br>
<br>
<br>
To preserve your way of life, establish colonies on <span class="text-green-500">${sizeText} temperate</span> planets.
Your civilization struggles with environmental extremes, making <span class="text-red-500">${oppositeSizeText}
${oppositeType.name} worlds</span> particularly challenging for expansion.`
  }

  if (type.id === 6) {
    return `Your civilization mastered survival on a ${sizeText} ice giant, where frozen landscapes
conceal abundant resources and extreme conditions forged your technological ingenuity.
<br>
<br>
<br>
Extend your influence by claiming <span class="text-green-500">${sizeText} ice</span> worlds that match your adaptive specializations.
Your people face significant challenges on other planet types, especially <span class="text-red-500">${oppositeSizeText}
${oppositeType.name} environments</span>, where growth is severely limited.`
  }

  return 'Your home world type and size combination is unknown.'
})

const videoURl = computed(() => {
  if(gameStore.player!.homeWorldPlanetType === 1) {
    return gameStore.cdn + 'ferion/videos/planets/lava/spin/96x96.webm'
  } else if(gameStore.player!.homeWorldPlanetType === 2) {
    return gameStore.cdn + 'ferion/videos/planets/desert/spin/96x96.webm'
  } else if(gameStore.player!.homeWorldPlanetType === 3) {
    return gameStore.cdn + 'ferion/videos/planets/temperate/spin/96x96.webm'
  } else if(gameStore.player!.homeWorldPlanetType === 4) {
    return gameStore.cdn + 'ferion/videos/planets/oceanic/spin/96x96.webm'
  } else if(gameStore.player!.homeWorldPlanetType === 5) {
    return gameStore.cdn + 'ferion/videos/planets/gas/spin/96x96.webm'
  } else if(gameStore.player!.homeWorldPlanetType === 6) {
    return gameStore.cdn + 'ferion/videos/planets/ice/spin/96x96.webm'
  } else {
    return gameStore.cdn + 'ferion/videos/planets/ferion/spin/96x96.webm'
  }

})

</script>

<template>
  <StandardCard title="Your planet">
    <video ref="videoElement" autoplay muted loop playsinline class="w-24 h-24 rounded object-cover float-right ml-2 mt-1">
      <source :src="videoURl" type="video/webm">
    </video>
    <p v-html="homeWorldDescription"></p>

    <div class="clear-left"></div>
  </StandardCard>
</template>

<style scoped lang="scss">
</style>