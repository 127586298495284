<script setup lang="ts">

import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import Position from '@/components/Interface/Position.vue'
import GenericIcon from '@/components/Elements/Images/Icons/GenericIcon.vue'
import PlayerName from '@/components/Objects/PlayerName.vue'
import { computed } from 'vue'


/**
 * Props
 */
const props = defineProps<{
  shipActionData: {
    action: string;
    targetX: number;
    targetY: number;
    targetPlayer: number;
    targetShip: number;
    data: {
      name?: string;
      type: number;
      typeVariantId: number;
      targetPlayer: number;
    }
  },
  eta: number
}>();

// const dataName =  computed(() => {
//   return props.shipActionData.data.name !== undefined ? props.shipActionData.data.name : 'Core Fleet';
// });

</script>

<template>
  <StandardCard top-padding="0" bottom-padding="1">
    <div class="flex justify-between text-subtext w-full">
      <div class="flex gap-4">
        <GenericIcon tooltip="Move" type="cmd_move" class="hidden md:inline"/>
        <div class="flex items-center">
          <div v-if="props.shipActionData.data">
            <span class="me-1">Move to <span class="">{{ props.shipActionData.data.name }}</span></span><Position :x="props.shipActionData.targetX" :y="props.shipActionData.targetY"/>
          </div>
          <div v-else>Catching up to core fleet at <Position :x="props.shipActionData.targetX" :y="props.shipActionData.targetY"/></div>
        </div>
      </div>
      <div>
        {{ eta }} days
      </div>
    </div>
  </StandardCard>
</template>

<style scoped lang="scss">

</style>