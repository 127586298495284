<script setup lang="ts">

import type NewsItem from "@/types/NewsItem.ts";
import NewsItemBaseStructure from '@/components/Sections/News/Types/Base/NewsItemBaseStructure.vue'
import NewsItemBase from '@/components/Sections/News/Types/Base/NewsItemBase.vue'
import { CDN } from '@/models/cdn.ts'
import PlayerName from '@/components/Objects/PlayerName.vue'

const props = defineProps<{
  newsItem: NewsItem,
  toast: boolean
}>();

</script>

<template>
  <NewsItemBase
    :toast="toast"
    :toast-image="CDN.spyDead(64,props.newsItem.contentData.imageCode)"
    :toast-text="'Spy killed'"
  >
    <NewsItemBaseStructure
      :newsItem="props.newsItem"
      :item-image="CDN.spyDead(256,props.newsItem.contentData.imageCode)"
      :header="'Spy killed'"
    >
      <span class="text-slate-300">{{ props.newsItem.contentData.name }}</span> got killed by a counter spy from <PlayerName :player-id="props.newsItem.contentData.targetPlayerId" /> while on a {{ props.newsItem.contentData.missionName }} mission.
    </NewsItemBaseStructure>
  </NewsItemBase>
</template>

<style scoped lang="scss">

</style>