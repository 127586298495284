<script setup lang="ts">
import * as yup from 'yup'
import SmallModel from "@/components/Interface/Modal/ModelSizes/SmallModel.vue";
import {reactive} from "vue";
import Backend from '@/models/backend.ts'
import FormProcess from '@/models/formProcess.ts'
import FormInput from "@/components/Interface/Modal/Forms/FormInput.vue";
import FormGenericError from '@/components/Interface/Modal/Forms/FormGenericError.vue'
import ActionButton from '@/components/Elements/Buttons/ActionButton.vue'
import { useGameStore } from '@/stores/gameStore.ts'
import { usePQAStore } from '@/stores/PQAStore.ts'

const gameStore = useGameStore()
const PQAStore = usePQAStore()
const backend = new Backend()
const formProcess = new FormProcess()


// Define the validation schema
const schema = yup.object().shape({
  PQAName: yup
    .string()
    .min(3, () => `Use at least 3 characters`)
    .max(32, () => `Do not use more than 32 characters`)
    .required(() => `This is required`)
})

// Default form values
const formValues = reactive<{ [key: string]: string }>({
  PQAName: ''
})

// Form errors
const formErrors = reactive<{ [key: string]: string }>({
  generic: '',
  PQAName: ''
});


// Handle form submission
const submit = async () => {
  try {
    // Validate the form values
    await schema.validate(formValues);
    formProcess.resetFormErrors(formErrors);

    // Create the PQA
    const response = await backend.createPQA(formValues.PQAName);

    // Wait in a loop until the PQA is created and reported
    const waitForPQA = async () => {
      while (!PQAStore.queues.has(response.data.id)) {
        await new Promise((r) => setTimeout(r, 200));
      }
    };

    await waitForPQA();

    // Update the modal type and data
    gameStore.modalType = 'configPQA';
    gameStore.modalData = response.data.id;
  } catch (error:any) {
    if (error.name === 'ValidationError') {
      // Handle frontend validation errors
      formProcess.processFrontendErrorResponse(error, formErrors);
    } else {
      // Handle backend errors
      formProcess.processBackendErrorResponse(error, formErrors);
    }
  }
};


</script>

<template>
  <SmallModel title="New Building Queue">
    <form method="post" @submit.prevent="submit" class="p-3">
      <FormInput
          label="Queue name"
          v-model="formValues.PQAName"
          :error="formErrors.PQAName"
      />
      <div class="flex justify-between pt-2">
        <FormGenericError :error="formErrors.generic"/>
        <ActionButton :spinner="true">Create</ActionButton>
      </div>
    </form>
  </SmallModel>
</template>


<style scoped lang="scss">

</style>