<script setup lang="ts">

import GenericIcon from '@/components/Elements/Images/Icons/GenericIcon.vue'
import ActionButton from '@/components/Elements/Buttons/ActionButton.vue'
import { useGameStore } from '@/stores/gameStore.ts'
import Backend from '@/models/backend.ts'

/**
 * Props
 */
const props = defineProps<{
  newsType: string,
  status:string
}>();

/**
 * Stores
 */
const gameStore = useGameStore()

/**
 * function
 */
const toggleNewsItem = () => {
  const backend = new Backend();
  backend.toggleNotificationNewsType(props.newsType).then(() => {
    gameStore.setTinySuccessToastMessage('Notification toggled');
  });
}

</script>

<template>
  <div class="flex justify-between items-center ps-2 rounded bg-slate-900 p-1 mb-1">
    <div>{{newsType}}</div>
    <div><ActionButton :for-icon="true" @click="toggleNewsItem"><GenericIcon :type="status==='enabled'?'on':'off'"/></ActionButton></div>
  </div>
</template>

<style scoped lang="scss">

</style>