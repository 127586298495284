<script setup lang="ts">

import MediumModel from '@/components/Interface/Modal/ModelSizes/MediumModel.vue'
import { useGameStore } from '@/stores/gameStore.ts'
import {useAssetStore} from "@/stores/assetStore.ts";
import {computed} from "vue";
import Icon from '@/components/Objects/Icons/Icon.vue'
import ImageRoundedUrl from '@/components/Interface/Images/ImageRoundedUrl.vue'
import { CDN } from '@/models/cdn.ts'
import BuildingEffects from '@/components/Sections/Planets/PlanetTabs/Generic/BuildingEffects.vue'
import NamedNumbers from '@/components/Elements/Generic/NamedNumbers.vue'
import GenericIconWithNumber from '@/components/Elements/Images/Icons/GenericIconWithNumber.vue'

const gameStore = useGameStore()
const assetStore = useAssetStore()

const buildingData = computed(() => assetStore.findBuildingByCode(gameStore.modalData.buildingCode)!)

const productionObject = computed(() => assetStore.findProjectObjectByBuildingCode(buildingData.value.buildingCode)!)

</script>

<template>

  <MediumModel :title="buildingData.buildingName">
    <div class="p-3 md:p-3">
      <div class="flex flex-col sm:flex-row text-center sm:text-left">
        <img :src="CDN.building(256, buildingData.buildingCode)"
             alt=""
             class="shrink-0 rounded w-auto h-auto md:w-64 md:h-64 object-cover">
        <div class="flex-grow text-subtext lg:pl-3 pl-0 pt-2 sm:pt-0">
          <p class="pb-4">{{ buildingData.buildingLongDescription }}</p>
          <p class="pb-4">
            <NamedNumbers text="Production cost">
              <GenericIconWithNumber :amount="productionObject.productionCost" type="production"/>
            </NamedNumbers>
            <NamedNumbers text="Effects" class="mt-2">
              <BuildingEffects :building="buildingData"> </BuildingEffects>
            </NamedNumbers>
          </p>
        </div>
      </div>
    </div>
  </MediumModel>

</template>

<style scoped lang="scss">

</style>