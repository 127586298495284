<script setup lang="ts">
import { usePlanetStore } from '@/stores/planetStore.ts'
import { useTableStore } from '@/stores/tableStore.ts'
import { useGameStore } from '@/stores/gameStore.ts'
import FerionDataTable from '@/components/Interface/DataTable/FerionDataTable.vue'
import ActionBarBlock from '@/components/Interface/DataTable/ActionBar/ActionBarBlock.vue'
import PlanetMultiSelectMenu from '@/components/Interface/Menu/PlanetMultiSelectMenu.vue'
import ActionBarFiller from '@/components/Interface/DataTable/ActionBar/ActionBarFiller.vue'
import ActionBarSearch from '@/components/Interface/DataTable/ActionBar/ActionBarSearch.vue'
import HeaderFixedWidthIcon from '@/components/Interface/DataTable/Headers/HeaderFixedWidthIcon.vue'
import PlanetIcon from '@/components/Objects/Icons/PlanetIcon.vue'
import Icon from '@/components/Objects/Icons/Icon.vue'
import HeaderFixedWidthText from '@/components/Interface/DataTable/Headers/HeaderFixedWidthText.vue'
import CellFixedWidth from '@/components/Interface/DataTable/Cell/CellFixedWidth.vue'
import PlanetProductionProgressSimpleObject
  from '@/components/Sections/Planets/PlanetTabs/ProductionTab/Objects/PlanetProductionProgressSimpleObject.vue'
import { useMapStore } from '@/stores/mapStore.ts'
import router from '@/router'
import { formatShort } from '@/models/formatNumber.ts'
import type PlayerPlanet from '@/types/PlayerPlanet.ts'
import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'
import GenericIcon from '@/components/Elements/Images/Icons/GenericIcon.vue'
import ActionButton from '@/components/Elements/Buttons/ActionButton.vue'

const planetStore = usePlanetStore();
const tableStore = useTableStore();
const gameStore = useGameStore();
const mapStore = useMapStore();

// Load filtered ships list
const filters = (planets:Map<number, PlayerPlanet>) => {
  return Array.from(planets.values())
    .filter(planet => planet.playerId === gameStore.player?.id)
    .filter((planet) => {
      // If no search query, return all
      if (!tableStore.planets.filters.nameSearch || tableStore.planets.filters.nameSearch === '') {
        return true;
      }
      // Otherwise, filter based on the ship name (case-insensitive search)
      return planet.planetName.toLowerCase().includes(tableStore.planets.filters.nameSearch.toLowerCase());
    });
};

const onClick = (id:number) => {
  const planet = planetStore.planets.get(id);
  if(planet) {
    mapStore.focusMap(planet.xPos, planet?.yPos);
  }
};

const onDoubleClick = (id:number) => {
  router.push('/planets/' + id);
};

</script>false

<template>
  <FerionDataTable
    :data="planetStore.planets"
    tableStoreName="planets"
    :selector="true"
    :filters="filters"
    :heightLimit="'h-[calc(100vh-9rem)]'"
    :click="onClick"
    :double-click="onDoubleClick"
  >
    <!--- ActionBar --->
    <template #actionBar>
      <ActionBarBlock class="pl-2 mr-1 flex items-center rounded">
        <PlanetMultiSelectMenu>
          <ActionButton>Action</ActionButton>
        </PlanetMultiSelectMenu>
      </ActionBarBlock>
      <ActionBarFiller class="mr-1 rounded">
        <div class="py-3">
          &nbsp;
        </div>
      </ActionBarFiller>
      <ActionBarSearch v-model="tableStore.planets.filters.nameSearch" name="Name" placeHolder="Search..." class="" width="16"/>
    </template>
    <!--- Headers --->
    <template #columns="{ sort, sortColumn, sortDirection }">
      <HeaderFixedWidthIcon width="4" column="type" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort" class="rounded-tl">
        <div class="flex justify-center">
          <PlanetIcon :size="2" :type="5"></PlanetIcon>
        </div>
      </HeaderFixedWidthIcon>
      <HeaderFixedWidthIcon width="3" column="capturedAtTurn" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <GenericIcon type="redflag" tooltip="Turn Acquired"/>
      </HeaderFixedWidthIcon>
      <HeaderFixedWidthIcon width="3" column="population" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <GenericIcon tooltip="Population size" type="person"/>
      </HeaderFixedWidthIcon>
      <HeaderFixedWidthIcon width="3" column="currentHitPoints" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <GenericIcon tooltip="Troops" type="hit_points"/>
      </HeaderFixedWidthIcon>
      <HeaderFixedWidthIcon width="3" column="shields" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <GenericIcon tooltip="Planetary Shields" type="shields"/>
      </HeaderFixedWidthIcon>
      <HeaderFixedWidthIcon width="3" column="attackAgainstShips" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <GenericIcon tooltip="Attack strength against ships" type="attack_against_ships"/>
      </HeaderFixedWidthIcon>
      <HeaderFixedWidthIcon width="3" column="energy" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <GenericIcon tooltip="Energy" type="energy"></GenericIcon>
      </HeaderFixedWidthIcon>
      <HeaderFixedWidthText width="15" column="productionObjectId" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort" align="left" class="rounded-tr">
        <div class="pl-1">Production</div>
      </HeaderFixedWidthText>
    </template>
    <!--- Rows --->
    <template #rows="{ item }" >
      <CellFixedWidth width="4">
        <PlanetIcon :size="item.size" :type="item.type"></PlanetIcon>
      </CellFixedWidth>
      <CellFixedWidth width="3" class="text-subtext">
        {{ item.capturedAtTurn }}
      </CellFixedWidth>
      <CellFixedWidth width="3">
        {{ item.population }}
      </CellFixedWidth>
      <CellFixedWidth width="3" class="text-subtext">
        {{ formatShort(item.currentHitPoints) }}
      </CellFixedWidth>
      <CellFixedWidth width="3" class="text-subtext">
        {{ formatShort(item.shields) }}
      </CellFixedWidth>
      <CellFixedWidth width="3" class="text-subtext">
        {{ formatShort(item.attackAgainstShips) }}
      </CellFixedWidth>
      <CellFixedWidth width="3" class="text-subtext">
        {{ formatShort(item.energy) }}
      </CellFixedWidth>
      <CellFixedWidth width="15" align="left">
        <div class="pl-1">
          <PlanetProductionProgressSimpleObject :planet-id="item.id"></PlanetProductionProgressSimpleObject>
        </div>
      </CellFixedWidth>
    </template>

  </FerionDataTable>
</template>

<style scoped lang="scss">

</style>