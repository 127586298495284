<script setup lang="ts">

import { usePlayerOreStore } from '@/stores/playerOreStore.ts'
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import GFBSOreTrade from '@/components/Sections/Government/Finances/BalanceSheetDetails/OreTrade/GFBSOreTrade.vue'

const playerOreStore = usePlayerOreStore()

</script>

<template>
  <StandardCard title="Ore Trading" top-padding="0" :no-padding="true">
    <table class="w-full rounded">
      <tr v-for="[index,trade] in playerOreStore.tradeData" :key="index" class="border-b border-b-slate-700">
        <GFBSOreTrade v-if="trade.quantity>0" :quantity="trade.quantity" :to-ore-id="trade.toOreId" :from-ore-id="trade.fromOreId"/>
      </tr>
    </table>
  </StandardCard>
</template>

<style scoped lang="scss">

</style>