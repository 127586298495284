<script setup lang="ts">
import { RouterLink, useRoute } from 'vue-router'
import {useGameStore} from "@/stores/gameStore.ts";
import { usePlayerRelationStore } from '@/stores/playerRelations.ts'
import { computed, ref, onMounted } from 'vue'
import { version } from '@/../package.json';
import HeaderButton from '@/components/Elements/Buttons/HeaderButton.vue' // Adjust path if necessary
import router from '@/router'

const appVersion = version;

const route = useRoute()

const gameStore = useGameStore()
const playerRelations = usePlayerRelationStore()
const menuVisible = ref(false)

const totalDiplomacyRequests = computed(() => {
  return playerRelations.getCountOfPendingActions()
})


const buttons = [
  {
    'name': 'Map',
    'target' : '/map/galaxy',
    'activeKey': 'Map'
  },
  {
    'name': 'Planets',
    'target' : '/planets',
    'activeKey': 'Planets'
  },
  {
    'name': 'Command',
    'target' : '/command/ships',
    'activeKey': 'Command'
  },
  {
    'name': 'Research',
    'target' : '/research',
    'activeKey': 'Research'
  },
  // {
  //   'name': 'Vips',
  //   'target' : '/vips',
  //   'activeKey': 'Vips'
  // },
  {
    'name': 'Diplomacy',
    'target' : '/diplomacy/overview',
    'activeKey': 'Diplomacy'
  },
  {
    'name': 'Government',
    'target' : '/government/cabinet',
    'activeKey': 'Government'
  },
  {
    'name': 'Resources',
    'target' : '/resources',
    'activeKey': 'Resources'
  },
  {
    'name': 'Statistics',
    'target' : '/stats',
    'activeKey': 'Statistics'
  },
  {
    'name': 'News',
    'target' : '/news',
    'activeKey': 'News'
  }
]

const goBack = () => {
  router.replace(gameStore.routerLastUrl)
}

const goHome = () => {
  router.push('/')
}

onMounted(() => {
  document.title = 'Ferion Alpha ' + version
})

</script>

<template>
  <div v-if="menuVisible" @click="menuVisible = !menuVisible" class="fixed w-full h-full">
    <!-- Close menu on background click -->
  </div>
  <div class="header bg-slate-700 flex  sm:justify-start justify-between text-white">
    <div class="block sm:hidden self-center ps-2">
      <HeaderButton @click="goBack">
        Back
      </HeaderButton>
    </div>
    <!-- Logo -->
    <div @click="goHome" class="cursor-pointer ps-2 m-2">
      <object data="https://cdn.galexion.com/ferion/images/logos/logo.svg" type="image/svg+xml"
              class="pointer-events-none"
              width="130" height="auto"></object>
    </div>
    <!-- Menu -->
      <div :class="{
        'text-nowrap text-center sm:text-left rounded-b':true,
        'hidden sm:flex sm:self-center self-top pl-5': !menuVisible,
        'absolute flex flex-col bg-slate-600 z-50 pt-1 px-1 top right-0': menuVisible
      }">
        <RouterLink
          v-for="button in buttons"
          :key="button.name"
          :to="button.target"
        >
          <div>
            <HeaderButton
              :active="typeof route.name === 'string' && route.name.startsWith(button.activeKey)"
              @click="menuVisible = false"
              class="me-0 sm:me-1 mb-1 sm:mb-0 w-full sm:w-auto"
            >
              {{ button.name }}
              <span
                v-if="button.activeKey ==='News' && Number(gameStore.player?.unreadNewsItems) > 0"
                class="rounded bg-red-500 text-white text-xs px-1 ml-1"
              >{{ gameStore.player?.unreadNewsItems }}</span>

              <span
                v-if="button.activeKey ==='Diplomacy' && totalDiplomacyRequests > 0"
                class="rounded bg-red-500 text-white text-xs px-1 ml-1"
              >{{ totalDiplomacyRequests }}</span>
            </HeaderButton>
          </div>
        </RouterLink>
      </div>
      <div class="block sm:hidden self-center pe-2">
        <HeaderButton @click="menuVisible = !menuVisible">
          <span v-if="Number(gameStore.player?.unreadNewsItems) > 0" class="border-b border-b-red-500">Menu</span>
          <span v-else>Menu</span>
        </HeaderButton>
      </div>
    </div>
</template>

<style scoped>
.header {
  height: 2.5rem;
}
.top{
  top: 2.5rem
}
</style>