<script setup lang="ts">

import { useAssetStore } from '@/stores/assetStore.ts'
import { computed } from 'vue'
import GenericIcon from '@/components/Elements/Images/Icons/GenericIcon.vue'
import GenericIconWithColoredNumber from '@/components/Elements/Images/Icons/GenericIconWithColoredNumber.vue'
import ActionButton from '@/components/Elements/Buttons/ActionButton.vue'
import Backend from '@/models/backend.ts'
import { useGameStore } from '@/stores/gameStore.ts'

/**
 * Props
 */
const props = defineProps({
  fromOreId: {
    type: Number,
    required: true
  },
  toOreId: {
    type: Number,
    required: true
  },
  quantity: {
    type: Number,
    required: true
  }
})

/**
 * Stores / models
 */
const assetStore = useAssetStore()
const gameStore = useGameStore()

/**
 * Computed
 */
const fromOre = computed(() => {
  return assetStore.findOreById(props.fromOreId)!
})

const toOre = computed(() => {
  return assetStore.findOreById(props.toOreId)!
})

/**
 * Functions
 */
const openModal = () => {
  gameStore.modal2Type = 'oreTradeConfig'
  gameStore.modal2Data = {
    sourceOre: fromOre,
    targetOre: toOre
  }
}

const resetOreTrade = () => {
  const backend = new Backend()
  backend.setOreTrade(props.fromOreId, props.toOreId, 0).then(() => {
    gameStore.setTinySuccessToastMessage('Cancellation will take effect next "Week"')
    gameStore.modal2Type=''
  })
}

</script>

<template>
  <td class="h-10">
    <div class="flex items-center ps-2">
      <GenericIcon :type="'ore' + fromOre.id"/><div class="ps-2 text-slate-300">{{ fromOre.oreName }}</div>
    </div>
  </td>
  <td class="h-10">
    <div class="flex items-center justify-start">
      <GenericIcon :type="'ore' + toOre.id"/><div class="ps-2 text-slate-300">{{ toOre.oreName }}</div>
    </div>
  </td>
  <td class="h-10">
    <div class="flex items-center justify-start">
      <GenericIconWithColoredNumber :amount="-quantity*toOre.tradeConversionCost" type="credit"/>
    </div>
  </td>
  <td class="h-10 text-end pe-2">
    <ActionButton @click="openModal">Trade</ActionButton>
  </td>
</template>

<style scoped lang="scss">

</style>