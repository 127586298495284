<script setup lang="ts">

import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import { useNewsStore } from '@/stores/newsStore.ts'
import NewsNotificationBlocked from '@/components/Sections/News/Notifications/NewsBlocked/NewsNotificationBlocked.vue'

/**
 * Stores
 */
const newsStore = useNewsStore();

</script>

<template>
  <StandardCard title="Notifications" class="pb-2" help="Here you can manage which news messages you wish to receive notifications for.">
    <template v-for="newsType in newsStore.newsTypes.values()" :key="newsType.newsType">
      <NewsNotificationBlocked :news-type="newsType.newsType" :status="newsType.status"/>
    </template>
  </StandardCard>
</template>

<style scoped lang="scss">

</style>