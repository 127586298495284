<script setup lang="ts">

import type NewsItem from "@/types/NewsItem.ts";
import NewsItemBaseStructure from '@/components/Sections/News/Types/Base/NewsItemBaseStructure.vue'
import NewsItemBase from '@/components/Sections/News/Types/Base/NewsItemBase.vue'
import { CDN } from '@/models/cdn.ts'
import PlayerName from '@/components/Objects/PlayerName.vue'
import ModalTrigger from '@/components/Interface/Modal/ModalTrigger.vue'

const props = defineProps<{
  newsItem: NewsItem,
  toast: boolean
}>();

</script>

<template>
  <NewsItemBase
    :toast="toast"
    :toast-image="CDN.spy(64,props.newsItem.contentData.imageCode)"
    :toast-text="'Spy leveled up!'"
  >
    <ModalTrigger type="spy" :data="{spyId: props.newsItem.contentData.id}" >
      <NewsItemBaseStructure
        :newsItem="props.newsItem"
        :item-image="CDN.spy(256,props.newsItem.contentData.imageCode)"
        :header="'Spy leveled up!'"
      >
        <span class="text-slate-300">{{ props.newsItem.contentData.name }}</span> leveled up and is now a level {{ props.newsItem.contentData.level }} spy!
      </NewsItemBaseStructure>
    </ModalTrigger>
  </NewsItemBase>
</template>

<style scoped lang="scss">

</style>