<script setup lang="ts">

import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import { useSpyStore } from '@/stores/spyStore.ts'
import { computed } from 'vue'
import Spy from '@/components/Sections/Diplomacy/Spies/Spy.vue'

const spyStore = useSpyStore()

// Convert Map values to an array and sort by "killed"
const sortedSpies = computed(() =>
  Array.from(spyStore.spies.values())
    .filter(spy => spy.killed === 0 && spy.cost !== 0)
    .sort((a, b) => a.killed - b.killed)
)

</script>

<template>
  <StandardCard top-padding="0" title="Spies" help="Spies while they are on offensive missions need credits to operate. The more difficult the mission the more credits it costs. Counter spy missions do not cost anything."></StandardCard>
  <div class="grid md:grid-cols-5 grid-cols-2 sm:grid-cols-3 gap-2 w-full pt-1">
    <template v-for="spy in sortedSpies" :key="spy.id">
      <Spy :spy="spy" icon="cost"/>
    </template>
  </div>
</template>

<style scoped lang="scss">

</style>