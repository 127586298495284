<script setup lang="ts">
</script>

<template>
  <div class="p-1 flex bg-slate-800 text-subtext border border-slate-700 shadow-even-md shadow-slate-700 mt-2 mr-2 rounded">
    <slot name="image">
    </slot>
    <div class="flex items-center pr-3 pl-3">
      <slot name="text"></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>