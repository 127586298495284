<script setup lang="ts">
import { useSpyStore } from '@/stores/spyStore.ts'
import Spy from '@/components/Sections/Diplomacy/Spies/Spy.vue'
import { computed } from 'vue'
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'

const spyStore = useSpyStore()

// Convert Map values to an array and sort by "killed"
const sortedSpies = computed(() =>
  Array.from(spyStore.spies.values()).sort((a, b) => a.killed - b.killed)
)
</script>

<template>
  <StandardCard title="Spies" class="w-full xl:w-2/3 mx-auto pt-1">
    Spies allow you to gather intelligence on other empires, sabotage their ships, steal technology, and more. Spies can be assigned to missions, and their success is based on their level and the difficulty of the mission.
    You obtain spies automatically through training. As a spy gains experience, they will level up and become more effective. Spies can also die if they fail their mission and get caught.
  </StandardCard>
  <div class="grid md:grid-cols-5 grid-cols-2 sm:grid-cols-3 gap-2 w-full xl:w-2/3 mx-auto pt-2">
    <template v-for="spy in sortedSpies" :key="spy.id">
      <Spy :spy="spy" icon="spy"/>
    </template>
  </div>
</template>

<style scoped lang="scss">
</style>
