<script setup lang="ts">

import { watch } from 'vue'
import { useGameStore } from '@/stores/gameStore.ts'

const gameStore = useGameStore()
let timeoutId = null;

watch(
  () => gameStore.tinyToastMessage,
  (newValue) => {
    if (newValue.length > 0) {
      // Clear the previous timeout if it exists
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      // Set a new timeout
      timeoutId = setTimeout(() => {
        gameStore.tinyToastMessage = '';
        timeoutId = null; // Clear the timeout ID after it runs
      }, 2000); // 1000 ms = 1 second
    }
  }
);

</script>

<template>
  <transition name="fade">
    <div
      v-if="gameStore.tinyToastMessage.length > 0"
      class="fixed top-10 left-1/2 -translate-x-1/2 w-auto mx-auto"
      style="z-index: 100"
    >
      <div class="flex justify-center">
        <div>
          <p
            :class="{
              'text-white p-2 rounded': true,
              'bg-red-700': gameStore.tinyToastType === 'error',
              'bg-green-700': gameStore.tinyToastType === 'success',
            }"
          >
            {{ gameStore.tinyToastMessage }}
          </p>
        </div>
      </div>
    </div>
  </transition>
</template>


<style scoped lang="scss">
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
.fade-enter-to, .fade-leave-from {
  opacity: 1;
}
</style>