<script setup lang="ts">

import { useGameStore } from '@/stores/gameStore.ts'
import {useAssetStore} from "@/stores/assetStore.ts";
import {computed} from "vue";
import Backend from '@/models/backend.ts'
import { CDN } from '@/models/cdn.ts'
import LargeWideModel from '@/components/Interface/Modal/ModelSizes/LargeWideModel.vue'
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import NamedNumbers from '@/components/Elements/Generic/NamedNumbers.vue'
import GenericIconWithNumber from '@/components/Elements/Images/Icons/GenericIconWithNumber.vue'
import ModalTechBuilding from '@/components/Interface/Modal/Modals/ModalTech/ModalTechBuilding.vue'
import ModalTechShipComponent from '@/components/Interface/Modal/Modals/ModalTech/ModalTechShipComponent.vue'
import ActionButton from '@/components/Elements/Buttons/ActionButton.vue'
import SimpleProgressBar from '@/components/Elements/ProgressBar/SimpleProgressBar.vue'

/**
 * Stores / models
 */
const gameStore = useGameStore()
const assetStore = useAssetStore()

/**
 * Computed
 */
const techData = computed(() => assetStore.findTechById(gameStore.modalData.techId)!)

const isAvailable = computed(() => {
  return !gameStore.playerTechs.has(gameStore.modalData.techId);
})

const buildings = computed(() => {
  const buildings = []
  for (const benefit of techData.value.provides) {
    if(benefit.type === 'buildings') {
      buildings.push(benefit.code)
    }
  }
  return buildings
})

const shipComponents = computed(() => {
  const shipComponents = []
  for (const benefit of techData.value.provides) {
    if(benefit.type === 'ship_components') {
      shipComponents.push(benefit.code)
    }
  }
  return shipComponents
})

const techPercentage = computed(() => {
  if(gameStore.player?.currentTechId != techData.value.id) {
    return -1;
  }
  const percentage = Math.floor((Number(gameStore.player?.sciencePoints)/ techData.value.cost)*100);
  if (percentage > 100) {
    return 100;
  }
  return percentage;
})

/**
 * Functions
 */
const setTech = () => {
  const backend = new Backend();
    backend.setTechTarget(gameStore.modalData.techId).then(() => {
      gameStore.modalData = []
      gameStore.modalType = ''
    });
  gameStore.setTinySuccessToastMessage('Target research tech set');
}

</script>

<template>
  <LargeWideModel :title="techData.name">
    <div class="p-2 lg:flex">
      <div class="w-full lg:w-1/2">
        <div class="flex flex-col md:flex-row">
          <img :src="CDN.technology(256, techData.code)"
               alt="Technology"
               class="shrink-0 rounded w-auto h-auto md:w-64 md:h-64 object-cover">
          <div class="md:ps-2 pt-2 md:pt-0 w-full">
            <StandardCard :title="techData.name" top-padding="0">
              <p>{{ techData.description }}</p>
              <div class="pt-2 flex justify-between items-center">
                <NamedNumbers text="Science cost"><GenericIconWithNumber :amount="techData.cost" type="science"/></NamedNumbers>
                <div v-if="techPercentage !== -1" class="w-1/2">
                  <SimpleProgressBar  :percentage="techPercentage" color="blue" :text="techPercentage + '%'" :height="5"/>
                </div>
                <div v-else>
                  <ActionButton v-if="isAvailable" @click="setTech">Start research</ActionButton>
                </div>
              </div>
            </StandardCard>
          </div>
        </div>
      </div>
      <div class="w-full lg:w-1/2 ps-0 lg:ps-2 pt-2 lg:pt-0">
        <template v-for="buildingCode in buildings">
          <ModalTechBuilding :buildingCode="buildingCode"/>
        </template>
        <template v-for="shipComponentCode in shipComponents">
          <ModalTechShipComponent :shipComponentCode="shipComponentCode"/>
        </template>
      </div>
    </div>
  </LargeWideModel>

</template>

<style scoped lang="scss">
.main-image {
  width:15rem;
  height:15rem;
}
</style>