<script setup lang="ts">

import { useGameStore } from '@/stores/gameStore.ts'
import {computed} from "vue";
import {usePlayerRelationStore} from "@/stores/playerRelations.ts";

const props = defineProps({
  playerId: {
    type: Number,
    required: true
  }
});

const gameStore = useGameStore();
const playerRelationStore = usePlayerRelationStore();

const data = computed(() => {
  if(props.playerId == gameStore.player?.id) {
    return {
      relation: 'player',
      name: gameStore.player?.nickName
    }
  }
  return playerRelationStore.findRelationByPlayerId(Number(props.playerId));
});

const openModal = () => {
  gameStore.modalType = 'requestAudience';
  gameStore.modalData = {relation: data};
}

</script>

<template>
  <span v-if="data?.relation == 'neutral'" >
    <span class="text-amber-900 cursor-pointer" @click="openModal">{{ data?.name }}</span>
  </span>
  <span v-else-if="data?.relation == 'player'">
    <span class="text-green-500">{{ data?.name }}</span>
  </span>
  <span v-else-if="data?.relation == 'enemy'">
    <span class="text-red-500 cursor-pointer" @click="openModal">{{ data?.name }}</span>
  </span>
  <span v-else-if="data?.relation == 'friend'">
    <span class="text-purple-500 cursor-pointer" @click="openModal">{{ data?.name }}</span>
  </span>
  <span v-else-if="data?.relation == 'ally'">
    <span class="text-cyan-500 cursor-pointer" @click="openModal">{{ data?.name }}</span>
  </span>
  <span v-else-if="data?.relation == 'unknown'">
    <span class="text-gray-500">Nobody</span>
  </span>
</template>

<style scoped lang="scss">

</style>