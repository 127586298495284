<script setup lang="ts">
import { ref, computed } from 'vue'
import SmallModel from '@/components/Interface/Modal/ModelSizes/SmallModel.vue'
import Backend from '@/models/backend.ts'
import { useGameStore } from "@/stores/gameStore.ts"
import { usePlanetGroupStore } from '@/stores/planetGroupStore.ts'

// Stores
const gameStore = useGameStore()
const planetGroupStore = usePlanetGroupStore()

// Backend instance
const backend = new Backend()

// Selected group reference
const selectedGroup = ref()

// Compute sorted planet groups
const sortedPlanetGroups = computed(() => {
  return Array.from(planetGroupStore.planetGroups.values()).sort((a, b) => a.name.localeCompare(b.name))
})

// Submit function
const submit = () => {
  backend.attachPlanetToGroup(selectedGroup.value, gameStore.modalData).then(() => {
    gameStore.modalType = ''
  })
}
</script>

<template>
  <SmallModel title="Select Group">
    <div class="p-2">
      <p class="pb-3 text-subtext">Select the group you wish to assign these {{ gameStore.modalData.length }} planet(s) to.</p>
      <select
        v-model="selectedGroup"
        @change="submit"
        class="w-full pointer-events-auto p-2 text-sm border rounded bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
      >
        <template v-for="group in sortedPlanetGroups" :key="group.id">
          <option :value="group.id">{{ group.name }}</option>
        </template>
      </select>
    </div>
  </SmallModel>
</template>

<style scoped lang="scss">
.table-container {
  height: 30rem;
  overflow-y: auto;
}
</style>
