<script setup lang="ts">
import SmallModel from '@/components/Interface/Modal/ModelSizes/SmallModel.vue'
import { useGameStore } from '@/stores/gameStore.ts'
import Backend from '@/models/backend.ts'
import { type Ref, ref, onMounted } from 'vue'
import type ProductionObject from '@/types/ProductionObject.ts'
import { useAssetStore } from '@/stores/assetStore.ts'
import BuildingEffects from '@/components/Sections/Planets/PlanetTabs/Generic/BuildingEffects.vue'
import ActionButton from '@/components/Elements/Buttons/ActionButton.vue'
import GenericIconWithNumber from '@/components/Elements/Images/Icons/GenericIconWithNumber.vue'

// Get the planet data
const gameStore = useGameStore()
const assetStore = useAssetStore()

const backend = new Backend()

const replaceCurrentObject = ref(0)

/**
 * Set the production objectModals
 */
const setProductionObject = (productionObjectId:number) => {
    backend.setPlanetsProductionObject(gameStore.modalData.planetIds, productionObjectId,replaceCurrentObject.value == 1);
    gameStore.setTinySuccessToastMessage('Building added to production queue');
}

const availableProductionObjects: Ref<ProductionObject[]> = ref([]);

/**
 * Calculate the available production objects for the current planet
 **/
const updateList = () => {
  // Get all available production objects for the player
  const productionObjectsMap = gameStore.playerProductionObjects;

  if (productionObjectsMap && productionObjectsMap.size > 0) {
     // Sort by cost
    availableProductionObjects.value = Array.from(productionObjectsMap.entries())
      .map(([id]) => {
        const productionObject = assetStore.findProductionObjectById(id);
        if (!productionObject) {
          throw new Error(`Production object with ID ${id} not found.`);
        }
        return productionObject;
      })
      .sort((a, b) => a.type.localeCompare(b.type));
  }
}

onMounted(() => {
  updateList();
})

</script>

<template>
  <SmallModel title="Build building">
    <div class="">
      <div style="max-height: 25rem;" class="overflow-y-scroll">
        <table class="w-full" >
          <tbody>
          <template v-for="productionObject in availableProductionObjects" :key="productionObject.id">
            <tr v-if="productionObject.type === 'building'" class="border-b border-b-slate-700">
              <td class="ps-2 py-3 text-slate-300 text-left">
                {{ productionObject.productionObjectName }}
              </td>
              <td class="py-3">
                <div class="hidden md:flex justify-star">
                  <BuildingEffects :building="assetStore.findBuildingByCode(productionObject.objectId)"> </BuildingEffects>
                </div>
              </td>
              <td class="pe-2 py-3 flex justify-end items-center">
                <ActionButton @click="setProductionObject(productionObject.id)">
                  <GenericIconWithNumber :amount="productionObject.productionCost" type="production" text-color="text-slate-300"/>
                </ActionButton>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
      <div class="p-2 text-slate-300 bg-slate-800">Options</div>
      <div class="flex justify-center pb-2 pt-2 text-subtext">
        <div class="flex items-center pr-2">Replace current object in production</div>
        <div class="ps-2">
          <select v-model="replaceCurrentObject"  class="pointer-events-auto p-2 text-sm border rounded bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500">
            <option value="0" selected>No</option>
            <option value="1">Yes</option>
          </select>
        </div>
      </div>
    </div>
  </SmallModel>
</template>


<style scoped lang="scss">
select {
  @apply bg-slate-600 p-1 text-slate-400 focus:outline-none
}
</style>