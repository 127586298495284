<script setup lang="ts">
import { ref, onMounted } from 'vue'
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'

interface Changelog {
  [version: string]: string[]
}

const changelog = ref<Changelog>({})
const error = ref('')

const loadChangelog = async () => {
  try {
    const response = await fetch('/changelog.json')
    if (!response.ok) throw new Error('Failed to load changelog')
    changelog.value = await response.json()
  } catch (err: any) {
    error.value = err.message
  }
}

onMounted(loadChangelog)
</script>

<template>
  <StandardCard title="Changelog">
    <div v-if="error" class="text-red-500">{{ error }}</div>

    <div v-else-if="Object.keys(changelog).length">
      <div v-for="(updates, version) in changelog" :key="version" class="mb-4">
        <h3 class="font-semibold text-lg">{{ version }}</h3>
        <ul class="list-disc pl-5">
          <li v-for="(update, index) in updates" :key="index">{{ update }}</li>
        </ul>
      </div>
    </div>

    <div v-else class="text-gray-500">Loading...</div>
  </StandardCard>
</template>

<style scoped lang="scss"></style>
