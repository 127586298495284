<script setup lang="ts">

import { useGameStore } from '@/stores/gameStore.ts'
import {useAssetStore} from "@/stores/assetStore.ts";
import MediumPlusModel from '@/components/Interface/Modal/ModelSizes/MediumPlusModel.vue'
import { useSpyStore } from '@/stores/spyStore.ts'
import {computed} from "vue";
import { CDN } from '@/models/cdn.ts'
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import ActionButton from '@/components/Elements/Buttons/ActionButton.vue'
import GenericIcon from '@/components/Elements/Images/Icons/GenericIcon.vue'
import NamedNumbers from '@/components/Elements/Generic/NamedNumbers.vue'
import ModalSpyMission from '@/components/Interface/Modal/Modals/ModalSpy/ModalSpyMission.vue'
import Backend from '@/models/backend.ts'

/**
 * Stores / models
 */
const gameStore = useGameStore()
const assetStore = useAssetStore()
const spyStore = useSpyStore()


/**
 * Computed
 */
const spy = computed(() => {
  return spyStore.spies.get(gameStore.modalData.spyId)!
})

/**
 * Functions
 */
const assignMission = (missionCode: string) => {
  if (missionCode === 'counter_espionage') {
    const backend = new Backend();
    backend.setSpyMission(spy.value.id, 0, 0, missionCode);
  } else {
    // These missions all require selecting a target empire
    const missionsThatNeedTarget = ['intelligence_gathering', 'cyber_attack', 'steal_technology','sabotage_ships','assassinate'];

    if (missionsThatNeedTarget.includes(missionCode)) {
      gameStore.modal2Type = 'spyMissionSelectPlayer';
      gameStore.modal2Data = {
        title: 'Select target empire',
        spyId: spy.value.id,
        missionCode
      };
    }
  }
}

</script>

<template>
  <MediumPlusModel :title="spy.name">
    <div class="flex justify-between md:flex-row flex-col">
      <!-- Current -->
      <div class="flex flex-col flex-shrink-0 p-2 md:w-64 items-start">
        <video autoplay muted loop class="rounded self-center w-auto h-auto">
          <source :src="CDN.spyVideo(spy.imageCode)" type="video/webm">
        </video>
        <NamedNumbers text="Level" class="w-full mt-2">
          <GenericIcon
            v-for="n in spy.level"
            :key="n"
            type="spy"
            tooltip="Experience"
            class="ms-1"
          />
        </NamedNumbers>
        <StandardCard title="Current Mission" top-padding="2">
          <ModalSpyMission :spy="spy" type="detailed" />
        </StandardCard>
      </div>
      <!-- Available Missions -->
      <div class="flex-grow text-subtext pr-2 pb-2 pl-2 md:pl-0 mt-0 md:mt-1">
        <StandardCard title="Available missions">
          <template v-for="(mission, index) in assetStore.spyMissions.values()" :key="mission.code">
            <div :class="{
                'flex w-full justify-between bg-slate-900 rounded p-2 items-start': true,
                'shadow-even-md shadow-cyan-600': spy.mission === mission.code && mission.type === 'passive',
                'shadow-even-md shadow-green-600': spy.mission === mission.code && mission.type === 'defense',
                'shadow-even-md shadow-red-600': spy.mission === mission.code && mission.type === 'attack',
                'mt-2': index > 0
              }">
              <div class="flex flex-col w-full">
                <div class="text-slate-300">{{ mission.name }}</div>
                <div class="py-2">{{ mission.description }}</div>
                <div class="flex flex-between items-end w-full">
                  <div class="flex-grow">
                    <NamedNumbers text="Difficulty">
                    <GenericIcon
                      v-for="n in mission.difficulty"
                      :key="n"
                      type="spy"
                      tooltip="Difficulty"
                      class="ms-1"
                    />
                    </NamedNumbers>
                  </div>
                  <div>
                    <ActionButton v-if="spy.level >= mission.difficulty" :full-width="false" @click="assignMission(mission.code)">
                      <span v-if="mission.cost===0">No cost</span>
                      <span v-else class="flex justify-center"><GenericIcon type="credit" tooltip="Assign mission"/><span class="ps-2">x {{ mission.cost }}</span> </span>
                    </ActionButton>
                    <span v-else>N/A</span>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </StandardCard>
      </div>
    </div>
  </MediumPlusModel>

</template>

<style scoped lang="scss">
</style>