<script setup lang="ts">
import {useGameStore} from "@/stores/gameStore.ts";
import { computed } from 'vue'
import { useAssetStore } from '@/stores/assetStore.ts'
import OreTradeSelection from '@/components/Interface/Modal/Modals/OreTrade/OreTradeSelection.vue'
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import SmallModel from '@/components/Interface/Modal/ModelSizes/SmallModel.vue'

// Get the planet data
const gameStore = useGameStore()
const assetStore = useAssetStore()

const ore = computed(() => {
  return assetStore.findOreById(gameStore.modalData)
})

const tradableOres = computed(() => {
  let list = assetStore.findOresByLevel(ore.value.level)
  // Remove the current ore from the list
  list = list.filter((listOre) => {
    return listOre.id !== ore.value.id
  })
  return list
})

</script>

<template>
  <SmallModel :title="'Trading ' + ore.oreName + ' at the Galactic Ore Exchange'">
    <div class="p-2">
      <StandardCard top-padding="0" bottom-padding="1">
        Here you can trade any ore for {{ ore.oreName }}, as long as they are from the same level. Ore is traded at a 1:1 ratio with a credit cost. This is the commission for the exchange.
      </StandardCard>
      <template v-for="ore in tradableOres" :key="ore.id">
        <OreTradeSelection :source-ore="ore" :target-ore-id="gameStore.modalData"/>
      </template>
    </div>
  </SmallModel>
</template>


<style scoped lang="scss">

</style>