import { defineStore } from 'pinia'
import type PlayerPlanet from '@/types/PlayerPlanet'
import {useAssetStore} from "@/stores/assetStore.ts";
import type Building from "@/types/Building.ts";
import { useGameStore } from '@/stores/gameStore.ts'
import { useShipDesignStore } from '@/stores/shipDesignStore.ts'

export const usePlanetStore = defineStore('planetStore', {
    state: () => ({
        planets: new Map<number, PlayerPlanet>(),
        tempPlanet: null,
        isBatching: false, // To track batching status
        planetStatsTab: 'food' as string, // To track the active tab in the planet stats section
        planetStatsTabNoData: false as boolean, // Pass along if the active tab has no data,
        planetProductionDisplayMode: '' as string, // Used for toggling the build mode in planet production screen
        planetProductionMenuType: 'all' as string, // Used filtering the menu in planet production screen
    }),
    getters: {
        findPlanetById: (state) => (id: number): PlayerPlanet | undefined => {
            return state.planets.get(id)
        },
        allPlanetsArray: (state) => {
            return Array.from(state.planets.values());
        }
    },
    actions: {
        getNumberOfShipsInProduction(shipDesignId: number): number {
          let count = 0;
          for (const planet of this.planets.values()) {
            count += planet.productionShipDesignId === shipDesignId ? 1 : 0;
          }
          return count;
        },
        getTotalCreditOutput() {
            let credits = 0;
            for(const planet of this.planets.values()) {
                credits += planet.creditsPerTurn
            }
            return credits;
        },
        getTotalScienceOutput() {
            let science = 0;
            for(const planet of this.planets.values()) {
                science += planet.sciencePerTurn
            }
            return science;
        },
        getTotalStorageCapacity() {
            let storage = 0;
            for(const planet of this.planets.values()) {
                storage += planet.oreStorageCapacity
            }
            return storage;
        },
        countPlanetsTerraForming() {
            const gameStore = useGameStore();
            let count = 0;
            if(gameStore.player) {
                for (const planet of this.planets.values()) {
                    if (Number(planet.type) != gameStore.player?.homeWorldPlanetType) {
                        // Check if the planet has a terraformer
                        if (planet.buildings.includes('terraforming_command_center')) {
                            count++;
                        }
                    }
                }
            }
            return count;
        },
        countPlanetsBuildingDysonSwarm() {
            const gameStore = useGameStore();
            let count = 0;
            if(gameStore.player) {
                for (const planet of this.planets.values()) {
                    // Check if the planet has a dyson swarm construction facility
                    if (planet.buildings.includes('dyson_sphere_construction_facility')) {
                        if(planet.dysonSwarmsBuilt < 5) {
                            count++;
                        }
                    }
                }
            }
            return count;
        },
        getActiveDefenceBuildings(planetId: number) {
            const assetStore = useAssetStore();

            const planet = this.planets.get(planetId);
            if (!planet) {
                return [];
            }
            const activeDefenceBuildings: Building[] = [];
            planet.buildings.forEach(buildingCode => {
                const building = assetStore.findBuildingByCode(buildingCode);
                building.effects.forEach((effect: any) => {
                    if (effect.type === 'defence_attack_ships') {
                        activeDefenceBuildings.push(building);
                    }
                });
            });

            return activeDefenceBuildings;
        },
        getPassiveDefenceBuildings(planetId: number) {
            const assetStore = useAssetStore();

            const planet = this.planets.get(planetId);
            if (!planet) {
                return [];
            }
            const passiveDefenceBuildings: Building[] = [];
            planet.buildings.forEach(buildingCode => {
                const building = assetStore.findBuildingByCode(buildingCode);
                building.effects.forEach((effect: any) => {
                    if (effect.type === 'defence_shields') {
                        passiveDefenceBuildings.push(building);
                    }
                });
            });

            return passiveDefenceBuildings;
        },
        countBuildingsOfType(buildingCode: string) {
            let count = 0;
            for(const planet of this.planets.values()) {
                for(const building of planet.buildings) {
                    if(building == buildingCode) {
                        count++;
                    }
                }
            }
            return count;
        },
        hasBuilding(planetId: number, requiredBuildingCode: string) {
            const planet = this.planets.get(planetId);
            if (!planet) {
                return false;
            }
            for (const buildingCode of planet.buildings) {
                if (buildingCode === requiredBuildingCode) {
                    return true; // Found the required building, so return true immediately
                }
            }
            return false;
        },
        updatePlanetById(id: number, data: PlayerPlanet) {
            // Allow receiving of partial updates
            if(this.planets.has(id)) {
                const item = this.planets.get(id);
                // Manually merge changes
                for (const key in data) {
                    // @ts-ignore
                    if(data[key] !== undefined) {
                        // @ts-ignore
                        item[key] = data[key];
                    }
                }
                // @ts-ignore
                data = item;
            }

            const gameStore = useGameStore();
            const assetStore = useAssetStore()
            const shipDesignStore = useShipDesignStore()

            const sizeCompatible = data.size === gameStore.player?.homeWorldPlanetSize;
            const sizeInCompatible = data.size === gameStore.player?.homeWorldOppositePlanetSize;
            const typeCompatible = Number(data.type) === gameStore.player?.homeWorldPlanetType;
            const typeInCompatible = Number(data.type) === gameStore.player?.homeWorldOppositePlanetType;

            let compatibility = 'C'
            if(Number(data.type)  === 7) {
                compatibility = 'F'
            } else if(sizeCompatible && typeCompatible) {
                compatibility = 'A'
            } else if(sizeInCompatible && typeInCompatible) {
                compatibility = 'E'
            } else if((sizeCompatible || typeCompatible) && !sizeInCompatible && !typeInCompatible) {
                compatibility = 'B'
            } else if((sizeInCompatible || typeInCompatible) && !sizeCompatible && !typeCompatible) {
                compatibility = 'D'
            }

            // Set the sort key for the production object for planet list screen
            if(data.productionShipDesignId>0) {
                //We are building a ship
                const design = shipDesignStore.getShipDesignById(data.productionShipDesignId)!
                data.productionObjectSortKey = 'aaaa:' + design.name + ':' + data.productionProgress
            } else if(data.productionObjectId>0) {
                //We are building a building
                const building = assetStore.findProductionObjectById(data.productionObjectId)!;
                data.productionObjectSortKey = 'Planet:' + building.productionObjectName + ':' + data.productionProgress
            } else {
                data.productionObjectSortKey = 'aaaaaa'
            }

            data.compatibility = compatibility;

            this.planets.set(id, data);
        },
        deletePlanetById(id: number) {
            if (this.planets.has(id)) {
                this.planets.delete(id)
            }
        },
        getUnitById(id: number): PlayerPlanet | undefined {
            const planet = this.planets.get(id)
            if (planet !== undefined) {
                return planet
            } else {
                console.log('Planet not found: ' + id)
            }
        }
    }
})
