import { defineStore } from 'pinia'
import type NewsItem from "@/types/NewsItem.ts";
import type FireBaseToken from '@/types/FireBaseToken.ts'

export const useNewsStore = defineStore('newsStore', {
    state: () => ({
        news: new Map<number, NewsItem>(),
        lowestId: 0, // This is used to keep track of the lowest id in the news map (load more news)
        highestId: 0, // This is used to keep track of the highest id in the news map (load more news)
        loadMoreDone: true, // Can we load more?
        firstLoad:false,
        toasts: new Map<number,NewsItem>(),
        newsTypes: new Map<string,{'status':string,'newsType':string}>(),
        firebaseTokens: new Map<number,FireBaseToken>()
    }),
    getters: {
    },
    actions: {
        add(data: NewsItem) {
            this.news.set(data.id, data)
            if(this.lowestId === 0 || data.id < this.lowestId) {
                this.lowestId = data.id
            }
            if(this.highestId === 0 || data.id > this.highestId) {
                this.highestId = data.id
            }
        },
        setAllRead() {
            //iterate over all news items and set unread = true
            this.news.forEach((newsItem) => {
                newsItem.isRead = true
            })
        },
        addToast(data: NewsItem) {
            // Add the toast using the data.id as the key
            this.toasts.set(data.id, data);
            // Use the correct id inside the setTimeout callback
            setTimeout(() => {
                this.removeToast(data.id);  // Pass the correct id
            }, 10000);  // Use a more reasonable timeout for toast visibility (e.g., 5000ms)
        },
        removeToast(id: number) {
            this.toasts.delete(id);  // Remove the toast using the id
        },
        updateNewsType(type:string, status:string) {
            this.newsTypes.set(type, {
                'status': status,
                'newsType': type
            })
        },
        addFirebaseToken(data: FireBaseToken) {
            this.firebaseTokens.set(data.id, data)
        },
        deleteFirebaseToken(id: number) {
            this.firebaseTokens.delete(id)
        },
    }
})
