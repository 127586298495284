import { defineStore } from 'pinia'
import type Ore from "@/types/Ore";
import type ProductionObject from "@/types/ProductionObject";
import type Building from "@/types/Building";
import type ShipComponent from "@/types/ShipComponent.ts";
import type PlanetType from "@/types/PlanetType.ts";
import type StarType from "@/types/StarType.ts";
import type Tech from '@/types/Tech.ts'
import type Ministry from '@/types/Ministry.ts'
import type SpyMission from '@/types/SpyMission.ts'

export const useAssetStore = defineStore('assetStore', {
    state: () => ({
        ores: [] as Record<number, Ore>,
        productionObjects: new Map<number, ProductionObject>(),
        buildings: new Map<string, Building>(),
        shipComponents: new Map<string, ShipComponent>,
        planetTypes: new Map<number, PlanetType>(),
        starTypes: new Map<number, StarType>(),
        techs: new Map<number,Tech>(),
        ministry: new Map<string, Ministry>(),
        spyMissions: new Map<string, SpyMission>(),
    }),
    getters: {
        findSpyMissionByCode: (state) => (code: string): SpyMission => {
            return state.spyMissions.get(code) as SpyMission
        },
        findOreById: (state) => (id: number): Ore => {
            return state.ores[id]
        },
        findOresByLevel: (state) => (level: number): Ore[] => {
            return Object.values(state.ores).filter(ore => ore.level === level)
        },
        findProductionObjectById: (state) => (id: number): ProductionObject => {
            if(!state.productionObjects.has(id))  {
                console.error('ProductionObject not found', id)
                console.error(`ProductionObject not found`,state.productionObjects)
            }
                return state.productionObjects.get(id) as ProductionObject
        },
        findProjectObjectByBuildingCode: (state) => (objectId: string): ProductionObject | undefined => {
            for (const productionObject of state.productionObjects.values()) {
                if (productionObject.type === 'building' && productionObject.objectId === objectId) {
                    return productionObject;
                }
            }
            return undefined;
        },
        findProjectObjectByShipComponentCode: (state) => (objectId: string): ProductionObject | undefined => {
            // find production object with type='shipComponent' and objectId = objectId
            return Object.values(state.productionObjects).find(po => po.type === 'shipComponent' && po.objectId === objectId);
        },
        findBuildingByCode: (state) => (id: string): Building => {
            return state.buildings.get(id) as Building
        },
        getBuildingNameByCode: (state) => (id: string): string => {
            return state.buildings.get(id)?.buildingName as string
        },
        findShipComponentByCode: (state) => (id: string): ShipComponent => {
            return state.shipComponents.get(id) as ShipComponent
        },
        findPlanetTypeById: (state) => (id: number): PlanetType | undefined => {
            return state.planetTypes.get(id)
        },
        findStarTypeById: (state) => (id: number): StarType | undefined => {
            return state.starTypes.get(id)
        },
        findTechById: (state) => (id: number): Tech | undefined => {
            return state.techs.get(id)
        },
        findTechByCode: (state) => (code: string): Tech => {
            return Array.from(state.techs.values()).find(tech => tech.code === code) as Tech
        },
        findTechsByTreeRowAndColumn: (state) => (row: number, column: number): Tech => {
            return Array.from(state.techs.values()).find(tech => tech.treeRow === row && tech.treeColumn === column) as Tech
        },
        findMinistryByCode: (state) => (code: string): Ministry => {
            if(code === 'placeholder') {
                return {
                    code: 'placeholder',
                    name: 'Ministry of Placeholders',
                    left: {
                        name: 'Placeholder',
                        summary: 'This is a placeholder',
                        description: 'This is a placeholder',
                        cost: 1,
                        code: 'placeholder',
                        image: 'https://upload.wikimedia.org/wikipedia/commons/8/8a/Test_grid_1000x1000.png',
                        salary: 1
                    },
                    right: {
                        name: 'Placeholder',
                        summary: 'This is a placeholder',
                        description: 'This is a placeholder',
                        cost: 1,
                        code: 'placeholder',
                        image: 'https://upload.wikimedia.org/wikipedia/commons/8/8a/Test_grid_1000x1000.png',
                        salary: 1
                    }
                }
            }
            return state.ministry.get(code) as Ministry
        },
        findMinisterByCode: (state) => (code: string):any => {
            for (const value of state.ministry.values()) {
                if (value.left.code === code) {
                    return value.left;
                } else if (value.right.code === code) {
                    return value.right;
                }
            }
            // If no match is found, return an empty string
            return '';
        },
        findMinistryByMinisterCode: (state) => (code: string): Ministry|undefined => {
            for (const value of state.ministry.values()) {
                if (value.left.code === code) {
                    return value;
                } else if (value.right.code === code) {
                    return value;
                }
            }
        },
        findMinisterSalaryByCode: (state) => (code: string): number => {
            for (const value of state.ministry.values()) {
                if (value.left.code === code) {
                    return value.left.salary;
                } else if (value.right.code === code) {
                    return value.right.salary;
                }
            }
            // If no match is found, return 0
            return 0;
        }
    },
    actions: {
        updateSpyMissionByCode(code: string, data: SpyMission) {
            this.spyMissions.set(code, data);
        },
        updateMinistryByCode(code: string, data: Ministry) {
            this.ministry.set(code, data)
        },
        updateStarTypeById(id: number, data: StarType) {
            this.starTypes.set(id, data)
        },
        updatePlanetTypeById(id: number, data: PlanetType) {
            this.planetTypes.set(id, data)
        },
        updateOreById(id: number, data: Ore) {
            if (this.ores[id] !== undefined) {
                this.ores[id].id = data.id
                this.ores[id].oreName = data.oreName
                this.ores[id].rarity = data.rarity
            } else {
                this.ores[id] = data
            }
        },
        updateProductionObjectById(id: number, data: ProductionObject) {
            this.productionObjects.set(id, data)
        },
        updateBuildingById(id: string, data: Building) {
            this.buildings.set(id, data)
        },
        updateShipComponentById(id: string, data: ShipComponent) {
            this.shipComponents.set(id, data)
        },
        updateTechByCode(id: number, data: Tech) {
            this.techs.set(id, data)
        },
        findBuildingProductionObjectsByEffectType(
          effectType: string
        ): Map<number, ProductionObject> {
            // Use the same key type as productionObjects: number
            const data = new Map<number, ProductionObject>();

            for (const [key, productionObject] of this.productionObjects) {
                // If it's a building production object
                if (productionObject.type === 'building') {
                    const building = this.findBuildingByCode(productionObject.objectId);
                    // If we found the building and it has effects
                    if (building?.effects) {
                        // Check if the building has the desired effectType with value > 0
                        for (const effect of Object.values(building.effects)) {
                            if (effect.type === effectType && effect.bonus > 0) {
                                data.set(key, productionObject);
                                // If you only want the first matching effect per building, uncomment:
                                // break;
                            }
                        }
                    }
                }
            }
            return data;
        },
        findAllBuildingProductionObjects(): Map<number, ProductionObject> {
            // Use the same key type as productionObjects: number
            const data = new Map<number, ProductionObject>();

            for (const [key, productionObject] of this.productionObjects) {
                // If it's a building production object
                if (productionObject.type === 'building') {
                    data.set(key, productionObject);
                }
            }
            return data;
        },
        getBuildingEffectsByCode(code: string): Record<string, number> {
            const building = this.findBuildingByCode(code);
            if (building && building.effects) {
                return building.effects;
            }
            return {};
        },
        getShipComponentEffectsByCode(code: string): Record<string, number> {
            const shipComponent = this.findShipComponentByCode(code);
            if (shipComponent && shipComponent.effects) {
                return shipComponent.effects;
            }
            return {};
        }
    }
})
