<script setup lang="ts">

import { CDN } from '@/models/cdn.ts'
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import ActionButton from '@/components/Elements/Buttons/ActionButton.vue'
import { computed } from 'vue'
import { useAssetStore } from '@/stores/assetStore.ts'
import BuildingEffects from '@/components/Sections/Planets/PlanetTabs/Generic/BuildingEffects.vue'
import GenericIconWithNumber from '@/components/Elements/Images/Icons/GenericIconWithNumber.vue'
import { usePQAStore } from '@/stores/PQAStore.ts'
import GenericIcon from '@/components/Elements/Images/Icons/GenericIcon.vue'

/**
 * Props
 */
const props = defineProps({
  productionObjectId: {
    type: Number,
    required: true
  },
  index: {
    type: Number,
    required: true
  },
  PQAId: {
    type: Number,
    required: true
  }
});

/**
 * Stores / models
 */
const assetStore = useAssetStore()
const PQAStore = usePQAStore()

/**
 * Functions
 */
const addProductionObject = () => {
  PQAStore.addToQueue(productionObjectData.value.id)
}

/**
 * Functions
 */
const removeProductionObject = () => {
  PQAStore.removeFromQueue(props.index)
}

const moveUp = () => {
  PQAStore.moveUp(props.index)
}

const moveDown = () => {
  PQAStore.moveDown(props.index)
}

const moveToTop = () => {
  PQAStore.moveTop(props.index)
}

const moveToBottom = () => {
  PQAStore.moveBottom(props.index)
}

/**
 * Computed
 */
const productionObjectData = computed(() => {
  return assetStore.findProductionObjectById(props.productionObjectId)!
})

const buildingData = computed(() => {
  return assetStore.findBuildingByCode(productionObjectData.value.objectId)
})

</script>

<template>
  <StandardCard>
    <div class="flex flex-col">
      <!--- Image --->
      <div class="flex justify-between">
        <div class="flex-shrink-0">
          <img
            :src="CDN.building(128,buildingData.buildingCode)" class="rounded h-20" alt=""
          />
        </div>
        <div class="flex flex-grow flex-col">
          <div class="flex-grow text-subtext ps-2 flex flex-col justify-between">
            <div>{{ buildingData.buildingName }}</div>
            <div class="flex justify-end pb-2">
              <GenericIconWithNumber :amount="productionObjectData.productionCost" type="production"/>
            </div>
          </div>
          <!--- Icons --->
          <div class="flex justify-between">
            <div class="flex items-center pt-1 ps-2">
              <BuildingEffects v-if="buildingData" :building="buildingData"/>
            </div>
            <div class="flex items-end">
              <ActionButton @click="moveToTop" class="mr-2" :for-icon="true"><GenericIcon tooltip="Move to top" type="top"/></ActionButton>
              <ActionButton @click="moveUp" class="mr-2" :for-icon="true"><GenericIcon tooltip="Move up" type="up"/></ActionButton>
              <ActionButton @click="moveDown" class="mr-2" :for-icon="true"><GenericIcon tooltip="Move down"  type="down"/></ActionButton>
              <ActionButton @click="moveToBottom" class="mr-2" :for-icon="true"><GenericIcon tooltip="Move to bottom"  type="bottom"/></ActionButton>
              <ActionButton @click="removeProductionObject" :for-icon="true">
                <GenericIcon tooltip="Delete from queue" type="trash"/>
              </ActionButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </StandardCard>
</template>

<style scoped lang="scss">

</style>