// src/router/index.ts
import { createRouter, createWebHistory } from 'vue-router'
import { useGameStore } from '@/stores/gameStore.ts'
import PlanetsMain from '@/components/Sections/Planets/PlanetsMain.vue'
import PlanetDetails from '@/components/Sections/Planets/PlanetDetails.vue'
import ShipDetails from '@/components/Sections/Command/Ships/ShipDetails.vue'
import CommandMain from '@/components/Sections/Command/CommandMain.vue'
import ShipsMain from '@/components/Sections/Command/Ships/ShipsMain.vue'
import ShipDesigns from '@/components/Sections/Command/ShipDesigns/ShipDesigns.vue'
import FleetMain from '@/components/Sections/Command/Fleets/FleetMain.vue'
import { useMapStore } from '@/stores/mapStore.ts'
import NewsMain from "@/components/Sections/News/NewsMain.vue";
import NewsNotificationsMain from '@/components/Sections/News/Notifications/NewsNotificationsMain.vue'
import Welcome from '@/components/Sections/Welcome/Welcome.vue'
import AlliancesMain from '@/components/Sections/Diplomacy/Alliances/AlliancesMain.vue'
import Diplomacy from '@/components/Sections/Diplomacy/Diplomacy.vue'
import ResearchMain from '@/components/Sections/Research/ResearchMain.vue'
import StatisticsMain from '@/components/Sections/Statistics/StatisticsMain.vue'
import FleetDetails from '@/components/Sections/Command/Fleets/FleetDetails.vue'
import GovernmentMain from '@/components/Sections/Government/GovernmentMain.vue'
import GovernmentCabinetMain from '@/components/Sections/Government/Cabinet/GovernmentCabinetMain.vue'
import Overview from '@/components/Sections/Diplomacy/Overview/Overview.vue'
import GovernmentFinancesMain from '@/components/Sections/Government/Finances/GovernmentFinancesMain.vue'
import ResourcesMain from '@/components/Sections/Resources/ResourcesMain.vue'
import SpiesMain from '@/components/Sections/Diplomacy/Spies/SpiesMain.vue'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Welcome
  },
  {
    path: '/map',
    name: 'Map',
    component: () => import('@/components/Sections/Map/MapMain.vue'),
    children: [
      {
        path: 'galaxy',
        name: 'MapGalaxy',
        component: () => import('@/components/Sections/Map/MapMain.vue'),
      },
      {
        path: 'galaxy/:x/:y',
        name: 'MapGalaxyPos',
        component: () => import('@/components/Sections/Map/Galaxy/GalaxyTab.vue'),
        beforeEnter: (to:any, from:any, next:any) => {
          const mapStore = useMapStore();
          mapStore.gotoX = Number(to.params.x);
          mapStore.gotoY = Number(to.params.y);
          next();
        }
      }
    ]
  },
  {
    path: '/command',
    name: 'Command',
    component: CommandMain,
    children: [
      {
        path: 'ships',
        name: 'CommandShips',
        component: ShipsMain,
        children: [
          {
            path: ':shipId',
            name: 'CommandShipsDetail',
            component: ShipDetails,
            props: (route:any) => ({ shipId: Number(route.params.shipId) }),
          }
        ],
      },
      {
        path: 'fleets',
        name: 'CommandFleets',
        component: FleetMain,
        children: [
          {
            path: ':fleetId',
            name: 'CommandFleetsDetail',
            component: FleetDetails,
            props: (route:any) => ({ fleetId: Number(route.params.fleetId) }),
          }
        ],
      },
      {
        path: 'ship-designs',
        name: 'CommandShipDesigns',
        component: ShipDesigns,
      },
    ],
  },
  {
    path: '/planets',
    name: 'Planets',
    component: PlanetsMain,
    children: [
      {
        path: ':planetId',
        name: 'PlanetsDetail',
        component: PlanetDetails,
        props: (route:any) => ({ planetId: Number(route.params.planetId) }),
      }
    ],
  },
  {
    'path': '/diplomacy',
    'name': 'Diplomacy',
    component: Diplomacy,
    children: [
      {
        'path': 'overview',
        'name': 'DiplomacyOverview',
        component: Overview,
        props: { 'type': 'overview' }
      },
      {
        'path': 'spies',
        'name': 'DiplomacySpies',
        component: SpiesMain,
      },
      {
        'path': 'alliances',
        'name': 'DiplomacyAlliances',
        component: AlliancesMain,
      }
    ]
  },
  {
    path: '/government',
    name: 'Government',
    component: GovernmentMain,
    children: [
      {
        path: 'cabinet',
        name: 'GovernmentCabinet',
        component: GovernmentCabinetMain,
      },
      {
        path: 'finances',
        name: 'GovernmentFinances',
        component: GovernmentFinancesMain,
      },
    ],
  },
  {
    path: '/resources',
    name: 'Resources',
    component: ResourcesMain
  },
  {
    path: '/stats',
    name: 'Statistics',
    component: StatisticsMain
  },
  {
    path: '/research',
    name: 'Research',
    component: ResearchMain
  },
  {
    path: '/news',
    name: 'News',
    component: NewsMain
  },
  {
    path: '/news/notifications',
    name: 'NewsNotifications',
    component: NewsNotificationsMain
  },
  {
    path: "/:catchAll(.*)",
    component: () => import('@/components/Sections/Map/MapMain.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const gameStore = useGameStore();
  if (to.name?.toString().startsWith('MapGalaxy')) {
    // Map needs to be loaded when its in view, and only once
    gameStore.mapInitialized = true;
  }
  //If last router url is different from the current, then set it
  if (from.fullPath !== to.fullPath) {
    gameStore.routerLastUrl = from.fullPath;
  }
  next();
});

export default router
