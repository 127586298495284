<script setup lang="ts">

import type NewsItem from "@/types/NewsItem.ts";
import ModalTrigger from "@/components/Interface/Modal/ModalTrigger.vue";
import NewsItemBaseStructure from '@/components/Sections/News/Types/Base/NewsItemBaseStructure.vue'
import NewsItemBase from '@/components/Sections/News/Types/Base/NewsItemBase.vue'
import { CDN } from '@/models/cdn.ts'
import PlayerName from '@/components/Objects/PlayerName.vue'
import NamedNumbers from '@/components/Elements/Generic/NamedNumbers.vue'
import { useAssetStore } from '@/stores/assetStore.ts'
import { computed } from 'vue'

/**
 * Props
 */
const props = defineProps<{
  newsItem: NewsItem,
  toast: boolean
}>();

</script>

<template>
  <NewsItemBase
    :toast="toast"
    :toast-image="CDN.spyDead(64,props.newsItem.contentData.imageCode)"
    :toast-text="'Spy assassinated'"
  >
    <NewsItemBaseStructure
      :newsItem="props.newsItem"
      :item-image="CDN.spyDead(256,props.newsItem.contentData.imageCode)"
      :header="'Spy assassinated'"
    >
      <div v-if="props.newsItem.contentData.targetPlayerId===0">
        <span class="text-slate-300">{{ props.newsItem.contentData.name }}</span> was assassinated.
      </div>
      <div v-else>
        <span class="text-slate-300">{{ props.newsItem.contentData.name }}</span> was assassinated by a spy from <PlayerName :player-id="props.newsItem.contentData.targetPlayerId" />.
      </div>
    </NewsItemBaseStructure>
  </NewsItemBase>
</template>

<style scoped lang="scss">

</style>