<script setup lang="ts">

import type NewsItem from "@/types/NewsItem.ts";
import ModalTrigger from "@/components/Interface/Modal/ModalTrigger.vue";
import {useGameStore} from "@/stores/gameStore.ts";
import Position from '@/components/Interface/Position.vue'
import PlayerName from '@/components/Objects/PlayerName.vue'
import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'
import NewsItemHeader from '@/components/Interface/Headers/NewsItemHeader.vue'
import ToastItem from '@/components/Interface/Toast/ToastItem.vue'
import ImageRoundedUrl from '@/components/Interface/Images/ImageRoundedUrl.vue'
import { usePlayerRelationStore } from '@/stores/playerRelations.ts'
import NewsItemBaseStructure from '@/components/Sections/News/Types/Base/NewsItemBaseStructure.vue'
import NewsItemBase from '@/components/Sections/News/Types/Base/NewsItemBase.vue'
import { computed } from 'vue'
import GenericIconWithColoredNumber from '@/components/Elements/Images/Icons/GenericIconWithColoredNumber.vue'
import GenericIconWithNumber from '@/components/Elements/Images/Icons/GenericIconWithNumber.vue'
import GenericIcon from '@/components/Elements/Images/Icons/GenericIcon.vue'
import { CDN } from '@/models/cdn.ts'
import ColoredNumber from '@/components/Elements/Generic/ColoredNumber.vue'

const gameStore = useGameStore();
const playerRelationStore = usePlayerRelationStore();

const props = defineProps<{
  newsItem: NewsItem,
  toast: boolean
}>();

const title = computed(() => {
  return props.newsItem.contentData.winnerPlayerId === gameStore.player?.id ? 'You won a battle' : 'You lost a battle';
});

const attackerShipChange = computed(() => {
  return props.newsItem.contentData.attackerShipsEnd - props.newsItem.contentData.attackerShipsStart;
});

const defenderShipChange = computed(() => {
  return props.newsItem.contentData.defenderShipsEnd - props.newsItem.contentData.defenderShipsStart;
});

const defenderPlanetTroopsChange = computed(() => {
  return props.newsItem.contentData.defenderPlanetTroopsEnd - props.newsItem.contentData.defenderPlanetTroopsStart;
});

const defenderPlanetShieldsChange = computed(() => {
  return props.newsItem.contentData.defenderPlanetShieldsEnd - props.newsItem.contentData.defenderPlanetShieldsStart;
});

</script>

<template>
  <NewsItemBase
    :toast="toast"
    :toast-image="playerRelationStore.getProfileImageByPlayerId(newsItem.contentData.opponentPlayerId)"
    :toast-text="title"
  >
    <ModalTrigger type="battleReport" :data="{'battleReportId': props.newsItem.contentData.battleReportId}">
      <NewsItemBaseStructure
        :newsItem="props.newsItem"
        :item-image="playerRelationStore.getProfileImageByPlayerId(newsItem.contentData.opponentPlayerId)"
        :header="title"
      >
        <div class="pb-1">
            <div class="flex justify-evenly text-center items-start">
              <div class="w-1/2 ">
                <div class="py-1 bg-slate-900 bg-opacity-50 rounded ">
                  <span v-if="props.newsItem.contentData.attackerPlayerId === props.newsItem.contentData.winnerPlayerId" class="hidden md:inline">Winner : </span>
                  <PlayerName :player-id="props.newsItem.contentData.attackerPlayerId"/>
                </div>
                <div class="pb-1 flex justify-center items-center bg-opacity-25 rounded bg-slate-900 p-1 mt-1">
                  <span class="pe-2"><GenericIcon tooltip="Ships" type="ship"/></span>
                  <span class="pe-2">{{ props.newsItem.contentData.attackerShipsStart }}</span>
                  <span class="pe-2"><GenericIcon type="right"/></span>
                  <span class="pe-2">
                    <span v-if="attackerShipChange < 0" class="text-red-500">{{ props.newsItem.contentData.attackerShipsEnd }}</span>
                    <span v-else-if="attackerShipChange == 0">{{ props.newsItem.contentData.attackerShipsEnd }}</span>
                  </span>
                </div>
              </div>

              <div class="px-2 pt-1">
                <div class="pb-2">VS</div>
                <Position @click.stop :x="props.newsItem.contentData.xPos" :y="props.newsItem.contentData.yPos"/>
              </div>

              <div class="w-1/2">
                <div class="py-1 bg-slate-900 bg-opacity-50 rounded">
                  <span v-if="props.newsItem.contentData.defenderPlayerId === props.newsItem.contentData.winnerPlayerId" class="hidden md:inline">Winner : </span>
                  <PlayerName :player-id="props.newsItem.contentData.defenderPlayerId"/>
                </div>
                <div v-if="props.newsItem.contentData.defenderShipsStart>0" class="flex justify-center items-center bg-opacity-25 rounded bg-slate-900 p-1 mt-1">
                  <span class="pe-2"><GenericIcon tooltip="Ships" type="ship"/></span>
                  <span class="pe-2">{{ props.newsItem.contentData.defenderShipsStart }}</span>
                  <span class="pe-2"><GenericIcon type="right"/></span>
                  <span class="pe-2">
                    <span v-if="defenderShipChange < 0" class="text-red-500">{{ props.newsItem.contentData.defenderShipsEnd }}</span>
                    <span v-else-if="defenderShipChange == 0">{{ props.newsItem.contentData.defenderShipsEnd }}</span>
                  </span>
                </div>
                <div class="">
                  <div v-if="props.newsItem.contentData.planetId>0" class="flex justify-center items-center py-1 bg-slate-900 bg-opacity-45 rounded-t mt-1 pt-1 px-1">
                    <span class="text-xs">{{ props.newsItem.contentData.planetName }}</span>
                  </div>
                  <div v-if="props.newsItem.contentData.planetId>0" class="flex justify-center items-center bg-slate-900 bg-opacity-25 p-1 px-1">
                    <div class="pe-3">
                      <img :src="CDN.planet(128,props.newsItem.contentData.planetSize,props.newsItem.contentData.planetType)" alt="Planet" class="w-12 h-12 object-cover"/>
                    </div>
                    <div class="flex flex-col justify-evenly">
                      <div class="flex justify-between items-center">
                        <span class="pe-2"><GenericIcon tooltip="Planet energy" type="energy_storage"/></span>
                        <span class="pe-2">{{ props.newsItem.contentData.defenderPlanetShieldsStart }}%</span>
                        <span class="pe-2"><GenericIcon type="right"/></span>
                        <span class="pe-2">
                          <span v-if="defenderPlanetShieldsChange < 0" class="text-red-500">{{ props.newsItem.contentData.defenderPlanetShieldsEnd }}%</span>
                          <span v-else-if="defenderPlanetShieldsChange == 0">{{ props.newsItem.contentData.defenderPlanetShieldsEnd }}%</span>
                        </span>
                      </div>
                      <div class="flex justify-between items-center pt-2">
                        <span class="pe-2"><GenericIcon tooltip="Troops" type="soldier"/></span>
                        <span class="pe-2">{{ props.newsItem.contentData.defenderPlanetTroopsStart }}%</span>
                        <span class="pe-2"><GenericIcon type="right"/></span>
                        <span class="pe-2">
                          <span v-if="defenderPlanetTroopsChange < 0" class="text-red-500">{{ props.newsItem.contentData.defenderPlanetTroopsEnd }}%</span>
                          <span v-else-if="defenderPlanetTroopsChange == 0">{{ props.newsItem.contentData.defenderPlanetTroopsEnd }}%</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </NewsItemBaseStructure>
    </ModalTrigger>
  </NewsItemBase>
</template>

<style scoped lang="scss">

</style>