<script setup lang="ts">

import { defineProps, ref } from 'vue'
import Loader from '@/components/Elements/Loaders/Loader.vue'
import LoaderSmall from '@/components/Elements/Loaders/LoaderSmall.vue'

/**
 * Props
 */
const props = defineProps({
  active: {
    type: Boolean,
    required: false,
    default: false
  },
  fullWidth: {
    type: Boolean,
    required: false,
    default: false
  },
  forIcon: {
    type: Boolean,
    required: false,
    default: false
  },
  spinner: {
    type: Boolean,
    required: false,
    default: false
  }
});

/**
 * Variables
 */
const spinning = ref(false)

/**
 * Functions
 */
const clicked = () => {
  if (props.spinner) {
    spinning.value = true
  }
}

</script>

<template>
<button @click="clicked" :class="{
  'py-1 hover:bg-slate-500 rounded text-slate-300': true,
  'bg-slate-700': active,
  'bg-slate-800': !active,
  'w-full': fullWidth,
  'px-2' : !forIcon,
  'px-1' : forIcon,
  'cursor-none': spinning
}">
  <span v-if="spinner && spinning">
    <LoaderSmall/>
  </span>
  <span v-if="!spinner || !spinning ">
    <slot></slot>
  </span>
</button>
</template>

<style scoped lang="scss">

</style>