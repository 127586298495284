<script setup lang="ts">

import { useGameStore } from '@/stores/gameStore.ts'
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'

const gameStore = useGameStore()
</script>

<template>
  <StandardCard :title="'Welcome ' + gameStore.player?.nickName + '!'">
    <div class="flex">
    <img
      :src="'https://galexion.com/cache?url=' + gameStore.player?.profileImage + '&w=128&h=128'"
      alt="Arena"
      class="w-32 h-32 md:w-24 md:h-24 rounded object-cover float-left mr-2 mt-1"
    />

    <div>
      You were once the undisputed leader of your planet. After years of war, diplomacy, and struggle, you achieved the impossible—uniting all nations under a single banner, ushering in an era of peace and prosperity.
    </div>
    </div>
    <p class="my-4 py-2 text-center text-slate-300">
      But your triumph was short-lived.
    </p>
    <p>
      One day, an all-powerful alien race, the Ferion, made contact. They revealed an unsettling truth: your civilization had reached a threshold—a level of development that triggered its forced relocation to the Arena, a vast, uncharted region of space. Here, countless other civilizations, each plucked from their homeworlds at the peak of their progress, compete for survival.
    </p>
    <p class="py-4">
      For the Ferion, this is sport. A grand spectacle. A brutal test.
    </p>
    <p>
      Now, you must rebuild, expand, and conquer. Explore the galaxy, settle new worlds, command powerful fleets, and navigate the treacherous web of diplomacy. Forge alliances—or wage total war. The rules are simple: only one empire will survive.
    </p>
    <p class="pt-4">
      Will you lead your people to dominance, or become another fallen civilization in the Ferion’s arena?
    </p>
    <div class="clear-left"></div>
  </StandardCard>
</template>

<style scoped lang="scss">

</style>