<template>
  <SmallModel title="Select Group">
    <div class="p-2">
      <p class="pb-3 text-subtext">
        Select the group you wish to assign these {{ gameStore.modalData.length }} ship(s) to.
      </p>
      <select
        @change="submit"
        v-model="selectedGroup"
        class="w-full pointer-events-auto p-2 text-sm border rounded bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
      >
        <template
          v-for="group in sortedShipGroups"
          :key="group.id"
        >
          <option :value="group.id">{{ group.name }}</option>
        </template>
      </select>
    </div>
  </SmallModel>
</template>

<script setup lang="ts">
import { useGameStore } from "@/stores/gameStore.ts";
import SmallModel from "@/components/Interface/Modal/ModelSizes/SmallModel.vue";
import { useShipGroupStore } from "@/stores/shipGroupStore.ts";
import Backend from "@/models/backend.ts";
import { ref, computed } from "vue";

const gameStore = useGameStore();
const shipGroupStore = useShipGroupStore();
const backend = new Backend();

const selectedGroup = ref();

// Computed property for sorted ship groups
const sortedShipGroups = computed(() => {
  return Array.from(shipGroupStore.shipGroups.values()).sort((a, b) =>
    a.name.localeCompare(b.name)
  );
});

const submit = () => {
  backend.attachShipsToGroup(selectedGroup.value, gameStore.modalData).then(() => {
    gameStore.modalType = "";
  });
};
</script>
