<script setup lang="ts">

import DeleteButton from '@/components/Elements/Buttons/DeleteButton.vue'
import { useGameStore } from '@/stores/gameStore.ts'
import GenericIcon from '@/components/Elements/Images/Icons/GenericIcon.vue'
import Backend from '@/models/backend.ts'

/**
 * Props
 */
const props = defineProps({
  id: {
    type: Number,
    required: true
  },
  name: {
    type: String,
    required: true
  }
})

/**
 * Stores
 */
const gameStore = useGameStore()


/**
 * Functions
 */
const deleteToken = () => {
  gameStore.modal2Type = 'confirmationDelete';
  gameStore.modal2Data = {
    title: 'Are you sure?',
    message: 'Are you sure you want to delete this device?',
    actionText: 'Yes',
    cancelText: 'No',
    action: () => {
      const backend = new Backend();
      backend.deleteFireBaseToken(props.id).then(() => {
        gameStore.setTinySuccessToastMessage('Device deleted')
      });
    },
  };
}

</script>

<template>
 <div class="flex flex-col">
    <div class="rounded bg-slate-900 p-2 mb-2 flex justify-between items-center">
      <div>
        {{ name }}
      </div>
      <div class="flex-shrink-0">
        <DeleteButton :for-icon="true" @click="deleteToken"><GenericIcon type="trash"/></DeleteButton>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>