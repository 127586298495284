<script setup lang="ts">

import type NewsItem from "@/types/NewsItem.ts";
import NewsItemHeader from '@/components/Interface/Headers/NewsItemHeader.vue'
import ImageRounded from '@/components/Interface/Images/ImageRounded.vue'
import ToastItem from '@/components/Interface/Toast/ToastItem.vue'
import { computed } from 'vue'
import { useGameStore } from '@/stores/gameStore.ts'
import NewsItemBaseStructure from '@/components/Sections/News/Types/Base/NewsItemBaseStructure.vue'
import NewsItemBase from '@/components/Sections/News/Types/Base/NewsItemBase.vue'
import ModalTrigger from '@/components/Interface/Modal/ModalTrigger.vue'
import Position from '@/components/Interface/Position.vue'
import GenericIconWithColoredNumber from '@/components/Elements/Images/Icons/GenericIconWithColoredNumber.vue'

const gameStore = useGameStore()

const props = defineProps<{
  newsItem: NewsItem,
  toast: boolean
}>();

const size = computed(() => {
  return props.newsItem.contentData.planetSize*2
})

</script>

<template>
  <NewsItemBase
    :toast="toast"
    :toast-image="gameStore.player!.profileImage"
    :toast-text="'Negative credits!'"
  >
      <NewsItemBaseStructure
        :newsItem="props.newsItem"
        :item-image="gameStore.player!.profileImage"
        :header="'Negative credits!'"
      >
        <p class="pb-2">Your empire has reached unacceptable levels of debt, and actions have been taken to correct your financial situation.</p>
        <div v-if="props.newsItem.contentData.action_taken.destroyRandomBuilding > 0"><span class="text-slate-300">{{ props.newsItem.contentData.action_taken.destroyRandomBuilding }}</span> Buildings have been sold</div>
        <div v-if="props.newsItem.contentData.action_taken.destroyRandomShip > 0"><span class="text-slate-300">{{ props.newsItem.contentData.action_taken.destroyRandomShip }}</span> Ships have been sold</div>
        <div v-if="props.newsItem.contentData.action_taken.dropPopulation > 0"><span class="text-slate-300">{{ props.newsItem.contentData.action_taken.dropPopulation }}</span> Planets have reported a population decrease.</div>
        <div class="flex pt-2"><div class="pr-2">Total credits recovered</div><GenericIconWithColoredNumber :amount="props.newsItem.contentData.credits_recovered" type="credit"/></div>

      </NewsItemBaseStructure>
  </NewsItemBase>
</template>

<style scoped lang="scss">

</style>