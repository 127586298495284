
<script setup lang="ts">

import { useStatsStore } from '@/stores/statsStore.ts'
import StatsGraph from './StatsGraph.vue';
import { onMounted } from 'vue'
import StandardCard from '@/components/Elements/Cards/StandardCard.vue' // Import the child component

const statsStore = useStatsStore()

onMounted(() => {
  statsStore.loadStatsForKey(statsStore.selectedKey ?? 'population')
});

</script>

<template>
  <div class="p-2">
    <StandardCard title="Statistics" top-padding="0">
      These section shows you anonymous statistics about this arena and your Empire.
    </StandardCard>
    <div class="flex flex-col md:flex-row pt-2">
      <div class="overflow-y-auto">
        <ul class="flex-column space-y space-y-1 text-xs sm:text-sm font-medium text-slate-300 w-full md:w-56">
          <template v-for="(item, index) in statsStore.statsKeys.values()" :key="item.key">
            <!-- Show group header when the group changes -->
            <li v-if="index === 0 || item.group !== [...statsStore.statsKeys.values()][index - 1].group" class="bg-slate-700 p-2 rounded">
              {{ item.group }}
            </li>

            <!-- Render the item -->
            <li>
              <a
                href="#"
                :class="{
                  'inline-flex items-center ps-3 py-2 text-slate-300 active w-full rounded ': true,
                  'bg-blue-500': statsStore.selectedKey === item.key,
                  'bg-slate-800 hover:bg-slate-700 hover:text-slate-300': statsStore.selectedKey !== item.key,
                }"
                aria-current="page"
                @click="statsStore.setSelectedKey(item.key)"
              >
                {{ item.label }}
              </a>
            </li>
            <li v-if="statsStore.selectedKey === item.key">
              <div class="h-96 block md:hidden">
                <StatsGraph />
              </div>
            </li>
          </template>
        </ul>
      </div>
      <div class="flex-grow pt-2 pl-0 md:pl-2 hidden md:block">
        <StatsGraph />
      </div>
  </div>
  </div>
</template>


<style scoped>
/* Custom styling if needed */
</style>
