<script setup lang="ts">
import { ref, onMounted, onUnmounted } from "vue";
import SmallModel from "@/components/Interface/Modal/ModelSizes/SmallModel.vue";
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import SimpleProgressBar from '@/components/Elements/ProgressBar/SimpleProgressBar.vue'
import { useGameStore } from '@/stores/gameStore.ts'

const gameStore = useGameStore()
const dayPercentage = ref(0);
const weekPercentage = ref(0);
const dayTimeLeft = ref('');
const weekTimeLeft = ref('');

const formatTimeLeft = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60);
  return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
};

const updateDayProgress = () => {
  const timeSinceTurn = ((Date.now() / 1000) - gameStore.dayTurnedAt);
  const totalTime = gameStore.dayInterval * 60;
  const timeLeft = Math.max(0, totalTime - timeSinceTurn);
  const percentage = (timeSinceTurn / (totalTime / 100));

  dayPercentage.value = Math.min(100, Math.max(0, percentage));
  dayTimeLeft.value = formatTimeLeft(timeLeft);
};

const updateWeekProgress = () => {
  const timeSinceTurn = ((Date.now() / 1000) - gameStore.weekTurnedAt);
  const totalTime = gameStore.weekInterval * 60;
  const timeLeft = Math.max(0, totalTime - timeSinceTurn);
  const percentage = (timeSinceTurn / (totalTime / 100));

  weekPercentage.value = Math.min(100, Math.max(0, percentage));
  weekTimeLeft.value = formatTimeLeft(timeLeft);
};

let dayInterval: ReturnType<typeof setInterval>;
let weekInterval: ReturnType<typeof setInterval>;

onMounted(() => {
  updateDayProgress();
  updateWeekProgress();
  dayInterval = setInterval(updateDayProgress, 1000);
  weekInterval = setInterval(updateWeekProgress, 1000);
});

onUnmounted(() => {
  clearInterval(dayInterval);
  clearInterval(weekInterval);
});
</script>

<template>
  <SmallModel title="In-game time">
    <StandardCard class="px-2" top-padding="2">
      Ferion time is divided into days and weeks. Ships move and attack every day, while planets, credits, science, ore and everything else is calculated every week.
    </StandardCard>
    <div class="flex justify-between p-1 px-2 pb-2">
      <StandardCard class="w-1/2">
        <div class="pb-2">A Ferion day is {{ gameStore.dayInterval }} real minutes.</div>
        <SimpleProgressBar :percentage="dayPercentage" color="blue" :text="dayTimeLeft" :height="6"/>
      </StandardCard>
      <StandardCard class="w-1/2 ps-2">
        <div class="pb-2">A Ferion week is {{ gameStore.weekInterval }} real minutes.</div>
        <SimpleProgressBar :percentage="weekPercentage" color="blue" :text="weekTimeLeft" :height="6"/>
      </StandardCard>
    </div>
  </SmallModel>
</template>
