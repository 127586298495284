<script setup lang="ts">
import { useTableStore } from '@/stores/tableStore.ts'
import { onMounted, watch } from 'vue'
import ActionButton from '@/components/Elements/Buttons/ActionButton.vue'

const tableStore = useTableStore()

const props = defineProps({
  tableStoreName: {
    type: String,
    required: true
  }
});

// Method to handle page change
const handlePageChange = (newPage: number) => {
  const totalPages = Math.ceil(tableStore[props.tableStoreName].totalFilteredRows / tableStore[props.tableStoreName].rowsPerPage);
  if (newPage >= 0 && newPage <= totalPages) {
    tableStore[props.tableStoreName].page = newPage;
  }
};

// watch if the totalFilteredRows changes and we are on a page that does not exist anymore
watch(() => tableStore[props.tableStoreName].totalFilteredRows, (newVal) => {
  const totalPages = Math.ceil(newVal / tableStore[props.tableStoreName].rowsPerPage);
  if (tableStore[props.tableStoreName].page > (totalPages-1)) {
    tableStore[props.tableStoreName].page = 0;
  }
});

onMounted(() => {
  // If the totalFilteredRows is 0, reset the page to 0
  const totalPages = Math.ceil(tableStore[props.tableStoreName].totalFilteredRows / tableStore[props.tableStoreName].rowsPerPage);
  if (tableStore[props.tableStoreName].page > (totalPages-1)) {
    tableStore[props.tableStoreName].page = 0;
  }
});

</script>

<template>
  <div class="flex justify-between px-2">
    <ActionButton @click="handlePageChange(tableStore[tableStoreName].page - 1)" :disabled="tableStore[tableStoreName].page === 0"><<</ActionButton>
    <div class="mx-2 self-center text-subtext">Page {{ tableStore[tableStoreName].page + 1 }} of {{ Math.ceil(tableStore[tableStoreName].totalFilteredRows / tableStore[tableStoreName].rowsPerPage) }}</div>
    <ActionButton @click="handlePageChange(tableStore[tableStoreName].page + 1)" :disabled="(tableStore[tableStoreName].page + 1) >= Math.ceil(tableStore[tableStoreName].totalFilteredRows / tableStore[tableStoreName].rowsPerPage)" :end-margin="false">>></ActionButton>
  </div>
</template>

<style scoped lang="scss">

button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
