<script setup lang="ts">
import {useGameStore} from "@/stores/gameStore.ts";
import { computed } from 'vue'
import { useAssetStore } from '@/stores/assetStore.ts'
import MediumModel from '@/components/Interface/Modal/ModelSizes/MediumModel.vue'
import { usePlanetStore } from '@/stores/planetStore.ts'
import ImageRounded from '@/components/Interface/Images/ImageRounded.vue'
import ImageRoundedUrl from '@/components/Interface/Images/ImageRoundedUrl.vue'
import { CDN } from '@/models/cdn.ts'
import NamedNumbers from '@/components/Elements/Generic/NamedNumbers.vue'

// Get the planet data
const gameStore = useGameStore()
const assetStore = useAssetStore()
const planetStore = usePlanetStore()

const outputOre = computed(() => {
  return assetStore.findOreById(gameStore.modalData.outputOreId)
})

const leftOre = computed(() => {
  return assetStore.findOreById(gameStore.modalData.inputOreLeftId)
})

const rightOre = computed(() => {
  return assetStore.findOreById(gameStore.modalData.inputOreRightId)
})

const factoryCount = computed(() => {
  return planetStore.countBuildingsOfType(gameStore.modalData.buildingType)
})

</script>

<template>
  <MediumModel :title="outputOre.oreName + ' Refinery'">
    <div class="flex flex-col sm:flex-row text-center sm:text-left p-2">
      <img
        :src="CDN.building(512,gameStore.modalData.buildingType)"
        alt=""
        class="self-center sm:self-start rounded w-auto md:w-1/3"
      />
      <div class="text-subtext ps-0 md:ps-2 pt-3 md:pt-0 w-full flex flex-col justify-between">
        <div class="pb-2">Converts {{outputOre.dependency1ConversionRate}} {{ leftOre.oreName }} and {{outputOre.dependency2ConversionRate}} {{ rightOre.oreName }} into 1 {{ outputOre.oreName }}</div>
        <div class="pt-2 flex justify-between">
          <NamedNumbers text="Factories">{{ factoryCount }}</NamedNumbers>
          <NamedNumbers text="Processed last week">{{ gameStore.modalData.capacity }}</NamedNumbers>
        </div>
      </div>
    </div>
  </MediumModel>
</template>


<style scoped lang="scss">

</style>