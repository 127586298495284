<script setup lang="ts">

import { useBattleReportStore } from '@/stores/battleReportStore.ts'
import ColoredNumber from '@/components/Elements/Generic/ColoredNumber.vue'
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'

const battleReportStore = useBattleReportStore();

</script>

<template>
  <StandardCard title="Ships" :no-padding="true">
    <div class="bg-slate-800 rounded p-2">
      <div class="flex justify-between">
        <div>Total</div>
        <div><ColoredNumber :amount="battleReportStore.attackerShips"/></div>
      </div>
      <div class="flex justify-between">
        <div>Lost</div>
        <div><ColoredNumber :amount="-battleReportStore.attackerShipsDestroyed"/></div>
      </div>

      <div class="flex justify-between">
        <div>Left</div>
        <div><ColoredNumber :amount="battleReportStore.attackerShips-battleReportStore.attackerShipsDestroyed"/></div>
      </div>
    </div>
  </StandardCard>

  <StandardCard title="Damage dealt" :no-padding="true">
    <div class="bg-slate-800 rounded p-2">
      <div class="flex justify-between">
        <div>Fleet vs Fleet</div>
        <div><ColoredNumber :amount="battleReportStore.defenderDamageTakenFleetVsFleet" :short-format="false"/></div>
      </div>
      <div class="flex justify-between">
        <div>Fleet vs Planet</div>
        <div><ColoredNumber :amount="battleReportStore.defenderDamageTakenFleetVsPlanet" :short-format="false"/></div>
      </div>

      <div class="flex justify-between">
        <div>Total</div>
        <div><ColoredNumber :amount="battleReportStore.defenderDamageTakenFleetVsFleet+battleReportStore.defenderDamageTakenFleetVsPlanet" :short-format="false"/></div>
      </div>
    </div>
  </StandardCard>

  <StandardCard title="Damage taken" :no-padding="true">
    <div class="bg-slate-800 rounded p-2">
      <div class="flex justify-between">
        <div>Fleet vs Fleet</div>
        <div><ColoredNumber :amount="-battleReportStore.attackerDamageTakenFleetVsFleet" :short-format="false"/></div>
      </div>
      <div class="flex justify-between">
        <div>Planet vs Fleet</div>
        <div><ColoredNumber :amount="-battleReportStore.attackerDamageTakenPlanetVsFleet" :short-format="false"/></div>
      </div>

      <div class="flex justify-between">
        <div>Total</div>
        <div><ColoredNumber :amount="-battleReportStore.attackerDamageTakenFleetVsFleet-battleReportStore.attackerDamageTakenPlanetVsFleet" :short-format="false"/></div>
      </div>
    </div>
  </StandardCard>

</template>

<style scoped lang="scss">

</style>