<script setup lang="ts">

import type NewsItem from "@/types/NewsItem.ts";
import NewsItemBaseStructure from '@/components/Sections/News/Types/Base/NewsItemBaseStructure.vue'
import NewsItemBase from '@/components/Sections/News/Types/Base/NewsItemBase.vue'
import { useGameStore } from '@/stores/gameStore.ts'

/**
 * Props
 */
const props = defineProps<{
  newsItem: NewsItem,
  toast: boolean
}>();

/**
 * Stores
 */
const gameStore = useGameStore();

</script>

<template>
  <NewsItemBase
    :toast="toast"
    :toast-image="gameStore.player!.profileImage"
    :toast-text="'Ships destroyed'"
  >
    <NewsItemBaseStructure
      :newsItem="props.newsItem"
      :item-image="gameStore.player!.profileImage"
      :header="'Ships destroyed'"
    >
      <div>
        <span class="text-slate-300">{{ props.newsItem.contentData.destroyed ?? 1 }}</span> ship(s) have been destroyed by an enemy spy. The following ship(s) were destroyed:
        <template v-for="(shipName, index) in props.newsItem.contentData.ships">
          <span class="text-slate-300">
            <template v-if="index > 0">, </template>{{ shipName }}
          </span>
        </template>
      </div>
    </NewsItemBaseStructure>
  </NewsItemBase>
</template>

<style scoped lang="scss">

</style>