<script setup lang="ts">

import { computed, defineProps } from 'vue'
import { usePlanetStore } from '@/stores/planetStore.ts'
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import NamedNumbers from '@/components/Elements/Generic/NamedNumbers.vue'
import GenericIconWithColoredNumber from '@/components/Elements/Images/Icons/GenericIconWithColoredNumber.vue'
import SimpleProgressBar from '@/components/Elements/ProgressBar/SimpleProgressBar.vue'

/**
 * Props
 */
const props = defineProps({
  planetId: {
    type: Number,
    required: true
  }
});

/**
 * Stores / models
 */
const planetStore = usePlanetStore()


/**
 * Computed
 */
const planetData = computed(() => {
  return planetStore.findPlanetById(props.planetId)!
})

const percentage = computed(() => {
  return (planetData.value.populationGrowthProcess / planetData.value.populationGrowthRequired) * 100
})

const etaText = computed(() => {
  const amount = Math.ceil((planetData.value.populationGrowthRequired - planetData.value.populationGrowthProcess) / planetData.value.populationGrowthPerTurn)
  if(amount < 0) return 'Never'
  return amount + ' weeks'
})


</script>

<template>
<StandardCard title="Population" top-padding="2" help="The population of your planet grows over time, all you need to do to increase your population is to keep producing a surplus of food. However, the larger the population, the more food is required to grow it.">

  <div class="flex justify-between pb-4">
    <NamedNumbers text="Population">
      <GenericIconWithColoredNumber :amount="planetData.population" type="person"/>
    </NamedNumbers>

    <NamedNumbers text="Food surplus">
      <GenericIconWithColoredNumber :amount="planetData.populationGrowthPerTurn" type="food"/>
    </NamedNumbers>
  </div>
  <SimpleProgressBar :percentage="percentage" :text="etaText" color="blue" :height="6"/>
  <div class="flex justify-between pt-4">
    <NamedNumbers text="Stored">
      <GenericIconWithColoredNumber :amount="planetData.populationGrowthProcess" type="food"/>
    </NamedNumbers>

    <NamedNumbers text="Required">
      <GenericIconWithColoredNumber :amount="planetData.populationGrowthRequired" type="food"/>
    </NamedNumbers>
  </div>
</StandardCard>
</template>

<style scoped lang="scss">

</style>
