import { defineStore } from 'pinia'
import Backend from '@/models/backend.ts'
import { useGameStore } from '@/stores/gameStore.ts'
import * as THREE from 'three'
import { useBattleReportShipsStore } from '@/stores/battleReportShipsStore.ts'
import type BRPlanetMesh from '@/types/Three/BattleReport/BRPlanetMesh.ts'

let renderer: THREE.WebGLRenderer | null = null;
let scene: THREE.Scene | null = null;
let camera: THREE.PerspectiveCamera | null = null;
let laserBeam: THREE.Line | null = null;
const explosions: THREE.Mesh[] = []; // Track explosions

export const useBattleReportStore = defineStore('battleReportStore', {
    state: () => ({
        loading: false,
        thisPlayerId: 0,
        attackingPlayerId: 0,
        defendingPlayerId: 0,
        objects: [] as any[],
        events: [] as any[],
        brContainer: null as HTMLDivElement | null,
        mapSize: { width: 0, height: 0 },
        playSpeed: 100 as number,
        running: false,

        // Keep track of interval and timeout IDs
        eventTimeoutId: null as number | null,
        scriptTimeoutId: null as number | null,

        // Track requestAnimationFrame ID so we can cancel it
        animationFrameId: null as number | null,
        // Data
        hasPlanet: false,
        planetPlayerId: 0,
        attackerShips:0,
        attackerShipsDestroyed:0,
        defenderShips:0,
        defenderShipsDestroyed:0,
        attackerDamageTakenFleetVsFleet:0,
        attackerDamageTakenPlanetVsFleet:0,
        defenderDamageTakenFleetVsFleet:0,
        defenderDamageTakenFleetVsPlanet:0,
        planetShields:0,
        planetTroops:0,
        planetBuildingsDestroyed:0,
        planetPopulationKilled:0,
        planetName:'Planet',
        winner:0
    }),

    actions: {
        init(bgContainer: HTMLDivElement | null) {
            console.log('BattleReport Init');
            if (!bgContainer) return;

            this.brContainer = bgContainer;

            const gameStore = useGameStore();
            this.thisPlayerId = gameStore.player?.id || 0;
            this.loading = true;

            const backend = new Backend();
            backend.getBattleReport(gameStore.modalData.battleReportId).then((response: any) => {
                this.processData(response.data);
                this.initWebGL();
                this.loading = false;
                this.runScript();
            });
        },

        processData(data: any) {
            console.log('BattleReport Process Data');
            this.attackingPlayerId = data.attackerPlayerId;
            this.defendingPlayerId = data.defenderPlayerId;

            this.objects = data.objects;
            this.events = data.events;
        },

        initWebGL() {
            console.log('BattleReport Init WebGL');
            if (!this.brContainer) return;

            const battleReportShipStore = useBattleReportShipsStore();

            // Create the renderer
            renderer = new THREE.WebGLRenderer({
                antialias: true,
                powerPreference: 'high-performance',
                alpha: true
            });

            renderer.setSize(this.brContainer.clientWidth, this.brContainer.clientHeight);
            this.brContainer.appendChild(renderer.domElement);

            // Create the camera
            camera = new THREE.PerspectiveCamera(
              45,
              this.brContainer.clientWidth / this.brContainer.clientHeight,
              1,
              500
            );
            camera.position.set(1, 1, 100);
            camera.lookAt(0, 0, 0);

            // Create the scene
            scene = new THREE.Scene();

            // Calculate the map size
            const vFov = (camera.fov * Math.PI) / 180; // Convert degrees to radians
            const height = 2 * Math.tan(vFov / 2) * camera.position.z; // Frustum height
            const width = height * camera.aspect; // Frustum width
            this.mapSize = { width, height };

            // Create instanced meshes
            battleReportShipStore.initShipInstancedMesh(2000);
            if (battleReportShipStore.shipInstancedMesh) {
                scene.add(battleReportShipStore.shipInstancedMesh);
            }

            // Example for adding a ship (just for debugging)
            battleReportShipStore.addShip(0, -100000, -100000, 'small_frame', true);

            this.running = true;
            this.animate();
        },

        animate() {
            if (!this.running) return;
            this.animationFrameId = requestAnimationFrame(() => this.animate());
            if (!renderer || !scene || !camera) return;

            renderer.render(scene, camera);
        },

        reset() {
            console.log('BattleReport Reset');

            // Stop the animation loop
            if (this.animationFrameId) {
                cancelAnimationFrame(this.animationFrameId);
                this.animationFrameId = null;
            }

            // Clear interval and timeout if they exist
            if (this.eventTimeoutId) {
                clearInterval(this.eventTimeoutId);
                this.eventTimeoutId = null;
            }
            if (this.scriptTimeoutId) {
                clearTimeout(this.scriptTimeoutId);
                this.scriptTimeoutId = null;
            }

            // Reset state
            this.running = false;
            this.loading = false;
            this.attackingPlayerId = 0;
            this.defendingPlayerId = 0;
            this.objects = [];
            this.events = [];
            this.brContainer = null;
            renderer = null;
            camera = null;
            scene = null;
            this.mapSize = { width: 0, height: 0 };

            // Data
            this.hasPlanet = false
            this.planetPlayerId = 0
            this.attackerShips = 0
            this.attackerShipsDestroyed = 0
            this.defenderShips = 0
            this.defenderShipsDestroyed = 0
            this.attackerDamageTakenFleetVsFleet = 0
            this.attackerDamageTakenPlanetVsFleet = 0
            this.defenderDamageTakenFleetVsFleet = 0
            this.defenderDamageTakenFleetVsPlanet = 0
            this.planetShields = 0
            this.planetTroops = 0
            this.planetBuildingsDestroyed = 0
            this.planetPopulationKilled = 0
            this.planetName = 'Planet'
            this.winner = 0

            // Reset the battle report ship store as well
            const battleReportShipStore = useBattleReportShipsStore();
            battleReportShipStore.clear();
        },

        destroy() {
            console.log('BattleReport Destroy');

            // Clean up any objects in the scene
            if (scene) {
                scene.traverse((object: any) => {
                    if (object.isMesh) {
                        object.geometry.dispose();
                        object.material.dispose();
                    }
                });

                // Remove all objects from the scene
                while (scene.children.length > 0) {
                    scene.remove(scene.children[0]);
                }
            }

            // Dispose of the renderer
            if (renderer) {
                renderer.dispose();
            }

            // Remove the canvas element
            if (this.brContainer && renderer) {
                this.brContainer.removeChild(renderer.domElement);
            }

            // Finally reset everything
            this.reset();

            console.log('BattleReport Destroyed');
        },

        runScript() {
            console.log('BattleReport Run Script');

            this.addLaserBeam();

            // Add your objects (ships, planets, etc.)
            this.objects.forEach((object: any) => {
                if (object.type === 'ship') {
                    this.addShip(object);
                    if(object.playerId === this.attackingPlayerId) {
                        this.attackerShips++
                    } else {
                        this.defenderShips++
                    }
                } else if (object.type === 'planet') {
                    this.addDefendingPlanet(object);
                }
            });

            // Wait for all ships to have reached their destination, then run runEvents
            this.scriptTimeoutId = window.setTimeout(() => {
                this.runEvents();
            }, 2000);
        },

        runEvents() {
            console.log('BattleReport Run Events');

            // Clear any existing timeout to prevent duplicate executions
            if (this.eventTimeoutId) {
                clearTimeout(this.eventTimeoutId);
            }

            const processNextEvent = () => {
                if (this.events.length > 0 && this.running) {
                    this.processEvent();
                    this.eventTimeoutId = setTimeout(processNextEvent, this.playSpeed) as unknown as number;
                }
            };

            // Start the event processing loop
            processNextEvent();
        },

        processEvent() {
            const event = this.events.shift();
            console.log('Processing event', event);
            if (!event) return;

            if (event.type === 'attack') {
                this.processEventAttack(event);
            } else if (event.type === 'destroyed') {
                this.processEventDestroyed(event);
            } else if (event.type === 'buildingDestroyed' || event.type === 'populationKilled') {
                // Example: Show an explosion at this building's location
                const buildingLocation = this.getRandomLocationOnPlanet()
                this.addExplosion(buildingLocation.x, buildingLocation.y, 4);

                if (event.type === 'buildingDestroyed') {
                    this.planetBuildingsDestroyed++
                } else {
                    this.planetPopulationKilled = this.planetPopulationKilled + 1000000000
                }
            } else if(event.type === 'planetStats') {
                this.planetName = event.name
                this.planetTroops = event.health
                this.planetShields = event.shields
            } else if(event.type === 'winner') {
                this.clearLaserBeam()
                if(this.hasPlanet && this.planetPlayerId !== event.playerId) {
                    //wait one second
                    setTimeout(() => {
                        this.endAnimation()
                    }, 1000);
                }
                this.winner = event.playerId
            }
        },
        endAnimation() {
            //Fly all ships to the planet
            const battleReportShipStore = useBattleReportShipsStore();
            const ships = battleReportShipStore.shipInstancedMesh
            if(!ships) return
            for (let i = 0; i < ships.count; i++) {
                const planetCoords = this.getRandomLocationOnPlanet()
                const time = Math.random() * 5000
                battleReportShipStore.flyShipToPlanet(i, planetCoords.x, planetCoords.y,1000+time);
            }
        },
        processEventDestroyed(event: any) {
            this.clearLaserBeam();
            if (event.id.startsWith('ship:')) {
                const battleReportShipStore = useBattleReportShipsStore();
                const shipId = event.id.split(':')[1];
                const instanceId = battleReportShipStore.findInstanceIdByShipId(shipId);
                if (instanceId) {
                    console.log('Removing ship', shipId);
                    battleReportShipStore.removeShip(instanceId, shipId);

                    // Example: Show an explosion at this ship's last known location
                    const { x, y } = battleReportShipStore.findCoordinatesByShipId(shipId);
                    this.addExplosion(x, y, 4);
                }

                if(event.playerId === this.attackingPlayerId) {
                    this.attackerShipsDestroyed++
                } else {
                    this.defenderShipsDestroyed++
                }
            }
        },

        processEventAttack(event: any) {
            const battleReportShipStore = useBattleReportShipsStore();
            let attackingCoords = { x: 0, y: 0 };
            let defendingCoords = { x: 0, y: 0 };

            // Attacker
            if (event.attacker.id.startsWith('ship:')) {
                const attackingShipId = event.attacker.id.split(':')[1];
                attackingCoords = battleReportShipStore.findCoordinatesByShipId(attackingShipId);
            } else if (event.attacker.id.startsWith('planet:')) {
                attackingCoords = this.getRandomLocationOnPlanet();
            }

            // Defender
            if (event.defender.id.startsWith('ship:')) {
                const defendingShipId = event.defender.id.split(':')[1];
                defendingCoords = battleReportShipStore.findCoordinatesByShipId(defendingShipId);
            } else if (event.defender.id.startsWith('planet:')) {
                defendingCoords = this.getRandomLocationOnPlanet();
            } else {
                console.warn('Unknown defender type', event);
            }

            if(event.defender.damageShipToShip){
                if(event.defender.playerId === this.attackingPlayerId) {
                    this.attackerDamageTakenFleetVsFleet += event.defender.damageShipToShip;
                } else {
                    this.defenderDamageTakenFleetVsFleet += event.defender.damageShipToShip;
                }
            } else if(event.defender.damageShipToPlanet) {
                this.defenderDamageTakenFleetVsPlanet += event.defender.damageShipToPlanet;
                this.planetShields = event.defender.shields < 0 ? 0 : event.defender.shields
                this.planetTroops = event.defender.health < 0 ? 0 : event.defender.health
            } else if(event.defender.damagePlanetToShip) {
                this.attackerDamageTakenPlanetVsFleet += event.defender.damagePlanetToShip;
            }

            // Set shields
            if (event.defender.id.startsWith('planet:')) {
                this.updatePlanetShieldOpacity(event.defender.shields);
            }
            this.updateLaserBeam(attackingCoords, defendingCoords, event.defender.playerId);
        },

        getRandomLocationOnPlanet(): { x: number; y: number } {
            const x = this.mapSize.width / 2.1 - Math.random() * 10;
            const y = Math.random() * 25 - 10;
            return { x, y };
        },

        updatePlanetShieldOpacity(shields: number) {
            if (!scene) return;

            // Find the defending planet by its objectType
            const planet = scene.children.find(
              (child: any) => child.objectType === 'planet'
            ) as BRPlanetMesh | undefined;

            if (!planet) {
                console.warn('Defending planet not found');
                return;
            }

            // Update the shields property
            planet.shield = shields;

            // Ensure the shield material updates
            if ((planet as any)._shieldMaterial) {
                (planet as any)._shieldMaterial.opacity = shields / 100;
                (planet as any)._shieldMaterial.needsUpdate = true; // Force material update
            }
        },

        addLaserBeam() {
            if (!scene || !renderer || !camera) return;

            const geometry = new THREE.BufferGeometry();
            const vertices = new Float32Array([
                1, 1, 0, // Start
                1, 1, 0  // End
            ]);
            geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));

            const material = new THREE.LineBasicMaterial({ color: 0xff0000 });

            laserBeam = new THREE.Line(geometry, material);
            laserBeam.geometry.dispose(); // Dispose old geometry if it exists
            laserBeam.geometry = geometry;
            laserBeam.material = material;

            laserBeam.name = 'laserBeam';
            scene.add(laserBeam);
        },

        updateLaserBeam(start: any, end: any, playerId: number) {
            if (!laserBeam) return;

            // Pick color based on who is attacking
            if (playerId !== this.thisPlayerId) {
                laserBeam.material = new THREE.LineBasicMaterial({ color: 0x00ff00 });
            } else {
                laserBeam.material = new THREE.LineBasicMaterial({ color: 0xff0000 });
            }

            const position = laserBeam.geometry.attributes.position as THREE.BufferAttribute;
            position.setXYZ(0, start.x, start.y, 1);
            position.setXYZ(1, end.x, end.y, 1);
            position.needsUpdate = true;
        },

        clearLaserBeam() {
            // Move it offscreen or hide it
            this.updateLaserBeam({ x: 1000, y: 1000 }, { x: 1000, y: 1000 }, 0);
        },

        addDefendingPlanet(object: any) {
            if (!scene || !renderer || !camera) return;

            this.hasPlanet = true
            this.planetPlayerId = object.playerId


            const gameStore = useGameStore();
            const texture = new THREE.TextureLoader().load(
              `${gameStore.cdn}ferion/images/planets/${object.planetType}/3/512.webp`
            );

            const geometry = new THREE.PlaneGeometry(50, 50);
            const material = new THREE.MeshBasicMaterial({ map: texture, transparent: true });
            const planet = new THREE.Mesh(geometry, material) as unknown as BRPlanetMesh;

            planet.position.set(this.mapSize.width / 2, 0, -1.5);
            planet.renderOrder = -1;
            planet.name = object.id;
            (planet as any).objectType = 'planet';
            (planet as any)._health = object.health;

            // // Decide color
            // const gameStorePlayerId = useGameStore().player?.id;
            // (planet as any)._color = 0xff0000;
            // let healthBarColor = 0xaa0000;
            //
            // if (this.defendingPlayerId === gameStorePlayerId) {
            //     (planet as any)._color = 0x00ff00;
            //     healthBarColor = 0x00aa00;
            // }

            scene.add(planet);

            // Create health bar
            // const healthBarGeometry = new THREE.PlaneGeometry(Math.floor(object.health / 10), 0.25);
            // const healthBarMaterial = new THREE.MeshBasicMaterial({ color: healthBarColor });
            // const healthBar = new THREE.Mesh(healthBarGeometry, healthBarMaterial);

            // healthBar.position.set(-0.5, -28, -1.2);
            // planet.add(healthBar);
            // (planet as any)._healthBar = healthBar;

            // Define health property with getter/setter
            // Object.defineProperty(planet, 'health', {
            //     get() {
            //         return (this as any)._health;
            //     },
            //     set(value) {
            //         (this as any)._health = value;
            //         const newWidth = value / 10;
            //         (this as any)._healthBar.geometry.dispose();
            //         (this as any)._healthBar.geometry = new THREE.PlaneGeometry(newWidth, 0.25);
            //     }
            // });

            // Shield Image
            const shieldTexture = new THREE.TextureLoader().load(`${gameStore.cdn}/images/planet/shield.png`);
            const shieldGeometry = new THREE.PlaneGeometry(54, 54);
            const shieldMaterial = new THREE.MeshBasicMaterial({ map: shieldTexture, transparent: true });
            const shield = new THREE.Mesh(shieldGeometry, shieldMaterial);

            shield.position.set(0, 0, 0.1);
            shield.renderOrder = -1;
            shieldMaterial.opacity = object.shields / 100;

            planet.add(shield);
            (planet as any)._shieldMaterial = shieldMaterial;
            (planet as any)._shields = object.shields; // initial

            Object.defineProperty(planet, 'shields', {
                get() {
                    return (this as any)._shields;
                },
                set(value) {
                    (this as any)._shields = value;
                    (this as any)._shieldMaterial.opacity = value / 100;
                }
            });
        },

        addShip(object: any) {
            if (!this.mapSize.width || !this.mapSize.height) return;

            const battleReportShipStore = useBattleReportShipsStore();

            // Basic parameters
            const middleGapPct = 0.1; // % of total width to keep clear in the middle
            const halfWidth = this.mapSize.width / 2;
            const halfHeight = this.mapSize.height / 2;

            const xOffset = 4;
            const yOffset = 4;

            const middleGapWidth = this.mapSize.width * middleGapPct;

            const leftMinX = -halfWidth + xOffset;
            const leftMaxX = -(middleGapWidth / 2) - xOffset;

            const rightMinX = middleGapWidth / 2 + xOffset;
            const rightMaxX = halfWidth - xOffset;

            const minY = -halfHeight + yOffset;
            const maxY = halfHeight - yOffset;

            console.log('Adding ship', object);

            const isLeftSide = object.playerId === this.attackingPlayerId;
            let x: number;

            if (isLeftSide) {
                x = leftMinX + Math.random() * (leftMaxX - leftMinX);
            } else {
                x = rightMinX + Math.random() * (rightMaxX - rightMinX);
            }

            const y = minY + Math.random() * (maxY - minY);

            // Strip "ship:" prefix
            const shipId = object.id.split(':')[1];

            const instanceId = battleReportShipStore.addShip(shipId, x, y, object.frame, !isLeftSide);
            if (isLeftSide) {
                battleReportShipStore.animateShip(instanceId, x - 100, x, Math.random() * 2000);
            } else {
                battleReportShipStore.animateShip(instanceId, x + 100, x, Math.random() * 2000);
            }

            object.instanceId = instanceId;
        },
        addExplosion(x: number, y: number,size: number) {
            if (!scene) return;

            // Limit number of explosions on screen
            if (explosions.length >= 50) {
                const oldestExplosion = explosions.shift();
                if (oldestExplosion) {
                    scene.remove(oldestExplosion);
                    oldestExplosion.geometry.dispose();
                    if (oldestExplosion.material instanceof THREE.Material) {
                        oldestExplosion.material.dispose();
                    }
                }
            }


            // Load the texture (48 frames, 8 columns x 6 rows)
            const texture = new THREE.TextureLoader().load(
              'https://cdn.galexion.com/images/tests/explode.webp'
            );

            // Setup sprite sheet parameters
            const columns = 8;
            const rows = 6;
            const totalFrames = columns * rows; // 48
            const frameWidth = 1 / columns;     // fraction of texture width
            const frameHeight = 1 / rows;       // fraction of texture height

            // Use a simple Plane for the explosion
            const geometry = new THREE.PlaneGeometry(size, size);
            const material = new THREE.MeshBasicMaterial({
                map: texture,
                transparent: true,
            });

            // Repeat only the size of one frame
            texture.wrapS = THREE.RepeatWrapping;
            texture.wrapT = THREE.RepeatWrapping;
            texture.repeat.set(frameWidth, frameHeight);

            // Create mesh
            const explosionMesh = new THREE.Mesh(geometry, material);
            explosionMesh.position.set(x, y, 2);
            scene.add(explosionMesh);

            // Track the current frame
            let currentFrame = 0;

            // Animate frames using setInterval (simple approach)
            const frameInterval = 50; // ms per frame, adjust if you want faster/slower animation
            const intervalId = setInterval(() => {
                currentFrame++;
                if (currentFrame >= totalFrames) {
                    // Finished animation, cleanup
                    clearInterval(intervalId);
                    if(!scene) return
                    scene.remove(explosionMesh);

                    // Dispose geometry/material to free memory
                    explosionMesh.geometry.dispose();
                    explosionMesh.material.dispose();
                    return;
                }

                // Calculate the new offset in the sprite sheet
                const column = currentFrame % columns;
                const row = Math.floor(currentFrame / columns);

                // Texture offset is (x, y) from bottom-left in Three.js
                // If sprite sheet frames go top-to-bottom, invert the row offset
                // But in this sheet, frames appear to go left-to-right, top-to-bottom
                texture.offset.x = column * frameWidth;
                texture.offset.y = 1 - (row + 1) * frameHeight;
            }, frameInterval);
        },
    }
});
