<script setup lang="ts">

import GenericIconWithColoredNumber from '@/components/Elements/Images/Icons/GenericIconWithColoredNumber.vue'
import { useShipDesignCreationStore } from '@/stores/shipDesignCreationStore.ts'
import { computed } from 'vue'
import ActionButton from '@/components/Elements/Buttons/ActionButton.vue'
import { useGameStore } from '@/stores/gameStore.ts'

/**
 * Stores / models
 */
const shipDesignCreationStore = useShipDesignCreationStore()
const gameStore = useGameStore()

/**
 * Function
 */
const save = () => {
  gameStore.modalType = 'createShipDesignSave'
}

/**
 * Computed
 */
//Calculate the percentage of the ship size in use (make reactive)
const shipSizePercentage = computed(() => {
  if(shipDesignCreationStore.shipFrame === 0) return 0
  const percentage = (shipDesignCreationStore.shipMass / shipDesignCreationStore.shipFrame) * 100
  if(percentage > 100) return 100
  return percentage
})

const overloaded = computed(() => {
  return shipDesignCreationStore.shipFrame < shipDesignCreationStore.shipMass
})

</script>

<template>
  <div class="">
    <div class="flex pb-1">
      <div class="p-2 rounded bg-slate-800 flex justify-center flex-grow">
        <div class="flex-shrink-0 px-2 self-center">
          <GenericIconWithColoredNumber :amount="shipDesignCreationStore.shipMass" type="mass" :shortFormat="false"/>
        </div>
        <div class="flex-grow px-1 self-center">
          <div class="w-full rounded h-5 bg-gray-700">
            <div :class="{
              'bg-blue-600': shipDesignCreationStore.shipFrame > shipDesignCreationStore.shipMass,
              'bg-red-600': shipDesignCreationStore.shipFrame < shipDesignCreationStore.shipMass,
              'bg-green-600': shipDesignCreationStore.shipFrame === shipDesignCreationStore.shipMass,
              'h-5 rounded' : true
            }" :style="'width: '+ shipSizePercentage +'%'"></div>
          </div>
        </div>
        <div class="flex-shrink-0 px-2 py-1 self-center">
          <GenericIconWithColoredNumber :amount="shipDesignCreationStore.shipFrame" type="frame" :shortFormat="false"/>
        </div>
      </div>
      <div class="flex-shrink-0 rounded bg-slate-800 p-2 ml-1" v-if="shipDesignCreationStore.hasEngine && !overloaded">
        <ActionButton @click="save">Save</ActionButton>
      </div>
    </div>
    <div class="grid grid-cols-5 gap-1">
      <div class="p-2 rounded bg-slate-800 flex justify-center">
        <GenericIconWithColoredNumber :amount="shipDesignCreationStore.shipAttackShips" type="attack_against_ships"/>
      </div>
      <div class="p-2 rounded bg-slate-800 flex justify-center">
        <GenericIconWithColoredNumber :amount="shipDesignCreationStore.shipAttackPlanets" type="attack_against_planets"/>
      </div>
      <div class="p-2 rounded bg-slate-800 flex justify-center">
        <GenericIconWithColoredNumber :amount="shipDesignCreationStore.shipShields" type="shields"/>
      </div>
      <div class="p-2 rounded bg-slate-800 flex justify-center">
        <GenericIconWithColoredNumber :amount="shipDesignCreationStore.shipHitPoints" type="hit_points"/>
      </div>
      <div class="p-2 rounded bg-slate-800 flex justify-center">
        <GenericIconWithColoredNumber :amount="shipDesignCreationStore.shipMaxSpeed" type="max_speed"/>
      </div>
      <div class="p-2 rounded bg-slate-800 flex justify-center">
        <GenericIconWithColoredNumber :amount="shipDesignCreationStore.shipScanningRange" type="scanning_range"/>
      </div>
      <div class="p-2 rounded bg-slate-800 flex justify-center">
        <GenericIconWithColoredNumber :amount="shipDesignCreationStore.shipRepair" type="repair"/>
      </div>
      <div class="p-2 rounded bg-slate-800 flex justify-center">
        <GenericIconWithColoredNumber :amount="shipDesignCreationStore.shipProductionCost" type="production"/>
      </div>
      <div class="p-2 rounded bg-slate-800 flex justify-center">
        <GenericIconWithColoredNumber :amount="shipDesignCreationStore.maintenanceCost" type="credit"/>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>