<script setup lang="ts">

import { useGameStore } from '@/stores/gameStore.ts'
import Position from '@/components/Interface/Position.vue'
import PlayerName from '@/components/Objects/PlayerName.vue'
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import GenericIcon from '@/components/Elements/Images/Icons/GenericIcon.vue'

/**
 * Props
 */
const props = defineProps<{
  shipActionData: {
    action: string;
    targetX: number;
    targetY: number;
    targetPlayer: number;
    targetShip: number;
    data: {
      name: string;
      type: number;
      typeVariantId: number;
      targetPlayer: number;
    }
  },
  eta: number
}>();

</script>

<template>
  <StandardCard top-padding="0" bottom-padding="1">
    <div class="flex justify-between text-subtext w-full">
      <div class="flex flex-wrap gap-4">
        <GenericIcon tooltip="Follow & Attack" type="cmd_followattack" class="hidden md:inline" />
        <div class="flex flex-wrap items-center">
          <div class="me-1">
            Follow & Attack <PlayerName :playerId="shipActionData.targetPlayer" /> to
            <span>{{ shipActionData.data.name }}</span>
          </div>
          <Position class="pr-1" :x="props.shipActionData.targetX" :y="props.shipActionData.targetY" />
          <span v-if="props.shipActionData.data.status === 'tracking'">(Tracking)</span>
          <span v-if="props.shipActionData.data.status === 'on_position'">(Caught up with target)</span>
          <span v-if="props.shipActionData.data.status === 'lost_tracking'">(Lost tracking, moving to last known position)</span>
        </div>
      </div>

      <div class="flex-shrink-0 ps-2">
        {{ eta }} days
      </div>
    </div>
  </StandardCard>
</template>

<style scoped lang="scss">

</style>