<script setup lang="ts">

import { useGameStore } from '@/stores/gameStore.ts'
import Ministry from '@/components/Sections/Government/Cabinet/Ministry.vue'
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import GenericIcon from '@/components/Elements/Images/Icons/GenericIcon.vue'
import GenericIconWithNumber from '@/components/Elements/Images/Icons/GenericIconWithNumber.vue'
import NamedNumbers from '@/components/Elements/Generic/NamedNumbers.vue'

/**
 * Stores / models
 */
const gameStore = useGameStore()

</script>

<template>
  <div id="main-content" class="content-height-limit-tab overflow-y-scroll">
    <div class="flex justify-center md:pt-10 px-2 pt-1 sm:pt-0">
      <StandardCard title="Leader" class="sm:w-10/12 xl:w-8/12 2xl:w-1/2">
        <div class="flex justify-center flex-col md:flex-row">
          <div class="rounded md:pr-2">
            <img :src="gameStore.player?.profileImage" alt="Leader" class="">
          </div>
          <div class="">
            <p class="text-slate-300 pb-2 md:pt-0 pt-2">
              Hail {{ gameStore.player?.nickName }}!, our mighty leader!
            </p>
            <p class="pb-2">
              Welcome to your Cabinet! Here, you can appoint ministers to various ministries, each offering two candidates. You may select only one candidate for each ministry.
            </p>
            <p class="pb-2">
              Strategically choosing which minister to appoint at the right time is crucial, as each one provides unique benefits to your empire.
            </p>
            <p class="pb-2">
              Appointing a minister requires VIP Tokens <GenericIcon tooltip="Vip tokens" type="vip"/>. These tokens are earned automatically over time.
            </p>
            <p>
              Please note, once a minister is appointed, the decision is final—choose wisely!
            </p>
            <div class="flex justify-center py-4">
              <NamedNumbers text="VIP Tokens">
                <GenericIconWithNumber type="vip" :amount="Number(gameStore.player?.vipTokens)" />
              </NamedNumbers>
            </div>
          </div>
        </div>
      </StandardCard>
    </div>
    <div class="text-center pt-10 flex justify-center">
      <div class="grid 2xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-10">
        <Ministry code="ministry_of_industry"></Ministry>
        <Ministry code="ministry_of_cartography"></Ministry>
        <Ministry code="ministry_of_colonization"></Ministry>
        <Ministry code="ministry_of_exploration"></Ministry>

        <Ministry code="ministry_of_defense"></Ministry>
        <Ministry code="ministry_of_conquest"></Ministry>
        <Ministry code="ministry_of_maintenance"></Ministry>
        <Ministry code="placeholder"></Ministry>

        <Ministry code="placeholder"></Ministry>
        <Ministry code="placeholder"></Ministry>
        <Ministry code="placeholder"></Ministry>
        <Ministry code="placeholder"></Ministry>
      </div>
    </div>

    <div class="pb-32"></div>
  </div>
</template>

<style scoped lang="scss">

</style>
