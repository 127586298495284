import { defineStore } from 'pinia';
import type PlayerSpy from '@/types/PlayerSpy.ts'

export const useSpyStore = defineStore('spyStore', {
    state: () => ({
        spies: new Map<number, PlayerSpy>()
    }),
    getters: {
        findSpyById: (state) => (id: number): PlayerSpy | undefined => {
            return state.spies.get(id);
        }
    },
    actions: {
        updateSpyById(id: number, data: PlayerSpy) {
            //console.log('updateSpyById', id, data);
            if(this.spies.has(id)) {
                const item = this.spies.get(id);
                // Manually merge changes
                for (const key in data) {
                    item[key] = data[key];
                }
                data = item;
            }

            this.spies.set(id, data);
        }
    },
});
