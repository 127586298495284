<script setup lang="ts">

import { useAssetStore } from '@/stores/assetStore.ts'
import { onMounted, reactive, watch } from 'vue'
import { usePlanetStore } from '@/stores/planetStore.ts'
import FerionDataTable from '@/components/Interface/DataTable/FerionDataTable.vue'
import HeaderTextFlex from '@/components/Interface/DataTable/Headers/HeaderTextFlex.vue'
import CellFlexWidth from '@/components/Interface/DataTable/Cell/CellFlexWidth.vue'
import HeaderFixedWidthText from '@/components/Interface/DataTable/Headers/HeaderFixedWidthText.vue'
import CellFixedWidth from '@/components/Interface/DataTable/Cell/CellFixedWidth.vue'
import IconCount from '@/components/Objects/IconCount.vue'
import Icon from '@/components/Objects/Icons/Icon.vue'
import { useGameStore } from '@/stores/gameStore.ts'
import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'
import Backend from '@/models/backend.ts'
import ActionButton from '@/components/Elements/Buttons/ActionButton.vue'
import GenericIconWithColoredNumber from '@/components/Elements/Images/Icons/GenericIconWithColoredNumber.vue'
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import DeleteButton from '@/components/Elements/Buttons/DeleteButton.vue'

const assetStore = useAssetStore()
const planetStore = usePlanetStore()
const gameStore = useGameStore()
const backend = new Backend()

const buildings = reactive(new Map<string, {
  id: string;
  name: string;
  quantity: number;
  creditsPerBuilding: number;
  totalCredits: number;
  effects: Record<string, any>;
}>());

const calculateList = () => {
  // Iterate through all buildings and count them
  let buildingList = new Map<string, number>()
  for(const planet of planetStore.planets.values()) {
    for(const buildingCode of planet.buildings) {
      if(buildingList.has(buildingCode)) {
        const value = buildingList.get(buildingCode) ?? 0
        buildingList.set(buildingCode, value + 1)
      } else {
        buildingList.set(buildingCode, 1)
      }
    }
  }
  // For each building in the buildinList, check the assetStore for the effects and calculate the total cost
  for(const [buildingCode, amount] of buildingList) {
    const building = assetStore.buildings.get(buildingCode)
    if(building) {
      let creditsPerBuilding = 0
      for (const [key, value] of Object.entries(building.effects)) {
        if (value.type === 'credit') {
          creditsPerBuilding += value.bonus
        }
      }
      const totalCredits = creditsPerBuilding * amount
      if(totalCredits === 0) {
        continue
      }
      if(amount === 0) {
        continue
      }
      buildings.set(buildingCode, {
        id: buildingCode,
        name: building.buildingName,
        quantity: amount,
        creditsPerBuilding: creditsPerBuilding,
        totalCredits: totalCredits,
        effects: building.effects
      })
    }
  }
}

onMounted(() => {
  calculateList()
})

const loadBuilding = (buildingCode: string) => {
  gameStore.modalType = 'building'
  gameStore.modalData = {
    buildingCode: buildingCode
  }
}

const deleteBuilding = (buildingCode: string) => {
  // Find the planet with the building
  for(const planet of planetStore.planets.values()) {
    if(planet.buildings.includes(buildingCode)) {
      backend.deleteBuildingFromPlanet(planet.id, buildingCode).then(() => {
        gameStore.setTinySuccessToastMessage('Building deleted')
      })
      return
    }
  }
}

const filters = (buildings:Map<string, {
  id: string;
  name: string;
  quantity: number;
  creditsPerBuilding: number;
  totalCredits: number;
  effects: Record<string, any>;
}>) => {
  return Array.from(buildings.values())
}

watch(() => planetStore.planets, () => {
  calculateList()
}, { deep: true })

</script>

<template>
  <StandardCard top-padding="0" title="Buildings" help="Buildings can generate or cost credits. Below you have an overview of the buildings in your empire that affect your credits. If you wish to cut costs, you can chose to delete one of a specific building. This will destroy one building of the selected type on a random planet."/>
  <FerionDataTable
    :data="buildings"
    tableStoreName="financeBuildings"
    :selector="false"
    :filters="filters"
    :heightLimit="'h-[calc(100vh-19.5rem)]'"
    :no-result-message="'No data yet...'"
    :click="loadBuilding"
    :footer-bar="false"
  >
    <template #columns="{ sort, sortColumn, sortDirection }">
      <HeaderTextFlex min-width="10" column="name" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort" class="rounded-tl">
        <div class="pl-2">Building</div>
      </HeaderTextFlex>
      <HeaderFixedWidthText width="5" column="quantity" :sortDirection="sortDirection" :sort-column="sortColumn" @sort="sort" align="center">
        Quantity
      </HeaderFixedWidthText>
      <HeaderFixedWidthText width="10" column="creditsPerBuilding" :sortDirection="sortDirection" :sort-column="sortColumn" @sort="sort" align="center">
        Credits Per Building
      </HeaderFixedWidthText>
      <HeaderFixedWidthText width="10" column="totalCredits" :sortDirection="sortDirection" :sort-column="sortColumn" @sort="sort" align="center">
        Total Credits
      </HeaderFixedWidthText>
      <HeaderFixedWidthText width="10" column="" :sortDirection="sortDirection" :sort-column="sortColumn" @sort="sort" align="center" class="rounded-tr">
        Action
      </HeaderFixedWidthText>
    </template>
    <!--- Rows --->
    <template #rows="{ item }" >
      <CellFlexWidth min-width="10">
        <div class="flex-col ml-2 ">
          {{ item.name }}
        </div>
      </CellFlexWidth>
      <CellFixedWidth width="5">
        <div class="flex-col ml-2 ">
          {{ item.quantity }}
        </div>
      </CellFixedWidth>
      <CellFixedWidth width="10">
        <div class="flex-col ml-2 ">
          <GenericIconWithColoredNumber :amount="item.creditsPerBuilding" type="credit"/>
        </div>
      </CellFixedWidth>
      <CellFixedWidth width="10">
        <div class="flex-col ml-2 ">
          <GenericIconWithColoredNumber :amount="item.totalCredits" type="credit"/>
        </div>
      </CellFixedWidth>
      <CellFixedWidth width="10">
        <div class="flex-col ml-2 ">
          <DeleteButton @click.stop="deleteBuilding(item.id)">Delete one</DeleteButton>
        </div>
      </CellFixedWidth>
    </template>
  </FerionDataTable>
</template>

<style scoped lang="scss">

</style>