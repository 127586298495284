<script setup lang="ts">

import { computed } from 'vue'
import { useFleetStore } from '@/stores/fleetStore.ts'
import ShipStatIcons from '@/components/Objects/IconsWithCounters/ShipStatIcons.vue'
import { useGameStore } from '@/stores/gameStore.ts'
import FleetDetailsTabs from '@/components/Sections/Command/Fleets/FleetDetails/FleetDetailsTabs.vue'
import Position from '@/components/Interface/Position.vue'
import ModalTrigger from '@/components/Interface/Modal/ModalTrigger.vue'

const fleetStore = useFleetStore()
const gameStore = useGameStore()

const props = defineProps({
  fleetId: Number
});

const fleetData = computed(() => {
  return fleetStore.findFleetById(props.fleetId ?? 0)
})

</script>

<template>
  <div v-if="props.fleetId !==0 && fleetData">
    <div class="shipHeader flex flex-col justify-between bg-cover rounded" :style="'background-image: url(' + gameStore.cdn + 'images/fleet/1.webp)'">
      <span class="p-2 text-2xl flex justify-between w-full items-center">
        <span class="flex items-center">
          <ModalTrigger type="editFleetName" :data="{fleetId: props.fleetId}">
            {{ fleetData.name }}
          </ModalTrigger>
          <Position :x="fleetData.xPos" :y="fleetData.yPos" class="ms-1"></Position>
        </span>
      </span>
    </div>
    <div class="pt-2">
      <div class="grid grid-cols-4 sm:grid-cols-7 gap-1">
        <div class="bg-slate-800 text-subtext text-center rounded">
          <div class="flex justify-center p-1">
            <ShipStatIcons type="hit_points" :amount="fleetData.healthPercentage" :percentage="true"/>
          </div>
        </div>
        <div class="bg-slate-800 text-subtext text-center rounded">
          <div class="flex justify-center p-1">
            <ShipStatIcons type="attack_against_ships" :amount="fleetData.attackShips" />
          </div>
        </div>
        <div class="bg-slate-800 text-center text-subtext rounded">
          <div class="flex justify-center p-1">
            <ShipStatIcons type="attack_against_planets" :amount="fleetData.attackPlanets" />
          </div>
        </div>
        <div class="bg-slate-800 text-center text-subtext rounded">
          <div class="flex justify-center p-1">
            <ShipStatIcons type="shields" :amount="fleetData.shields" />
          </div>
        </div>
        <div class="bg-slate-800 text-subtext text-center rounded">
          <div class="flex justify-center p-1">
            <ShipStatIcons type="max_speed" :amount="fleetData.maxSpeed" />
          </div>
        </div>
        <div class=" bg-slate-800 text-center text-subtext rounded">
          <div class="flex justify-center p-1">
            <ShipStatIcons type="scanning_range" :amount="fleetData.scanningRange" />
          </div>
        </div>
        <div class=" bg-slate-800 text-center text-subtext rounded">
          <div class="flex justify-center p-1">
            <ShipStatIcons type="repair" :amount="fleetData.repair" />
          </div>
        </div>
      </div>
    </div>
    <FleetDetailsTabs :fleetId="props.fleetId"></FleetDetailsTabs>
  </div>
</template>

<style scoped lang="scss">
.shipHeader{
  height: 8rem;
  background-size: cover;
  background-position: center;
}
</style>