<script setup>
import { ref, onMounted, onUnmounted, defineProps } from 'vue'
import { useTableStore } from '@/stores/tableStore.ts'
import { useGameStore } from '@/stores/gameStore.ts'
import { usePQAStore } from '@/stores/PQAStore.ts'
import Backend from '@/models/backend.ts'
import { useMapStore } from '@/stores/mapStore.ts'
import { usePlanetGroupStore } from '@/stores/planetGroupStore.ts'
import GenericIcon from '@/components/Elements/Images/Icons/GenericIcon.vue'

/**
 * Stores / models
 */
const tableStore = useTableStore()
const gameStore = useGameStore()
const pqaStore = usePQAStore()
const mapStore = useMapStore()
const planetGroupStore = usePlanetGroupStore()

/**
 * Props
 */
const props = defineProps({
  text: {
    type: String,
    default: 'Action'
  }
});

/**
 * Variables
 */
const menuRef = ref(null);

/**
 * Functions
 */
function toggleMenu() {
  if(gameStore.pullDownMenuVisible === 'planetMultiSelectMenu') {
    closeMenu()
  } else {
    gameStore.pullDownMenuVisible = 'planetMultiSelectMenu'
  }
}


/**
 * Assign ships to a fleet
 */
const setProductionQueue = () => {
  if (tableStore.planets.selectedRowIds.length > 0) {
    gameStore.modalType = 'planetsSetProductionQueue'
    gameStore.modalData = tableStore.planets.selectedRowIds;
    tableStore.planets.selectedAll = false;

    // Disable area selection on the map
    mapStore.disableAreaSelection();
  }
}

const setPopulationAssignments = () => {
  if (tableStore.planets.selectedRowIds.length > 0) {
    gameStore.modalType = 'planetsSetPopulationAssignments'
    gameStore.modalData = tableStore.planets.selectedRowIds;
    tableStore.planets.selectedAll = false;

    // Disable area selection on the map
    mapStore.disableAreaSelection();
  }
}

const setTax = () => {
  if (tableStore.planets.selectedRowIds.length > 0) {
    gameStore.modalType = 'planetsSetTax'
    gameStore.modalData = tableStore.planets.selectedRowIds;
    tableStore.planets.selectedAll = false;

    // Disable area selection on the map
    mapStore.disableAreaSelection();
  }
}

const clearProductionQueues = () => {
  const backend = new Backend()
  backend.clearProductionFromPlanet(tableStore.planets.selectedRowIds)
  gameStore.setTinySuccessToastMessage('Production queues cleared')
  tableStore.planets.selectedAll = false;

  // Disable area selection on the map
  mapStore.disableAreaSelection();

  closeMenu()
}

const buildShips = () => {

  if (tableStore.planets.selectedRowIds.length > 0) {
    gameStore.modalType = 'planetBuildShip'
    gameStore.modalData = { planetIds:tableStore.planets.selectedRowIds }
    tableStore.planets.selectedAll = false;

    // Disable area selection on the map
    mapStore.disableAreaSelection();
  }
}

const buildBuildings = () => {

  if (tableStore.planets.selectedRowIds.length > 0) {
    gameStore.modalType = 'planetBuildBuilding'
    gameStore.modalData = { planetIds:tableStore.planets.selectedRowIds }
    tableStore.planets.selectedAll = false;

    // Disable area selection on the map
    mapStore.disableAreaSelection();
  }
}

/**
 * Attach selected ships to a group
 */
const attachToGroup = () => {
  if (tableStore.planets.selectedRowIds.length > 0) {
    gameStore.modalType = 'attachToPlanetGroup'
    gameStore.modalData = tableStore.planets.selectedRowIds;
    tableStore.planets.selectedRowIds = []
    tableStore.planets.selectedAll = false

    // Disable area selection on the map
    mapStore.disableAreaSelection();
  }
}

const detachFromGroup = () => {
  if (tableStore.planets.selectedRowIds.length > 0) {
    const backend = new Backend();
    backend.detachPlanetsFromGroup(tableStore.planets.selectedRowIds)
    tableStore.planets.selectedRowIds = []
    tableStore.planets.selectedAll = false

    // Disable area selection on the map
    mapStore.disableAreaSelection();
  }
}


function closeMenu() {
  gameStore.pullDownMenuVisible = '';
}

function handleClickOutside(event) {
  if (!menuRef.value.contains(event.target)) {
    closeMenu();
  }
}

/**
 * Lifecycle hooks
 */
onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});
</script>


<template>
  <div class="relative" @click.stop="toggleMenu">
    <!-- Icon Button -->
    <slot></slot>
    <!-- Dropdown Menu -->
    <div v-show="gameStore.pullDownMenuVisible === 'planetMultiSelectMenu'" ref="menuRef" class="absolute w-60 border border-slate-500 bg-slate-800 rounded z-50 mt-1">
<!--        <span class="block px-4 py-2 text-sm text-slate-300 hover:bg-slate-700 cursor-pointer" @click="renamePlanets">-->
<!--          Rename Planets-->
<!--        </span>-->
      <div class="bg-slate-600 text-center text-slate-300 p-1">
        {{ tableStore.planets.selectedRowIds.length }} Planets selected
      </div>
      <div v-if="pqaStore.queues.size>0" class="p-2 flex items-center text-slate-300 hover:bg-slate-700 cursor-pointer" @click="setProductionQueue">
        <GenericIcon type="production" class="mr-3"/><div>Set production queue</div>
      </div>
      <div class="p-2 flex items-center text-slate-300 hover:bg-slate-700 cursor-pointer" @click="setPopulationAssignments">
        <GenericIcon type="person" class="mr-3"/><div>Set population assignments</div>
      </div>
      <div class="p-2 flex items-center text-slate-300 hover:bg-slate-700 cursor-pointer" @click="setTax">
        <GenericIcon type="credit" class="mr-3"/><div>Set tax</div>
      </div>
      <div class="p-2 flex items-center text-slate-300 hover:bg-slate-700 cursor-pointer" @click="clearProductionQueues">
        <GenericIcon type="trash" class="mr-3"/><div>Clear production</div>
      </div>
      <div class="p-2 flex items-center text-slate-300 hover:bg-slate-700 cursor-pointer" @click="buildShips">
        <GenericIcon type="ship" class="mr-3"/><div>Build ships</div>
      </div>
      <div class="p-2 flex items-center text-slate-300 hover:bg-slate-700 cursor-pointer" @click="buildBuildings">
        <GenericIcon type="building" class="mr-3"/><div>Build buildings</div>
      </div>
      <div v-if="planetGroupStore.planetGroups.size>0">
        <div class="p-2 flex items-center text-slate-300 hover:bg-slate-700 cursor-pointer" @click="attachToGroup">
          <GenericIcon type="add" class="mr-3"/><div>Add to group</div>
        </div>
        <div class="p-2 flex items-center text-slate-300 hover:bg-slate-700 cursor-pointer" @click="detachFromGroup">
          <GenericIcon type="remove" class="mr-3"/><div>Remove from group</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Add any specific styles for your dropdown here */
</style>
