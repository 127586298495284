<script setup lang="ts">

import { useGameStore } from '@/stores/gameStore.ts'
import { computed } from 'vue'
import GFBSMinister from '@/components/Sections/Government/Finances/BalanceSheetDetails/Ministers/GFBSMinister.vue'

const gameStore = useGameStore()

const ministers = computed (() => {
  return gameStore.playerMinisters
})


</script>

<template>
  <div>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-2">
        <template v-for="[index,minister] in ministers" :key="index">
          <GFBSMinister :minister-code="minister.code"/>
        </template>
      </div>
  </div>
</template>

<style scoped lang="scss">

</style>