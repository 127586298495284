<script setup lang="ts">

import { useGameStore } from '@/stores/gameStore.ts'
import Backend from '@/models/backend.ts'
import { ref, computed } from 'vue'
import LargeModel from '@/components/Interface/Modal/ModelSizes/LargeModel.vue'
import AutoAssignSettleBuildingQueue
  from '@/components/Interface/Modal/Modals/MinistryCandidate/AutoAssignSettleBuildingQueue.vue'
import AutoAssignConquerBuildingQueue
  from '@/components/Interface/Modal/Modals/MinistryCandidate/AutoAssignConquerBuildingQueue.vue'
import { useAssetStore } from '@/stores/assetStore.ts'
import NamedNumbers from '@/components/Elements/Generic/NamedNumbers.vue'
import GenericIconWithColoredNumber from '@/components/Elements/Images/Icons/GenericIconWithColoredNumber.vue'
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import ActionButton from '@/components/Elements/Buttons/ActionButton.vue'
import { CDN } from '@/models/cdn.ts'
import DeleteButton from "@/components/Elements/Buttons/DeleteButton.vue";

const gameStore = useGameStore()
const assetStore = useAssetStore()

const errorMessage = ref('')
const selectedSide = ref<'left' | 'right'>('left')

const purchaseCandidate = () => {
  errorMessage.value = ''
  const backend = new Backend()
  backend.purchaseMinistryCandidate(gameStore.modalData.code,side.value.code).then(() => {
    gameStore.setTinySuccessToastMessage(side.value.name + ' has joined your cabinet!')
  }).catch((error) => {
    errorMessage.value = error.response.data.message
  })
}

const fireCandidate = () => {

  gameStore.modal2Type = 'confirmationDelete';
  gameStore.modal2Data = {
    title: 'Are you sure?',
    message: 'Are you sure you want to fire ' + picked.value!.name + '? This will automatically close the ministry and you will lose the minister.',
    actionText: 'Yes',
    cancelText: 'No',
    action: () => {
      errorMessage.value = ''
      const backend = new Backend()
      backend.fireMinister(picked.value!.code).then(() => {
        gameStore.setTinySuccessToastMessage(picked.value!.name + ' has been fired!')
      }).catch((error) => {
        errorMessage.value = error.response.data.message
      })
    },
  };


}

const setSide = (side: 'left' | 'right') => {
  selectedSide.value = side
}

/**
 * Computed
 */
const data = computed(() => {
  return assetStore.findMinistryByCode(gameStore.modalData.code)
})

const picked = computed(() => {
  if (gameStore.playerMinisters.has(data.value.left.code)) {
    const storeMinister = gameStore.playerMinisters.get(data.value.left.code)!
    return { ...data.value.left, fired: storeMinister.fired }
  } else if (gameStore.playerMinisters.has(data.value.right.code)) {
    const storeMinister = gameStore.playerMinisters.get(data.value.right.code)!
    return { ...data.value.right, fired: storeMinister.fired }
  }
  return undefined
})


const side = computed(() => {
  if(selectedSide.value === 'left') {
    return data.value.left
  } else {
    return data.value.right
  }
})

const handleVideoClick = (side: 'left' | 'right', event: Event) => {
  setSide(side);

  // Play the video
  const videoElement = event.target as HTMLVideoElement;
  if (videoElement.paused) {
    videoElement.play();
  }
  // Pause the other video
  const otherSide = side === 'left' ? 'right' : 'left';
  const otherVideoElement = document.querySelector(`video[poster="${CDN.ministerImage(data.value[otherSide].code)}"]`) as HTMLVideoElement;
  if (otherVideoElement) {
    otherVideoElement.pause();
  }
};


</script>

<template>
  <LargeModel :title="data.name">
    <!-- Selected candidate -->
    <div v-if="picked" class="p-2">
      <div class="flex flex-col sm:flex-row text-center sm:text-left ">
        <!-- Image Section -->
        <div class="relative flex flex-col flex-shrink-0 items-center justify-center">
          <div v-if="picked.fired === 1" class="absolute inset-0 flex items-center justify-center z-10">
            <div class="text-5xl font-bold text-red-500" style="text-shadow: 0 0 8px white;">
              FIRED
            </div>
          </div>

          <video
              v-if="picked.fired === 0"
              autoplay
              muted
              loop
              class="rounded self-center sm:self-start w-auto h-auto md:w-64 md:h-64 z-0"
              :poster="CDN.ministerImage(picked.code)"
          >
            <source :src="CDN.ministerVideo(picked.code)" type="video/webm" />
          </video>

          <img
              v-else
              :src="CDN.ministerImage(picked.code)"
              class="rounded self-center sm:self-start w-auto h-auto md:w-64 md:h-64 grayscale z-0"
              alt="FIRED!"
          />
        </div>


        <!-- Text Section -->
        <div class="flex-grow text-subtext md:pl-2 pl-0">
          <p class="pb-2 pt-2 md:pt-0">{{ picked.description }}</p>
          <p class="p-2 text-slate-300 bg-slate-800 rounded">
            <AutoAssignSettleBuildingQueue v-if="picked.code === 'auto_assign_settle_building_queue'"/>
            <AutoAssignConquerBuildingQueue v-else-if="picked.code === 'auto_assign_capture_building_queue'"/>
            <span v-else>
              {{ picked.summary}}
            </span>
          </p>
          <div v-if="picked.salary>0 && picked.fired===0" class="pt-2 flex justify-center md:justify-start">
            <NamedNumbers text="Salary">
              <GenericIconWithColoredNumber :amount="-picked.salary" type="credit"/>
            </NamedNumbers>
          </div>
          <div v-if="picked.fired===0" class="pt-2">
            <DeleteButton :full-width="true" @click="fireCandidate">Fire {{ picked.name }}</DeleteButton>
          </div>
        </div>
      </div>
    </div>
    <!-- Not selected candidate -->
    <div v-else class="p-2">
      <StandardCard bottom-padding="1" top-padding="0">
        Choose your minister for the {{ data.name }}, click on the image to switch between the candidates.
      </StandardCard>
      <div class="flex justify-between">
        <StandardCard :title="data.left.name" class="pe-1">
          <div class="w-full aspect-square overflow-hidden">
            <video autoplay muted loop
               :class="{
                  'rounded w-full h-full object-cover cursor-pointer': true,
                  'grayscale': selectedSide !== 'left'
                }"
               :poster="CDN.ministerImage(data.left.code)"
               @click="handleVideoClick('left', $event)"
            >
              <source :src="CDN.ministerVideo(data.left.code)" type="video/webm">
            </video>
          </div>
        </StandardCard>
        <StandardCard :title="data.right.name" class=" ps-1">
        <div class="w-full aspect-square overflow-hidden">
          <video muted loop
                 :class="{
                  'rounded w-full h-full object-cover cursor-pointer': true,
                  'grayscale': selectedSide !== 'right'
                }"
                 :poster="CDN.ministerImage(data.right.code)"
                 @click="handleVideoClick('right', $event)"
          >
            <source :src="CDN.ministerVideo(data.right.code)" type="video/webm">
          </video>
        </div>
        </StandardCard>
      </div>
      <StandardCard>
        <div class="text-slate-300"> {{ side.summary }}</div>
      </StandardCard>
      <StandardCard>
        {{ side.description }}
      </StandardCard>

      <StandardCard>
        <div class="flex justify-evenly">
          <NamedNumbers text="Vip Tokens">
            <GenericIconWithColoredNumber :amount="-side.cost" type="vip" />
          </NamedNumbers>
          <NamedNumbers text="Salary">
            <GenericIconWithColoredNumber :amount="-side.salary" type="credit" />
          </NamedNumbers>
        </div>
      </StandardCard>
      <StandardCard>
        <ActionButton :full-width="true" @click="purchaseCandidate">Hire {{ side.name }}</ActionButton>
      </StandardCard>
      <StandardCard v-if="errorMessage">
        <div class="text-red-500">{{errorMessage}}</div>
      </StandardCard>
    </div>
  </LargeModel>

</template>

<style scoped lang="scss">
.main-image {
  width:15rem;
  height:15rem;
}
</style>