<script setup lang="ts">

import { CDN } from '@/models/cdn.ts'
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import { useGameStore } from '@/stores/gameStore.ts'
import GenericIcon from '@/components/Elements/Images/Icons/GenericIcon.vue'
import ModalSpyMission from '@/components/Interface/Modal/Modals/ModalSpy/ModalSpyMission.vue'

/**
 * Props
 */
const props = defineProps({
  spy: {
    type: Object,
    required: true
  },
  icon: {
    type: String,
    required: true
  }
});

/**
 * Stores
 */
const gameStore = useGameStore()

/**
 * Functions
 */
const openModal = () => {
  if(props.spy.killed) return
  gameStore.modalType = 'spy'
  gameStore.modalData = {
    spyId: props.spy.id
  }
}

</script>

<template>
  <StandardCard :title="spy.name" top-padding="0" @click="openModal" :class="{
    'grayscale opacity-40': spy.killed,
    'cursor-pointer': !spy.killed
  }">
    <div class="relative">
      <div class="absolute bottom-0 w-full flex items-end p-2 bg-gradient-to-b from-transparent to-black/100 h-1/2">
        <div class="flex justify-between w-full">
          <div>
          </div>
          <div v-if="props.icon === 'spy'">
            <GenericIcon
              v-for="n in spy.level"
              :key="n"
              type="spy"
              tooltip="Level"
              class="ms-1"/>
          </div>
          <div v-if="props.icon === 'cost'">
            <GenericIcon
              v-for="n in spy.cost"
              :key="n"
              type="credit"
              tooltip="Cost"
              class="ms-1"/>
          </div>
        </div>
      </div>
      <div class="rounded overflow-hidden aspect-square">
        <img :src="CDN.spy(256, spy.imageCode)" alt="" class="object-cover">
      </div>
    </div>
    <ModalSpyMission :spy="spy" type="basic" class="pt-2"/>
  </StandardCard>
</template>

<style scoped lang="scss">

</style>