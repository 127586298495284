<script setup lang="ts">
import {computed, defineComponent, defineProps, ref} from "vue";
import {usePlanetStore} from "@/stores/planetStore";
import PlanetProduction from "@/components/Sections/Planets/PlanetTabs/PlanetProduction.vue";
import PlanetBuildings from "@/components/Sections/Planets/PlanetTabs/PlanetBuildings.vue";
import PlanetDefence from "@/components/Sections/Planets/PlanetTabs/PlanetDefence.vue";
import PlanetTerraformer from '@/components/Sections/Planets/PlanetTabs/PlanetTerraformer.vue'
import PlanetDysonSwarm from '@/components/Sections/Planets/PlanetTabs/PlanetDysonSwarm.vue'
import PlanetStats from '@/components/Sections/Planets/PlanetTabs/PlanetStats.vue'
import PlanetAbandon from '@/components/Sections/Planets/PlanetTabs/PlanetAbandon.vue'
import PlanetPopulation from '@/components/Sections/Planets/PlanetTabs/PlanetPopulation.vue'

/**
 * Props
 */
const props = defineProps({
  planetId: {
    type: Number,
    required: true
  }
});

/**
 * Stores / models
 */
const planetStore = usePlanetStore()

/**
 * Variables
 */
const currentTab = ref('production')

/**
 * Computed
 */
const planetData = computed(() => planetStore.findPlanetById(props.planetId)!)

</script>

<template>
  <div class="text-sm font-medium text-center border-b text-slate-400 border-slate-700">
    <div class="overflow-x-auto whitespace-nowrap no-scrollbar">
      <ul class="flex">
        <li class="me-2">
          <a href="#"
             :class="{
                 'active': currentTab === 'stats',
                 'text-blue-500 border-blue-500': currentTab === 'stats',
                 'text-slate-500 border-transparent': currentTab !== 'stats',
                 'inline-block p-4 border-b-2 rounded-t-lg hover:border-slate-300 hover:text-slate-300': true
             }"
             @click="currentTab = 'stats'">
            Stats
          </a>
        </li>
        <li class="me-2">
          <a href="#"
             :class="{
                 'active': currentTab === 'production',
                 'text-blue-500 border-blue-500': currentTab === 'production',
                 'text-slate-500 border-transparent': currentTab !== 'production',
                 'inline-block p-4 border-b-2 rounded-t-lg hover:border-slate-300 hover:text-slate-300': true
             }"
             @click="currentTab = 'production'">
            Production
          </a>
        </li>
        <li class="me-2">
          <a href="#"
             :class="{
                 'active': currentTab === 'buildings',
                 'text-blue-500 border-blue-500': currentTab === 'buildings',
                 'text-slate-500 border-transparent': currentTab !== 'buildings',
                 'inline-block p-4 border-b-2 rounded-t-lg  hover:border-slate-300 hover:text-slate-300': true
             }"
             @click="currentTab = 'buildings'">
            Buildings
          </a>
        </li>
        <li class="me-2">
          <a href="#"
             :class="{
                 'active': currentTab === 'population',
                 'text-blue-500 border-blue-500': currentTab === 'population',
                 'text-slate-500 border-transparent': currentTab !== 'population',
                 'inline-block p-4 border-b-2 rounded-t-lg  hover:border-slate-300 hover:text-slate-300': true
             }"
             @click="currentTab = 'population'">
            Population
          </a>
        </li>
        <li class="me-2">
          <a href="#"
             :class="{
                 'active': currentTab === 'defence',
                 'text-blue-500 border-blue-500': currentTab === 'defence',
                 'text-slate-500 border-transparent': currentTab !== 'defence',
                 'inline-block p-4 border-b-2 rounded-t-lg hover:border-slate-300 hover:text-slate-300': true
             }"
             @click="currentTab = 'defence'">
            Defense
          </a>
        </li>
        <li class="me-2">
          <a href="#"
             :class="{
                 'active': currentTab === 'terraformer',
                 'text-blue-500 border-blue-500': currentTab === 'terraformer',
                 'text-slate-500 border-transparent': currentTab !== 'terraformer',
                 'inline-block p-4 border-b-2 rounded-t-lg hover:border-slate-300 hover:text-slate-300': true
             }"
             @click="currentTab = 'terraformer'">
            Terraformer
          </a>
        </li>
        <li class="me-2">
          <a href="#"
             :class="{
                 'active': currentTab === 'dysonswarm',
                 'text-blue-500 border-blue-500': currentTab === 'dysonswarm',
                 'text-slate-500 border-transparent': currentTab !== 'dysonswarm',
                 'inline-block p-4 border-b-2 rounded-t-lg hover:border-slate-300 hover:text-slate-300': true
             }"
             @click="currentTab = 'dysonswarm'">
            Dyson Swarm
          </a>
        </li>
        <li class="me-2">
          <a href="#"
             :class="{
                 'active': currentTab === 'abandon',
                 'text-red-500 border-red-500': currentTab === 'abandon',
                 'text-slate-500 border-transparent': currentTab !== 'abandon',
                 'inline-block p-4 border-b-2 rounded-t-lg hover:border-slate-300 hover:text-slate-300': true
             }"
             @click="currentTab = 'abandon'">
            Abandon
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div v-if="currentTab === 'stats'">
    <PlanetStats :planet-id="planetData.id"></PlanetStats>
  </div>
  <div v-if="currentTab === 'production'">
    <PlanetProduction :planet-id="planetData?.id"></PlanetProduction>
  </div>
  <div v-else-if="currentTab === 'buildings'">
    <PlanetBuildings :planet-id="planetData.id"></PlanetBuildings>
  </div>
  <div v-else-if="currentTab === 'population'">
    <PlanetPopulation :planet-id="planetData.id"></PlanetPopulation>
  </div>
  <div v-else-if="currentTab === 'defence'">
    <PlanetDefence :planet-id="planetData.id"></PlanetDefence>
  </div>
  <div v-else-if="currentTab === 'terraformer'">
    <PlanetTerraformer :planet-id="planetData.id"></PlanetTerraformer>
  </div>
  <div v-else-if="currentTab === 'dysonswarm'">
    <PlanetDysonSwarm :planet-id="planetData.id"></PlanetDysonSwarm>
  </div>
  <div v-else-if="currentTab === 'abandon'">
    <PlanetAbandon :planet-id="planetData.id"></PlanetAbandon>
  </div>
</template>

<style scoped lang="scss">
.nav-item {
  cursor: pointer;
}
</style>
