<script setup lang="ts">

import { usePlayerCreditStore } from '@/stores/playerCreditStore.ts'
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import ColoredNumber from '@/components/Elements/Generic/ColoredNumber.vue'
import GenericIcon from '@/components/Elements/Images/Icons/GenericIcon.vue'

const props = defineProps({
  header: {
    type: String,
    required: true
  },
  subheader: {
    type: String,
    required: false,
    default: ''
  },
  amount: {
    type: Number,
    required: true
  },
  total: {
    type: Boolean,
    required: false,
    default: false
  },
  type: {
    type: String,
    required: false,
    default: ''
  }
})

const playerCreditStore = usePlayerCreditStore()

const openDetails = () => {
  if (props.type != '') {
    playerCreditStore.balanceSheetDetails = props.type
  }
}

</script>

<template>
  <div :class="{
    'flex justify-between py-1 ': true,
    'cursor-pointer py-1 hover:bg-slate-800 hover:rounded': !total
  }"
       @click="openDetails"
  >
    <div class="ps-1 flex">
      <div>{{ header }}</div>
    </div>
    <div class="pe-2">
      <ColoredNumber :amount="amount" />
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>