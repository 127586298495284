<script setup lang="ts">
import { useMapStore } from "@/stores/mapStore.ts";
import { zoomFactor } from '@/enums/zoomFactor.ts'
import ActionButton from '@/components/Elements/Buttons/ActionButton.vue'
import FilterButton from '@/components/Elements/Buttons/FilterButton.vue'

/**
 * Stores / models
 */
const mapStore = useMapStore();

/**
 * variables
 */
const presets = [
  { name: 'Planet', value: zoomFactor.Planet },
  { name: 'Solar', value: zoomFactor.Solar },
  { name: 'Local', value: zoomFactor.Local },
  { name: 'Sector', value: zoomFactor.Sector },
  { name: 'Quadrant', value: zoomFactor.Quadrant },
  { name: 'Max', value: zoomFactor.Max },
];

/**
 * Functions
 */
const zoom = (zoom: number) => {
  mapStore.zoomFactor = zoom
};

</script>

<template>
  <div class="pt-2 pointer-events-auto flex justify-center md:justify-end">
    <div class="inline-flex">
      <div v-for="preset in presets" :key="preset.value">
        <FilterButton
          class="mr-2"
          :active="mapStore.zoomFactor === preset.value"
          @click="zoom(preset.value)">
          {{ preset.name }}
        </FilterButton>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
/* Add any additional styles if needed */
</style>
