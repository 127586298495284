<script setup lang="ts">

import type NewsItem from "@/types/NewsItem.ts";
import ModalTrigger from "@/components/Interface/Modal/ModalTrigger.vue";
import NewsItemBaseStructure from '@/components/Sections/News/Types/Base/NewsItemBaseStructure.vue'
import NewsItemBase from '@/components/Sections/News/Types/Base/NewsItemBase.vue'
import { CDN } from '@/models/cdn.ts'
import PlayerName from '@/components/Objects/PlayerName.vue'
import NamedNumbers from '@/components/Elements/Generic/NamedNumbers.vue'
import { useAssetStore } from '@/stores/assetStore.ts'
import { computed } from 'vue'

/**
 * Props
 */
const props = defineProps<{
  newsItem: NewsItem,
  toast: boolean
}>();

/**
 * Stores / models
 */
const assetStore = useAssetStore()

/**
 * Computed
 */
const techName = computed(() => {
  if(props.newsItem.contentData.data.currentTech === 0) {
    return 'None'
  }
  const tech = assetStore.findTechById(props.newsItem.contentData.data.currentTech)
  if(!tech) return 'None'
  return tech.name
})

</script>

<template>
  <NewsItemBase
    :toast="toast"
    :toast-image="CDN.spy(64,props.newsItem.contentData.spy.imageCode)"
    :toast-text="'Intelligence retrieved'"
  >
    <ModalTrigger type="spy" :data="{spyId: props.newsItem.contentData.spy.id}" >
      <NewsItemBaseStructure
        :newsItem="props.newsItem"
        :item-image="CDN.spy(256,props.newsItem.contentData.spy.imageCode)"
        :header="'Intelligence retrieved'"
      >
        <div>
          <span class="text-slate-300">{{ props.newsItem.contentData.spy.name }}</span> obtained valuable intelligence on <PlayerName :player-id="props.newsItem.contentData.spy.targetPlayerId" />.
        </div>
        <div class="flex flex-col py-2">
          <NamedNumbers text="Planets" class="pb-1">{{ props.newsItem.contentData.data.planets }}</NamedNumbers>
          <NamedNumbers text="Ships" class="pb-1">{{ props.newsItem.contentData.data.ships }}</NamedNumbers>
          <NamedNumbers text="Currently researching">{{ techName }}</NamedNumbers>
        </div>
      </NewsItemBaseStructure>
    </ModalTrigger>
  </NewsItemBase>
</template>

<style scoped lang="scss">

</style>