<script setup lang="ts">

import { useGameStore } from '@/stores/gameStore'
import ModalBuilding from '@/components/Interface/Modal/Modals/ModalBuilding.vue'
import ModalBuildShip from "@/components/Interface/Modal/Modals/ModalBuildShip.vue";
import ModelMapSelectPlayerShips from "@/components/Interface/Modal/Models/ModelMapSelectPlayerShips.vue";
import ModalEditShipName from '@/components/Interface/Modal/Modals/ModalEditShipName.vue'
import ModalMapAttack from "@/components/Interface/Modal/Modals/ModalMapAttack.vue";
import ModalRequestAudience from '@/components/Interface/Modal/Modals/ModalRequestAudience.vue'
import ModalTech from '@/components/Interface/Modal/Modals/ModalTech.vue'
import ModalAssignToFleet from '@/components/Interface/Modal/Modals/ModalAssignToFleet.vue'
import ModalCreateFleet from '@/components/Interface/Modal/Modals/ModalCreateFleet.vue'
import ModalEditFleetName from '@/components/Interface/Modal/Modals/ModalEditFleetName.vue'
import ModalPlanetsSetProductionQueue from '@/components/Interface/Modal/Modals/ModalPlanetsSetProductionQueue.vue'
import ModalPlanetsSetPopulationAssignments
  from '@/components/Interface/Modal/Modals/ModalPlanetsSetPopulationAssignments.vue'
import ModalMapFollow from '@/components/Interface/Modal/Modals/ModalMapFollow.vue'
import ModelMapFollowAttack from '@/components/Interface/Modal/Modals/ModelMapFollowAttack.vue'
import ModalCreateShipGroup from '@/components/Interface/Modal/Modals/ModalCreateShipGroup.vue'
import ModalMinistryCandidate from '@/components/Interface/Modal/Modals/ModalMinistryCandidate.vue'
import ModelMapSelectPlayerFleetShips from '@/components/Interface/Modal/Models/ModelMapSelectPlayerFleetShips.vue'
import ModelDelete from '@/components/Interface/Modal/Models/Confirmation/ModelDelete.vue'
import ModalBuildBuilding from '@/components/Interface/Modal/Modals/ModalBuildBuilding.vue'
import ModalCreatePlanetGroup from '@/components/Interface/Modal/Modals/ModalCreatePlanetGroup.vue'
import ModalAttachToShipGroup from '@/components/Interface/Modal/Modals/ModalAttachToShipGroup.vue'
import ModalAttachToPlanetGroup from '@/components/Interface/Modal/Modals/ModalAttachToPlanetGroup.vue'
import ModalOreRefinery from '@/components/Interface/Modal/Modals/ModalOreRefinery.vue'
import ModalOreDetails from '@/components/Interface/Modal/Modals/ModalOreDetails.vue'
import ModalCreatePQA from '@/components/Interface/Modal/Modals/ModalCreatePQA.vue'
import ModalConfigPQA from '@/components/Interface/Modal/Modals/ModalConfigPQA.vue'
import ModalEditPlanetName from '@/components/Interface/Modal/Modals/ModalEditPlanetName.vue'
import ModalCreateShipDesign from '@/components/Interface/Modal/Modals/ModalCreateShipDesign.vue'
import ModalCreateShipDesignSave from '@/components/Interface/Modal/Modals/ModalCreateShipDesignSave.vue'
import ModalNotResearching from '@/components/Interface/Modal/Modals/ModalNotResearching.vue'
import ModalOreTrade from '@/components/Interface/Modal/Modals/ModalOreTrade.vue'
import ModalOreTradeConfig from '@/components/Interface/Modal/Modals/ModalOreTradeConfig.vue'
import ModalBattleReport from '@/components/Interface/Modal/Modals/ModalBattleReport.vue'
import ModalPlanetsSetTax from '@/components/Interface/Modal/Modals/ModalPlanetsSetTax.vue'
import ModalDayWeek from '@/components/Interface/Modal/Modals/ModalDayWeek.vue'
import ModalSpy from '@/components/Interface/Modal/Modals/ModalSpy.vue'
import ModalSpyMissionSelectPlayer
  from '@/components/Interface/Modal/Modals/ModalSpy/Modals/ModalSpyMissionSelectPlayer.vue'

const gameStore = useGameStore()

</script>

<template>
  <!-- Level 1 Modals -->
  <div v-if="gameStore.modalType === 'building'">
    <ModalBuilding/>
  </div>
  <div v-if="gameStore.modalType === 'dayWeek'">
    <ModalDayWeek/>
  </div>

  <div v-else-if="gameStore.modalType === 'editPlanetName'">
    <ModalEditPlanetName/>
  </div>
  <div v-else-if="gameStore.modalType === 'createShipDesign'">
    <ModalCreateShipDesign/>
  </div>
  <div v-else-if="gameStore.modalType === 'createShipDesignSave'">
    <ModalCreateShipDesignSave/>
  </div>
  <div v-else-if="gameStore.modalType === 'planetBuildShip'">
    <ModalBuildShip/>
  </div>
  <div v-else-if="gameStore.modalType === 'planetBuildBuilding'">
    <ModalBuildBuilding/>
  </div>
  <div v-else-if="gameStore.modalType === 'mapSelectPlayerShip'">
    <ModelMapSelectPlayerShips/>
  </div>
  <div v-else-if="gameStore.modalType === 'mapSelectPlayerFleetShips'">
    <ModelMapSelectPlayerFleetShips/>
  </div>
  <div v-else-if="gameStore.modalType === 'editShipName'">
    <ModalEditShipName/>
  </div>
  <div v-else-if="gameStore.modalType === 'mapAttack'">
    <ModalMapAttack/>
  </div>
  <div v-else-if="gameStore.modalType === 'mapFollow'">
    <ModalMapFollow/>
  </div>
  <div v-else-if="gameStore.modalType === 'mapFollowAttack'">
    <ModelMapFollowAttack/>
  </div>
  <div v-else-if="gameStore.modalType === 'battleReport'">
    <ModalBattleReport/>
  </div>
  <div v-else-if="gameStore.modalType === 'requestAudience'">
    <ModalRequestAudience/>
  </div>
  <div v-else-if="gameStore.modalType === 'tech'">
    <ModalTech/>
  </div>
  <div v-else-if="gameStore.modalType === 'notResearching'">
    <ModalNotResearching/>
  </div>
  <div v-else-if="gameStore.modalType === 'createPQA'">
    <ModalCreatePQA/>
  </div>
  <div v-else-if="gameStore.modalType === 'configPQA'">
    <ModalConfigPQA/>
  </div>
  <div v-else-if="gameStore.modalType === 'assignToFleet'">
    <ModalAssignToFleet/>
  </div>
  <div v-else-if="gameStore.modalType === 'createFleet'">
    <ModalCreateFleet/>
  </div>
  <div v-else-if="gameStore.modalType === 'editFleetName'">
    <ModalEditFleetName/>
  </div>
  <div v-else-if="gameStore.modalType === 'planetsSetProductionQueue'">
    <ModalPlanetsSetProductionQueue/>
  </div>
  <div v-else-if="gameStore.modalType === 'planetsSetPopulationAssignments'">
    <ModalPlanetsSetPopulationAssignments/>
  </div>
  <div v-else-if="gameStore.modalType === 'planetsSetTax'">
    <ModalPlanetsSetTax/>
  </div>
  <div v-else-if="gameStore.modalType === 'createShipGroup'">
    <ModalCreateShipGroup/>
  </div>
  <div v-else-if="gameStore.modalType === 'createPlanetGroup'">
    <ModalCreatePlanetGroup/>
  </div>
  <div v-else-if="gameStore.modalType === 'attachToShipGroup'">
    <ModalAttachToShipGroup/>
  </div>
  <div v-else-if="gameStore.modalType === 'attachToPlanetGroup'">
    <ModalAttachToPlanetGroup/>
  </div>
  <div v-else-if="gameStore.modalType === 'ministryCandidate'">
    <ModalMinistryCandidate/>
  </div>
  <div v-else-if="gameStore.modalType === 'spy'">
    <ModalSpy/>
  </div>
  <!-- Ore Modals -->
  <div v-if="gameStore.modalType === 'oreTrade'">
    <ModalOreTrade/>
  </div>
  <div v-if="gameStore.modalType === 'oreRefinery'">
    <ModalOreRefinery/>
  </div>
  <div v-if="gameStore.modalType === 'oreDetails'">
    <ModalOreDetails/>
  </div>
  <!-- Level 2 Modals -->
  <div v-if="gameStore.modal2Type === 'confirmationDelete'">
    <ModelDelete/>
  </div>
  <div v-if="gameStore.modal2Type === 'oreTradeConfig'">
    <ModalOreTradeConfig/>
  </div>
  <div v-if="gameStore.modal2Type === 'spyMissionSelectPlayer'">
    <ModalSpyMissionSelectPlayer/>
  </div>

</template>

<style scoped lang="scss">

</style>