<script setup lang="ts">

import router from '@/router'
import { useShipStore } from '@/stores/shipStore.ts'
import IconCount from '@/components/Objects/IconCount.vue'
import Position from '@/components/Interface/Position.vue'
import ShipMultiSelectMenu from '@/components/Interface/Menu/ShipMultiSelectMenu.vue'
import FerionDataTable from '@/components/Interface/DataTable/FerionDataTable.vue'
import { useTableStore } from '@/stores/tableStore.ts'
import HeaderTextFlex from '@/components/Interface/DataTable/Headers/HeaderTextFlex.vue'
import HeaderFixedWidthText from '@/components/Interface/DataTable/Headers/HeaderFixedWidthText.vue'
import HeaderFixedWidthIcon from '@/components/Interface/DataTable/Headers/HeaderFixedWidthIcon.vue'
import CellFlexWidth from '@/components/Interface/DataTable/Cell/CellFlexWidth.vue'
import CellFixedWidth from '@/components/Interface/DataTable/Cell/CellFixedWidth.vue'
import ActionBarBlock from '@/components/Interface/DataTable/ActionBar/ActionBarBlock.vue'
import ActionBarFiller from '@/components/Interface/DataTable/ActionBar/ActionBarFiller.vue'
import ActionBarSearch from '@/components/Interface/DataTable/ActionBar/ActionBarSearch.vue'
import ActionBarSelect from '@/components/Interface/DataTable/ActionBar/ActionBarSelect.vue'
import TagButton from '@/components/Interface/Buttons/TagButton.vue'
import { useGameStore } from '@/stores/gameStore.ts'
import { useShipGroupStore } from '@/stores/shipGroupStore.ts'
import type PlayerShip from '@/types/PlayerShip.ts'
import { useShipActionStore } from '@/stores/shipActionStore.ts'
import Percentage from '@/components/Interface/Text/Percentage.vue'
import Backend from '@/models/backend.ts'
import ActionButton from '@/components/Elements/Buttons/ActionButton.vue'
import GenericIcon from '@/components/Elements/Images/Icons/GenericIcon.vue'

/**
 * Props
 */
const props = defineProps({
  withDetails: {
    type: Boolean,
    default: true
  },
  lockedToFleetId: {
    type: Number,
    default: 0
  }
});


/**
 * Stores
 */
const gameStore = useGameStore();
const shipsStore = useShipStore();
const shipGroupStore = useShipGroupStore();
const tableStore = useTableStore()
const shipActionStore = useShipActionStore()


/**
 * Functions
 */
// Load filtered ships list
const filters = (ships:Map<number,PlayerShip>) => {
  return Array.from(ships.values())
    .filter((ship) => {
      // If no category is selected, return all ships
      if (!tableStore.ships.filters.selectedAction || tableStore.ships.filters.selectedAction === '') {
        return true;
      }
      // Otherwise, filter based on the selected category
      return ship.currentActionName === tableStore.ships.filters.selectedAction;
    })
    .filter((ship) => {
      // If nothing is selected, return all ships
      if (!tableStore.ships.filters.selectedType || tableStore.ships.filters.selectedType === '') {
        return true;
      }
      // Otherwise, filter based on the selected type
      return ship.type === tableStore.ships.filters.selectedType;
    })
    .filter((ship) => {
      //On fleet screen we dont use groups
      if(props.lockedToFleetId > 0) {
        return true;
      }
      // All
      if (tableStore.ships.filters.selectedGroup === 0) {
        return true;
      }
      // Not in group
      if (tableStore.ships.filters.selectedGroup === -1) {
        return ship.shipGroupId === 0;
      }

      // Otherwise, filter based on the selected group
      return ship.shipGroupId === tableStore.ships.filters.selectedGroup;
    })
    .filter((ship) => {
      // If no search query, return all ships
      if (!tableStore.ships.filters.nameSearch || tableStore.ships.filters.nameSearch === '') {
        return true;
      }
      // Otherwise, filter based on the ship name (case-insensitive search)
      return ship.shipName.toLowerCase().includes(tableStore.ships.filters.nameSearch.toLowerCase());
    })
    .filter((ship) => {
      //Locked to fleet
      if(props.lockedToFleetId > 0) {
        return ship.fleetId == props.lockedToFleetId;
      } else {
        //No fleet
        return ship.fleetId == 0;
      }
    });
};


const openGroupModal = () => {
  gameStore.modalType = 'createShipGroup';
};

const filterGroup = (groupId: number) => {
  tableStore.ships.filters.selectedGroup = groupId;
};

const deleteGroup = (groupId: number) => {
  gameStore.modal2Type = 'confirmationDelete';
  gameStore.modal2Data = {
    title: 'Are you sure?',
    message: 'Are you sure you want to delete this group?',
    actionText: 'Yes',
    cancelText: 'No',
    action: () => {
      const backend = new Backend();
      backend.deleteShipGroup(groupId).then(() => {
        tableStore.ships.filters.selectedGroup = 0;
      });
    },
  };

};

const loadItem = (id:number) => {
  if(props.lockedToFleetId > 0) {
    return;
  }
  router.push('/command/ships/' + id);
}

const setActionMap = (id:number) => {
  const shipData = shipsStore.findShipById(id);
  if(shipData) {
    router.push('/map/galaxy/')
    shipActionStore.initShip(shipData)
  }
}


</script>

<template>
  <div v-if="props.lockedToFleetId === 0" class="mb-1 text-slate-300 overflow-x-auto whitespace-nowrap flex">
      <TagButton :selected="false" @click="openGroupModal">+</TagButton>
      <TagButton :selected="tableStore.ships.filters.selectedGroup===0" @click="filterGroup(0)">All</TagButton>
      <TagButton :selected="tableStore.ships.filters.selectedGroup===-1" @click="filterGroup(-1)">Not in group</TagButton>
    <template v-for="item in [...shipGroupStore.shipGroups.values()].sort((a, b) => a.name.localeCompare(b.name))" :key="item.id">
    <TagButton :selected="tableStore.ships.filters.selectedGroup===item.id" @click="filterGroup(item.id)">
        <div class="flex w-full">
          <div>{{ item.name }}</div>
          <div
            v-if="tableStore.ships.filters.selectedGroup===item.id"
            class="text-red-600 bg-slate-700 hover:text-slate-300 hover:bg-red-500 ml-1 px-1 rounded"
            @click.stop="deleteGroup(item.id)"
          >x</div>
        </div>
      </TagButton>
    </template>
  </div>
  <FerionDataTable
    :data="shipsStore.ships"
    tableStoreName="ships"
    :selector="true"
    :filters="filters"
    :heightLimit="props.lockedToFleetId>0?'h-[calc(100vh-28rem)] md:h-[calc(100vh-26rem)]':'h-[calc(100vh-11.5rem)]'"
    :click="loadItem"
    :double-click="setActionMap"
  >
    <!--- ActionBar --->
    <template #actionBar>
      <ActionBarBlock class="mr-1 flex items-center px-1 rounded">
        <ShipMultiSelectMenu :lockedToFleetId="props.lockedToFleetId">
          <ActionButton class="my-1">
            Action
          </ActionButton>
        </ShipMultiSelectMenu>
      </ActionBarBlock>
      <ActionBarFiller class="mr-1 rounded"></ActionBarFiller>
      <ActionBarSearch v-model="tableStore.ships.filters.nameSearch" name="Name" placeHolder="Search..." class="mr-1"/>
      <ActionBarSelect name="Type" width="6" v-model="tableStore.ships.filters.selectedType" class="mr-1">
        <option value="">All</option>
        <option value="scout">Scout</option>
        <option value="settler">Settler</option>
        <option value="bomber">Bomber</option>
        <option value="fighter">Fighter</option>
      </ActionBarSelect>
      <ActionBarSelect name="Action" width="6" v-model="tableStore.ships.filters.selectedAction">
        <option value="">All</option>
        <option value="Idle">Idle</option>
        <option value="Move">Move</option>
        <option value="Attack">Attack</option>
        <option value="Settle">Settle</option>
        <option value="Follow">Follow</option>
        <option value="FollowAttack">Follow & Attack</option>
      </ActionBarSelect>
    </template>
    <!--- Headers --->
    <template #columns="{ sort, sortColumn, sortDirection }">
      <HeaderTextFlex min-width="12" column="shipName" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        Name
      </HeaderTextFlex>
      <HeaderFixedWidthIcon width="5" column="healthPercentage" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <GenericIcon tooltip="Hit points" type="hit_points"/>
      </HeaderFixedWidthIcon>
      <HeaderFixedWidthIcon width="3" column="maxSpeed" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <GenericIcon tooltip="Max speed" type="max_speed"/>
      </HeaderFixedWidthIcon>
      <HeaderFixedWidthIcon width="3" column="shields" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <GenericIcon tooltip="Shields" type="shields"/>
      </HeaderFixedWidthIcon>
      <HeaderFixedWidthIcon width="3" column="attackShips" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <GenericIcon tooltip="Attack strength against ships" type="attack_against_ships"/>
      </HeaderFixedWidthIcon>
      <HeaderFixedWidthIcon width="3" column="attackPlanets" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <GenericIcon tooltip="Attack strength against planets" type="attack_against_planets"/>
      </HeaderFixedWidthIcon>
      <HeaderFixedWidthIcon width="3" column="scanningRange" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <GenericIcon tooltip="Scanning range" type="scanning_range"/>
      </HeaderFixedWidthIcon>
      <HeaderFixedWidthIcon width="3" column="repair" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <GenericIcon tooltip="Repair capacity" type="repair"/>
      </HeaderFixedWidthIcon>
      <HeaderFixedWidthIcon width="3" column="maintenanceCost" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <GenericIcon tooltip="Maintenance cost" type="credit"/>
      </HeaderFixedWidthIcon>
      <HeaderFixedWidthText width="5" column="xPos" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort" align="center">
        Location
      </HeaderFixedWidthText>
      <HeaderFixedWidthText v-if="lockedToFleetId === 0" width="10" column="currentActionName" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort" align="center">
        Action
      </HeaderFixedWidthText>
      <HeaderFixedWidthText v-else width="10" column="catchingUpToFleet" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort" align="center">
        Status
      </HeaderFixedWidthText>
      <HeaderFixedWidthText width="3" column="etaNextHop" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort" align="center" class="rounded-tr">
        ETA
      </HeaderFixedWidthText>
    </template>
    <!--- Rows --->
    <template #rows="{ item }" >
      <CellFlexWidth min-width="12">
        <div class="flex-col">
          <div>{{ item.shipName }}</div>
          <div v-if="item.shipGroupId>0" class="text-subtext text-xs">{{ shipGroupStore.getNameFromId(item.shipGroupId) }}</div>
        </div>
      </CellFlexWidth>
      <CellFixedWidth width="5">
        <Percentage :value="item.healthPercentage"/>
      </CellFixedWidth>
      <CellFixedWidth width="3">
        <IconCount :amount="item.maxSpeed" :no-plus="true"/>
      </CellFixedWidth>
      <CellFixedWidth width="3">
        <IconCount :amount="item.shields" :no-plus="true"/>
      </CellFixedWidth>
      <CellFixedWidth width="3">
        <IconCount :amount="item.attackShips" :no-plus="true"/>
      </CellFixedWidth>
      <CellFixedWidth width="3">
        <IconCount :amount="item.attackPlanets" :no-plus="true"/>
      </CellFixedWidth>
      <CellFixedWidth width="3">
        <IconCount :amount="item.scanningRange" :no-plus="true"/>
      </CellFixedWidth>
      <CellFixedWidth width="3">
        <IconCount :amount="item.repair" :no-plus="true"/>
      </CellFixedWidth>
      <CellFixedWidth width="3">
        <IconCount :amount="-item.maintenanceCost" :no-plus="true"/>
      </CellFixedWidth>
      <CellFixedWidth width="5">
        <Position :x="item.xPos" :y="item.yPos" @click.stop></Position>
      </CellFixedWidth>
      <CellFixedWidth width="10" align="center">
        <span v-if="lockedToFleetId === 0">
          {{ item.currentActionName }}
          {{ item.actions.length > 1 ? '+' + (item.actions.length-1) : ''}}
        </span>
        <span v-else>
          <span v-if="item.catchingUpToFleet == 1" class="text-amber-400">
            Reinforcement
          </span>
          <span v-else class="text-green-500">
            Core Fleet
          </span>
        </span>
      </CellFixedWidth>
      <CellFixedWidth width="3">
        {{ item.etaNextHop }}
      </CellFixedWidth>
    </template>

  </FerionDataTable>
</template>

<style scoped lang="scss">
</style>