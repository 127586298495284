<script setup lang="ts">

import type NewsItem from "@/types/NewsItem.ts";
import ModalTrigger from "@/components/Interface/Modal/ModalTrigger.vue";
import NewsItemBaseStructure from '@/components/Sections/News/Types/Base/NewsItemBaseStructure.vue'
import NewsItemBase from '@/components/Sections/News/Types/Base/NewsItemBase.vue'
import { CDN } from '@/models/cdn.ts'
import PlayerName from '@/components/Objects/PlayerName.vue'

/**
 * Props
 */
const props = defineProps<{
  newsItem: NewsItem,
  toast: boolean
}>();

</script>

<template>
  <NewsItemBase
    :toast="toast"
    :toast-image="CDN.spy(64,props.newsItem.contentData.spy.imageCode)"
    :toast-text="'Research successfully stolen!'"
  >
    <ModalTrigger type="spy" :data="{spyId: props.newsItem.contentData.spy.id}" >
      <NewsItemBaseStructure
        :newsItem="props.newsItem"
        :item-image="CDN.spy(256,props.newsItem.contentData.spy.imageCode)"
        :header="'Research successfully stolen!'"
      >
        <div>
          <span class="text-slate-300">{{ props.newsItem.contentData.spy.name }}</span> successfully stole research documents from <PlayerName :player-id="props.newsItem.contentData.spy.targetPlayerId" />.
          <div class="pt-2">
            Your research advanced by <span class="text-green-500">{{ props.newsItem.contentData.data.science_stolen }}</span> points.
          </div>
        </div>
      </NewsItemBaseStructure>
    </ModalTrigger>
  </NewsItemBase>
</template>

<style scoped lang="scss">

</style>