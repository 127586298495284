import { defineStore } from 'pinia'
import { markRaw } from 'vue'
import type MapCoordinate from '@/types/MapCoordinate.ts'
import { useMapShipStore } from '@/stores/mapShipStore.ts'
import { usePlayerRelationStore } from '@/stores/playerRelations.ts'
import { useGameStore } from '@/stores/gameStore.ts'
import { useMapPlanetOwnerStore } from '@/stores/mapPlanetOwnerStore.ts'
import { useMapStore } from '@/stores/mapStore.ts'
import { useMapPlanetStore } from '@/stores/mapPlanetStore.ts'
import { useMapStarStore } from '@/stores/mapStarStore.ts'
import { useMapPendingSettleStore } from '@/stores/mapPendingSettleStore.ts'
import Eta from '@/models/eta.ts'
import * as THREE from 'three'
import { MapCrossHairUpdate } from '@/models/Map/MapCrosshair.ts'
import type MapPlanet from '@/types/MapPlanet.ts'

export const useMapCoordinateStore = defineStore('mapCoordinateStore', {
    state: () => ({
        coordinates: markRaw(new Map()),
        planets: new Map(),
        fleets: new Map(),
        scene: markRaw(new THREE.Scene()),
        etaXPos: 0, //X Position for ETA calculations on sidebar
        etaYPos: 0, //Y Position for ETA calculations on sidebar
        etaSpeed: 0, //Speed for ETA calculations on sidebar
    }),
    actions: {
        hasByCoordinates(x: number, y: number): boolean {
            const key = x + '-' + y
            return this.coordinates.has(key);
        },
        has(mapCoordinate: MapCoordinate): boolean {
            const key = mapCoordinate.xPos + '-' + mapCoordinate.yPos
            return this.coordinates.has(key);
        },
        get(mapCoordinate: MapCoordinate): any {
            const key = mapCoordinate.xPos + '-' + mapCoordinate.yPos
            return this.coordinates.get(key);
        },
        getByCoordinates(x: number, y: number): any {
            const key = x + '-' + y
            return this.coordinates.get(key);
        },
        getSettleablePlanets: function(): Array<any> {
            const planets : MapPlanet[] = []
            this.planets.forEach((value, key) => {
                if (value.playerId === 0) {
                    planets.push(value)
                }
            })
            return planets
        },
        set(
          mapCoordinate: MapCoordinate,
          shipInstances: {
              playerShip: number|null,
              enemyShip: number|null,
              friendlyShip: number|null,
              allyShip: number|null,
              neutralShip: number|null
          },
          planetOwnerInstance:Number|null,
          planetInstance:Number|null,
          starInstance:Number|null
        ) {

            const gameStore = useGameStore();

            const key = mapCoordinate.xPos + '-' + mapCoordinate.yPos

            this.coordinates.set(key, {
                id: key,
                mapCoordinate: mapCoordinate,
                shipInstances:shipInstances,
                planetOwnerInstance:planetOwnerInstance,
                planetInstance:planetInstance,
                starInstance:starInstance,
                eta: 0
            });

            //also store the planet data
            if(Number(mapCoordinate.type) === 2) {

                const sizeCompatible = mapCoordinate.typeSize === gameStore.player?.homeWorldPlanetSize;
                const sizeInCompatible = mapCoordinate.typeSize === gameStore.player?.homeWorldOppositePlanetSize;
                const typeCompatible = mapCoordinate.typeVariant === gameStore.player?.homeWorldPlanetType;
                const typeInCompatible = mapCoordinate.typeVariant === gameStore.player?.homeWorldOppositePlanetType;

                let compatibility = 'C'
                if(mapCoordinate.typeVariant === 7) {
                    compatibility = 'F'
                } else if(sizeCompatible && typeCompatible) {
                    compatibility = 'A'
                } else if(sizeInCompatible && typeInCompatible) {
                    compatibility = 'E'
                } else if((sizeCompatible || typeCompatible) && !sizeInCompatible && !typeInCompatible) {
                    compatibility = 'B'
                } else if((sizeInCompatible || typeInCompatible) && !sizeCompatible && !typeCompatible) {
                    compatibility = 'D'
                }

                const eta = new Eta();
                eta.start(mapCoordinate.xPos, mapCoordinate.yPos);
                const newETA = eta.etaNext(this.etaXPos, this.etaYPos,this.etaSpeed);

                this.planets.set(key, {
                    id: key,
                    planetId: mapCoordinate.typeId,
                    playerId: mapCoordinate.typePlayerId,
                    size: mapCoordinate.typeSize,
                    type: mapCoordinate.typeVariant,
                    xPos: mapCoordinate.xPos,
                    yPos: mapCoordinate.yPos,
                    visible: mapCoordinate.currentlyVisible,
                    name: mapCoordinate.typeName,
                    compatibility: compatibility,
                    eta: newETA
                  }
                )
            } else {
                this.planets.delete(key)
            }
        },
        recalculateMapEtas(xPos : number, yPos : number, maxSpeed: number) {

            this.etaXPos = xPos
            this.etaYPos = yPos
            this.etaSpeed = maxSpeed

            //Go through all planets and recalculate the ETA
            this.planets.forEach((value, key) => {
                const eta = new Eta();
                eta.start(xPos, yPos);
                value.eta = eta.etaNext(value.xPos, value.yPos, maxSpeed);
            });
            //Go through all the ships and recalculate the ETA
            this.fleets.forEach((value, key) => {
                const eta = new Eta();
                eta.start(xPos, yPos);
                value.eta = eta.etaNext(value.xPos, value.yPos, maxSpeed);
            });
        },
        resetMapEtas() {
            this.etaXPos = 0
            this.etaYPos = 0
            this.etaSpeed = 0
            this.planets.forEach((value, key) => {
                value.eta = 0
            });
            this.fleets.forEach((value, key) => {
                value.eta = 0
            });
        },
        getDataForRayCasting(x: number, y: number) {
            const key = x + '-' + y
              if(this.coordinates.has(key)) {
                  const data = this.coordinates.get(key);
                  return {
                      'id': data.mapCoordinate.typeId ?? 0,
                      'isPlanet': data.mapCoordinate.type === 2,
                      'planetOwnerId': data.mapCoordinate.typePlayerId,
                      'canBeSettled': data.mapCoordinate.typePlayerId === 0 && data.mapCoordinate.type === 2,
                      'catBeFollowed': Object.values(data.mapCoordinate.ships).length > 0,
                      'hasTooltip': data.mapCoordinate.type === 2 || data.mapCoordinate.type === 1 || Object.values(data.mapCoordinate.ships).length > 0,
                      'hasPlayerShips': data.mapCoordinate.ships[useGameStore().player?.id ?? 0] !== undefined,
                      'ships': data.mapCoordinate.ships,
                      'xPos': data.mapCoordinate.xPos,
                      'yPos': data.mapCoordinate.yPos,
                  }
              }
              return null
        },
        clearShips(data: any) {
            const mapShipStore = useMapShipStore();
            if(data.shipInstances.playerShip) {
                mapShipStore.removeShip(data.shipInstances.playerShip)
            }
            if(data.shipInstances.enemyShip) {
                mapShipStore.removeShip(data.shipInstances.enemyShip)
            }
            if(data.shipInstances.friendlyShip) {
                mapShipStore.removeShip(data.shipInstances.friendlyShip)
            }
            if(data.shipInstances.allyShip) {
                mapShipStore.removeShip(data.shipInstances.allyShip)
            }
            if(data.shipInstances.neutralShip) {
                mapShipStore.removeShip(data.shipInstances.neutralShip)
            }
        },
        setShips(data: MapCoordinate): {
            playerShip: number|null,
            enemyShip: number|null,
            friendlyShip: number|null,
            allyShip: number|null,
            neutralShip: number|null
        } {
            const gameStore = useGameStore();
            const playerRelationStore = usePlayerRelationStore();
            const mapShipStore = useMapShipStore();

            let playerShips = 0
            let enemyShips = 0
            let friendlyShips = 0
            let allyShips = 0
            let neutralShips = 0
            for (const [playerId, shipCount] of Object.entries(data.ships)) {
                if (Number(playerId) == gameStore.player?.id) {
                    playerShips = shipCount
                } else {
                    const relation = playerRelationStore.findRelationByPlayerId(Number(playerId));
                    if(relation.relation == 'enemy') {
                        enemyShips += shipCount
                    } else if(relation.relation == 'friend') {
                        friendlyShips += shipCount
                    } else if(relation.relation == 'ally') {
                        allyShips += shipCount
                    } else if(relation.relation == 'neutral') {
                        neutralShips += shipCount
                    }
                }
            }

            let playerShipInstanceId = null
            if(playerShips>0) {
                playerShipInstanceId = mapShipStore.addShip(data.xPos,data.yPos,0x00FF00);
            }
            let enemyShipInstanceId = null
            if(enemyShips>0) {
                enemyShipInstanceId = mapShipStore.addShip(data.xPos,data.yPos,0xFF0000);
            }
            let friendlyShipInstanceId = null
            if(friendlyShips>0) {
                friendlyShipInstanceId = mapShipStore.addShip(data.xPos,data.yPos,0xCE93D8);
            }
            let allyShipInstanceId = null
            if(allyShips>0) {
                allyShipInstanceId = mapShipStore.addShip(data.xPos,data.yPos,0x00FFFF);
            }
            let neutralShipInstanceId = null
            if(neutralShips>0) {
                neutralShipInstanceId = mapShipStore.addShip(data.xPos,data.yPos,0xB57533);
            }

            return {
                playerShip: playerShipInstanceId,
                enemyShip : enemyShipInstanceId,
                friendlyShip: friendlyShipInstanceId,
                allyShip : allyShipInstanceId,
                neutralShip : neutralShipInstanceId,
            }
        },
        process(data: MapCoordinate) {
            if(this.has(data)) {
                //Need to update

                /** Ships **/
                const oldData = this.get(data);
                this.clearShips(oldData);

                /** Update FleetMap Data **/
                // check for new ships
                for (const [playerId, shipCount] of Object.entries(data.ships)) {
                    const playerIdNum = parseInt(playerId); // Convert key to number if needed
                    // New/update Fleet

                    const eta = new Eta();
                    eta.start(data.xPos, data.yPos);
                    const newETA = eta.etaNext(this.etaXPos, this.etaYPos, this.etaSpeed);

                    const key = data.xPos + '-' + data.yPos + '-' + playerIdNum
                    this.fleets.set(key, {
                        id: key,
                        playerId: playerIdNum,
                        xPos: data.xPos,
                        yPos: data.yPos,
                        ships: shipCount,
                        eta: newETA
                    })
                }
                // Check for removed ships
                for (const [playerId, shipCount] of Object.entries(oldData.mapCoordinate.ships)) {
                    const playerIdNum = parseInt(playerId); // Convert key to number if needed
                    if (data.ships[playerIdNum] === undefined) {
                        // Removed Ship
                        const key = oldData.mapCoordinate.xPos + '-' + oldData.mapCoordinate.yPos + '-' + playerIdNum
                        this.fleets.delete(key)
                    }
                }

                //If this is open space, without ships, remove it
                if(Number(data.type) == 0 && Object.keys(data.ships).length == 0) {
                    //Remove
                    //console.log(data,Object.keys(data.ships).length)
                    this.coordinates.delete(data.xPos + '-' + data.yPos)
                    return
                }

                //Update Ships
                const shipInstances = this.setShips(data);

                /** Planet Owner Circle **/
                const mapPlanetOwnerStore = useMapPlanetOwnerStore();
                let planetOwnerInstance = oldData.planetOwnerInstance
                if(oldData.planetOwnerInstance!==null) {
                    mapPlanetOwnerStore.removePlanetOwner(oldData.planetOwnerInstance)
                    planetOwnerInstance = mapPlanetOwnerStore.addPlanetOwner(data.xPos,data.yPos, mapPlanetOwnerStore.getColor(data));
                }



                /** Overwrite Data **/
                this.set(data, shipInstances, planetOwnerInstance, planetOwnerInstance, oldData.starInstance)

                const mapStore = useMapStore();
                this.applyZoomFactor({
                    mapCoordinate: data,
                    shipInstances: shipInstances,
                    planetOwnerInstance: planetOwnerInstance,
                    planetInstance: oldData.planetInstance,
                    starInstance: oldData.starInstance
                }, mapStore.zoomFactor)

            } else {
                // Create new

                /** Ships **/
                const shipInstances = this.setShips(data);

                /** Planet **/
                let planetInstance = null
                if(Number(data.type) == 2) {
                    const mapPlanetStore = useMapPlanetStore();
                    planetInstance = mapPlanetStore.addPlanet(data.xPos,data.yPos,data.typeVariant,data.typeSize)
                }
                /** Planet Owner Circle **/
                let planetOwnerInstance = null
                if(Number(data.type) == 2) {
                    const mapPlanetOwnerStore = useMapPlanetOwnerStore();
                    const mapPlanetOwnerColor = mapPlanetOwnerStore.getColor(data)
                    planetOwnerInstance = mapPlanetOwnerStore.addPlanetOwner(data.xPos,data.yPos, mapPlanetOwnerColor);
                }

                let starInstance = null
                if(Number(data.type) == 1) {
                    const mapStarStore = useMapStarStore();
                    starInstance = mapStarStore.addStar(data.xPos,data.yPos,data.typeVariant,data.typeSize)
                }

                /** Update FleetMap Data **/
                // check for new ships
                for (const [playerId, shipCount] of Object.entries(data.ships)) {
                    const playerIdNum = parseInt(playerId); // Convert key to number if needed
                    // New/update Fleet
                    const key = data.xPos + '-' + data.yPos + '-' + playerIdNum
                    this.fleets.set(key, {
                        id: key,
                        playerId: playerIdNum,
                        xPos: data.xPos,
                        yPos: data.yPos,
                        ships: shipCount
                    })
                }

                /** Store Data **/
                this.set(data, shipInstances, planetOwnerInstance, planetInstance, starInstance)

                const mapStore = useMapStore();
                this.applyZoomFactor({
                    mapCoordinate: data,
                    shipInstances: shipInstances,
                    planetOwnerInstance: planetOwnerInstance,
                    planetInstance: planetInstance,
                    starInstance: starInstance
                },mapStore.zoomFactor)
            }
            //Make sure the new element has the correct zoomFactor applied

        },
        applyZoomFactor(data: any, zoom: number) {
            const shipInstances = data.shipInstances;
            //on each zoomFactor we need to move things around or resize
            const mapShipStore = useMapShipStore();

            /** Ships Scaling and Positioning **/
            let position = 0
            const totalPositions = (shipInstances.playerShip !== null ? 1 : 0) + (shipInstances.enemyShip !== null ? 1 : 0) + (shipInstances.friendlyShip !== null ? 1 : 0) + (shipInstances.allyShip !== null ? 1 : 0) + (shipInstances.neutralShip !== null ? 1 : 0)

            if(shipInstances.playerShip!==null) {
                position++
                mapShipStore.scaleAndPositionShip(
                  shipInstances.playerShip,
                  data.mapCoordinate,
                  totalPositions,
                  position,
                  zoom
                );
            }
            if(shipInstances.enemyShip!==null) {
                position++
                mapShipStore.scaleAndPositionShip(
                  shipInstances.enemyShip,
                  data.mapCoordinate,
                  totalPositions,
                  position,
                  zoom
                );
            }
            if(shipInstances.friendlyShip!==null) {
                position++
                mapShipStore.scaleAndPositionShip(
                  shipInstances.friendlyShip,
                  data.mapCoordinate,
                  totalPositions,
                  position,
                  zoom
                );
            }
            if(shipInstances.allyShip!==null) {
                position++
                mapShipStore.scaleAndPositionShip(
                  shipInstances.allyShip,
                  data.mapCoordinate,
                  totalPositions,
                  position,
                  zoom
                );
            }
            if(shipInstances.neutralShip!==null) {
                position++
                mapShipStore.scaleAndPositionShip(
                  shipInstances.neutralShip,
                  data.mapCoordinate,
                  totalPositions,
                  position,
                  zoom
                );
            }

            /** Planet Owner Circle Scaling and Positioning **/
            if(data.planetOwnerInstance!==null) {
                const mapPlanetOwnerStore = useMapPlanetOwnerStore();
                mapPlanetOwnerStore.scaleAndPositionPlanetOwner(
                  data.planetOwnerInstance,
                  data.mapCoordinate,
                  zoom
                );
            }
            /** Planet Does not Scale **/

            /** Star Does not Scale **/

            /** CrossHairs Do Scale **/
            new MapCrossHairUpdate(this.scene, zoom)

        },
        applyZoomFactorAll(zoom: number) {
            this.coordinates.forEach((value, key) => {
                this.applyZoomFactor(value, zoom)
            });

            /** Settle Indicator does Scale **/
            const mapPendingSettleStore = useMapPendingSettleStore();
            mapPendingSettleStore.scaleAndPositionObjects(zoom)
        },
        reDrawPlayerAssets(playerId: number) {
            //Redraw all assets for a player, (because of a change in relation)
            this.coordinates.forEach((value, key) => {
                if(value.mapCoordinate.ships[playerId] !== undefined || value.mapCoordinate.typePlayerId == playerId) {
                    this.process(value.mapCoordinate)
                }
            });
        }
    }
});
