<script setup lang="ts">

import FullScreenModel from '@/components/Interface/Modal/ModelSizes/FullScreenModel.vue'
import BattleReportPlayBack from '@/components/Interface/Modal/Modals/BattleReport/BattleReportPlayBack.vue'
import ActionButton from '@/components/Elements/Buttons/ActionButton.vue'
import { ref } from 'vue'
import { useBattleReportStore } from '@/stores/battleReportStore.ts'
import BattleReportAttackerStats from '@/components/Interface/Modal/Modals/BattleReport/BattleReportAttackerStats.vue'
import BattleReportDefenderStats from '@/components/Interface/Modal/Modals/BattleReport/BattleReportDefenderStats.vue'
import { useGameStore } from '@/stores/gameStore.ts'

/**
 * Stores
 */
const battleReportStore = useBattleReportStore();
const gameStore = useGameStore()

/**
 * Variables
 */
const leftOpen = ref(false)
const rightOpen = ref(false)

/**
 * Functions
 */
const openLeft = () => {
  leftOpen.value = !leftOpen.value;
  rightOpen.value = false
}

const openRight = () => {
  rightOpen.value = !rightOpen.value;
  leftOpen.value = false
}

</script>

<template>
  <FullScreenModel title="Battle Report">
    <div class="h-[calc(100%-4rem)] relative">
      <div v-if="battleReportStore.winner === gameStore.player?.id" class="absolute top-24 text-green-500 left-1/2 -translate-x-1/2 z-50" style="font-size: 4rem;">
        Victory!
      </div>
      <div v-if="battleReportStore.winner !== gameStore.player?.id && battleReportStore.winner !== 0" class="absolute top-24 text-red-500 left-1/2 -translate-x-1/2 z-50" style="font-size: 4rem;">
        Defeat
      </div>
      <div v-if="leftOpen" title="Attacker" class="absolute w-full z-50 bottom-10 block xl:hidden">
        <BattleReportAttackerStats/>
      </div>
      <div v-if="rightOpen" title="Defender" class="absolute w-full z-50 bottom-10 right-0 block xl:hidden">
        <BattleReportDefenderStats/>
      </div>
      <div class="flex">
        <div class="w-96 hidden xl:block px-1">
          <div>
            <BattleReportAttackerStats/>
          </div>
        </div>
        <div class="relative w-full">
          <ActionButton class="absolute bottom-0 left-0 xl:hidden m-1" @click="openLeft">Attacker Stats</ActionButton>
          <select
            v-model="battleReportStore.playSpeed"
            class="absolute rounded bottom-0 left-1/2 -translate-x-1/2 p-1 mb-1 bg-slate-700 text-slate-300"
          >
            <option value="200">Slow</option>
            <option value="100">Normal</option>
            <option value="50">Fast</option>
            <option value="0">Max</option>
          </select>

          <ActionButton class="absolute bottom-0 right-0 xl:hidden m-1" @click="openRight">Defender Stats</ActionButton>
          <BattleReportPlayBack/>
        </div>
        <div class="w-96 hidden xl:block px-1">
          <div>
            <BattleReportDefenderStats/>
          </div>
        </div>
      </div>
    </div>
  </FullScreenModel>

</template>

<style scoped lang="scss">

</style>