<script setup lang="ts">
import IconCount from "@/components/Objects/IconCount.vue";
import {useShipDesignStore} from "@/stores/shipDesignStore.ts";
import ShipDesignInUse from '@/components/Sections/Command/ShipDesigns/ShipDesignInUse.vue'
import ShipDesignInProduction from '@/components/Sections/Command/ShipDesigns/ShipDesignInProduction.vue'
import CellFixedWidth from '@/components/Interface/DataTable/Cell/CellFixedWidth.vue'
import HeaderFixedWidthIcon from '@/components/Interface/DataTable/Headers/HeaderFixedWidthIcon.vue'
import CellFlexWidth from '@/components/Interface/DataTable/Cell/CellFlexWidth.vue'
import FerionDataTable from '@/components/Interface/DataTable/FerionDataTable.vue'
import HeaderTextFlex from '@/components/Interface/DataTable/Headers/HeaderTextFlex.vue'
import { useTableStore } from '@/stores/tableStore.ts'
import HeaderFixedWidthTextNoSort from '@/components/Interface/DataTable/Headers/HeaderFixedWidthTextNoSort.vue'
import type PlayerShipDesign from '@/types/PlayerShipDesign.ts'
import GenericIcon from '@/components/Elements/Images/Icons/GenericIcon.vue'
import { computed } from 'vue';
import { usePlanetStore } from '@/stores/planetStore.ts';
import { useShipStore } from '@/stores/shipStore.ts';
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'

const shipDesignStore = useShipDesignStore();
const tableStore = useTableStore();
const planetStore = usePlanetStore();
const shipStore = useShipStore();

const filters = (shipDesigns:Map<number,PlayerShipDesign>) => {
  return Array.from(shipDesigns.values())
    .filter((shipDesign) => {
      return !shipDesign.deleted
    })
    .filter((shipDesign) => {
      // If no search query, return all ships
      if (!tableStore.shipDesigns.filters.nameSearch || tableStore.shipDesigns.filters.nameSearch === '') {
        return true;
      }
      // Otherwise, filter based on the ship name (case-insensitive search)
      return shipDesign.name.toLowerCase().includes(tableStore.shipDesigns.filters.nameSearch.toLowerCase());
    })
};



const computedPlayerShipDesigns = computed(() => {
  return Array.from(shipDesignStore.playerShipDesigns.values())
    .map(shipDesign => {
      const inProduction = planetStore.getNumberOfShipsInProduction(shipDesign.id);
      const inUse = shipStore.getNumberOfShipsUsingDesignId(shipDesign.id);
      const inProductionCost = inProduction * shipDesign.maintenanceCost;
      const inUseCost = inUse * shipDesign.maintenanceCost;
      return {
        ...shipDesign,
        inProduction,
        inUse,
        inProductionCost,
        inUseCost
      };
    })
    .filter(shipDesign => shipDesign.inProduction > 0 || shipDesign.inUse > 0);
});

</script>

<template>
  <StandardCard top-padding="0" title="Ship maintenance" help="Ships require maintenance, and that costs credits. Depending on the size of the ship the maintenance cost will go up. Below you can see a list of all your ship designs with the cost per ship, total cost of ships in use and the cost of ships that are currently being produced."/>

  <div>
    <FerionDataTable
      :data="computedPlayerShipDesigns"
      tableStoreName="shipDesigns"
      :selector="false"
      :filters="filters"
      :heightLimit="'h-[calc(100vh-9.5rem)]'"
    >
      <!--- Headers --->
      <template #columns="{ sort, sortColumn, sortDirection }">
        <HeaderTextFlex min-width="12" column="name" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort" class="rounded-tl">
          <div class="pl-2">Name</div>
        </HeaderTextFlex>
        <HeaderFixedWidthIcon width="5" column="maintenanceCost" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
          <GenericIcon type="credit" tooltip="Cost per ship"></GenericIcon>
        </HeaderFixedWidthIcon>
        <HeaderFixedWidthTextNoSort width="8" align="center">
          In production
        </HeaderFixedWidthTextNoSort>
        <HeaderFixedWidthIcon width="5" column="inProductionCost" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
          <GenericIcon type="credit" tooltip="Total cost of ships in production"></GenericIcon>
        </HeaderFixedWidthIcon>
        <HeaderFixedWidthTextNoSort width="5" align="center">
          In use
        </HeaderFixedWidthTextNoSort>
        <HeaderFixedWidthIcon width="5" column="inUserCost" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
          <GenericIcon type="credit" tooltip="Total cost of ships in use"></GenericIcon>
        </HeaderFixedWidthIcon>
      </template>
      <!--- Rows --->
      <template #rows="{ item }">
        <CellFlexWidth min-width="12" >
          <div class="flex-col pl-2">
            <div>{{ item.name }}</div>
          </div>
        </CellFlexWidth>
        <CellFixedWidth width="5">
          <IconCount :amount="-item.maintenanceCost" :no-plus="true"/>
        </CellFixedWidth>
        <CellFixedWidth width="8">
          <ShipDesignInProduction :shipDesignId="item.id"></ShipDesignInProduction>
        </CellFixedWidth>
        <CellFixedWidth width="5">
          <IconCount :amount="-item.inProductionCost" :no-plus="true"/>
        </CellFixedWidth>
        <CellFixedWidth width="5">
          <ShipDesignInUse :shipDesignId="item.id"></ShipDesignInUse>
        </CellFixedWidth>
        <CellFixedWidth width="5">
          <IconCount :amount="-item.inUseCost" :no-plus="true"/>
        </CellFixedWidth>
      </template>
    </FerionDataTable>
  </div>
</template>

<style scoped lang="scss">

</style>