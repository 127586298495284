<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, nextTick } from "vue";
import { useNewsStore } from "@/stores/newsStore.ts";
import router from "@/router";
import Backend from "@/models/backend.ts";
import { useGameStore } from "@/stores/gameStore.ts";
import NewsItem from "@/components/Sections/News/NewsItem.vue";
import ActionButton from "@/components/Elements/Buttons/ActionButton.vue";
import Loader from "@/components/Elements/Loaders/Loader.vue";
import FilterButton from '@/components/Elements/Buttons/FilterButton.vue'
import GenericIcon from '@/components/Elements/Images/Icons/GenericIcon.vue'

const newsStore = useNewsStore();
const gameStore = useGameStore();
const backend = new Backend();

const subType = ref("all");
const loading = ref(false);
const observer = ref<IntersectionObserver | null>(null);
const loadMoreTrigger = ref<HTMLElement | null>(null);

const gotoNewsDetails = (path: string) => {
  router.push(path);
};

onMounted(async () => {
  // Load the first page
  if (!newsStore.firstLoad) {
    loading.value = true;
    await backend.getLatestNews(0);
    loading.value = false;
  }

  await nextTick(); // Ensure DOM updates before observing
  setupObserver();
});

onUnmounted(() => {
  if (gameStore.player) {
    newsStore.setAllRead();
    backend.setNewsRead();
  }

  observer.value?.disconnect();
});

const loadMore = async () => {
  if (loading.value) return;
  loading.value = true;
  await backend.getLatestNews(newsStore.lowestId);
  loading.value = false;
};

const setupObserver = () => {
  if (!loadMoreTrigger.value) return;

  observer.value = new IntersectionObserver(
    (entries) => {
      if (entries[0].isIntersecting) {
        loadMore();
      }
    },
    { rootMargin: "100px" } // Trigger 100px before reaching bottom
  );

  observer.value.observe(loadMoreTrigger.value);
};

const sortedNews = computed(() => {
  return [...newsStore.news.values()]
    .filter(newsItem => (subType.value === 'all' || newsItem.subType === subType.value))
    .sort((a, b) => b.id - a.id);
});

const newSubTypeCount = (type: string) => computed(() => {
  return [...newsStore.news.values()].filter(newsItem =>
    (!newsItem.isRead && type === 'all') ||
    (!newsItem.isRead && newsItem.subType === type)
  ).length;
});

const gotoNotifications = () => {
  router.push("/news/notifications");
};

</script>

<template>
  <div class="p-2 pb-0 overflow-x-scroll text-nowrap">
    <ActionButton :for-icon="true" class="mr-2" @click="gotoNotifications"><GenericIcon type="bell"/></ActionButton>
    <FilterButton class="mr-2" :active="subType === 'all'" @click="subType = 'all'">All <span v-if="newSubTypeCount('all').value > 0" class="bg-red-500 rounded ms-1 px-1 text-xs">{{ newSubTypeCount('all') }}</span></FilterButton>
    <FilterButton class="mr-2" :active="subType === 'research'" @click="subType = 'research'">Research <span v-if="newSubTypeCount('research').value > 0" class="bg-red-500 rounded ms-1 px-1 text-xs">{{ newSubTypeCount('research') }}</span></FilterButton>
    <FilterButton class="mr-2" :active="subType === 'battles'" @click="subType = 'battles'">Battles <span v-if="newSubTypeCount('battles').value > 0" class="bg-red-500 rounded ms-1 px-1 text-xs">{{ newSubTypeCount('battles') }}</span></FilterButton>
    <FilterButton class="mr-2" :active="subType === 'settlement'" @click="subType = 'settlement'">New Planets <span v-if="newSubTypeCount('settlement').value > 0" class="bg-red-500 rounded ms-1 px-1 text-xs">{{ newSubTypeCount('settlement') }}</span></FilterButton>
    <FilterButton class="mr-2" :active="subType === 'diplomacy'" @click="subType = 'diplomacy'">Diplomacy <span v-if="newSubTypeCount('diplomacy').value > 0" class="bg-red-500 rounded ms-1 px-1 text-xs">{{ newSubTypeCount('diplomacy') }}</span></FilterButton>
    <FilterButton class="mr-2" :active="subType === 'espionage'" @click="subType = 'espionage'">Espionage <span v-if="newSubTypeCount('espionage').value > 0" class="bg-red-500 rounded ms-1 px-1 text-xs">{{ newSubTypeCount('espionage') }}</span></FilterButton>
    <FilterButton class="mr-2" :active="subType === 'finances'" @click="subType = 'finances'">Finances <span v-if="newSubTypeCount('finances').value > 0" class="bg-red-500 rounded ms-1 px-1 text-xs">{{ newSubTypeCount('finances') }}</span></FilterButton>
    <FilterButton class="mr-2" :active="subType === 'other'" @click="subType = 'other'">Other <span v-if="newSubTypeCount('other').value > 0" class="bg-red-500 rounded ms-1 px-1 text-xs">{{ newSubTypeCount('other') }}</span></FilterButton>
  </div>
  <div class="grid grid-cols-1 lg:grid-cols-2 gap-2 p-2">
    <div v-for="newsItem in sortedNews" :key="newsItem.id" class="cursor-pointer" @click="gotoNewsDetails(newsItem.targetUrl)">
      <NewsItem :newsItem="newsItem" />
    </div>
  </div>
  <div class="flex justify-center">
    <Loader v-if="loading" />
  </div>
  <div v-if="sortedNews.length === 0 && !loading">
    <div class="text-center text-subtext p-6">No news available</div>
  </div>
  <div ref="loadMoreTrigger" class="h-10"></div> <!-- Invisible trigger element -->
</template>
