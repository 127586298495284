<script setup lang="ts">

import TinyModel2 from '@/components/Interface/Modal/ModelSizes/Type2/TinyModel2.vue'
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import { useGameStore } from '@/stores/gameStore.ts'
import { computed, onMounted, type PropType, ref, watch } from 'vue'
import { usePlayerOreStore } from '@/stores/playerOreStore.ts'
import GenericIconWithNumber from '@/components/Elements/Images/Icons/GenericIconWithNumber.vue'
import GenericIcon from '@/components/Elements/Images/Icons/GenericIcon.vue'
import NamedNumbers from '@/components/Elements/Generic/NamedNumbers.vue'
import DeleteButton from '@/components/Elements/Buttons/DeleteButton.vue'
import FormInput from '@/components/Interface/Modal/Forms/FormInput.vue'
import ActionButton from '@/components/Elements/Buttons/ActionButton.vue'
import GenericIconWithColoredNumber from '@/components/Elements/Images/Icons/GenericIconWithColoredNumber.vue'
import Backend from '@/models/backend.ts'
import ColoredNumber from '@/components/Elements/Generic/ColoredNumber.vue'

const gameStore = useGameStore()
const playerOreStore = usePlayerOreStore()

const amount = ref(0)

watch (() => amount.value, () => {
  //if the amount is text, set it to 0
  if(isNaN(amount.value)) {
    amount.value = 0
  }
  if(amount.value < 0) {
    amount.value = 0
  }
  if(amount.value > 5000) {
    amount.value = 5000
  }
})

watch(() => playerOreStore.tradeData, () => {
  const data = playerOreStore.getPlayerOreTrade(gameStore.modal2Data.sourceOre.id, gameStore.modal2Data.targetOre.id)
  if(data) {
    amount.value = data.quantity
  }
},{deep:true})

onMounted(() => {
  const data = playerOreStore.getPlayerOreTrade(gameStore.modal2Data.sourceOre.id, gameStore.modal2Data.targetOre.id)
  if(data) {
    amount.value = data.quantity
  }
})

const setOreTrade = () => {
  const backend = new Backend()
  backend.setOreTrade(gameStore.modal2Data.sourceOre.id, gameStore.modal2Data.targetOre.id, amount.value).then(() => {
    gameStore.setTinySuccessToastMessage('Trade will take effect next "Week"')
    gameStore.modal2Type=''
  })
}

const resetOreTrade = () => {
  const backend = new Backend()
  backend.setOreTrade(gameStore.modal2Data.sourceOre.id, gameStore.modal2Data.targetOre.id, 0).then(() => {
    gameStore.setTinySuccessToastMessage('Cancellation will take effect next "Week"')
    gameStore.modal2Type=''
  })
}

</script>

<template>
<TinyModel2 :title="'Trade'" style="z-index: 100">
  <div class="p-2">
    <div class="flex justify-between">
      <StandardCard top-padding="0">
        <div class="flex flex-col items-center w-20">
          <div class="pb-2">{{ gameStore.modal2Data.sourceOre.oreName }}</div>
          <img alt="" style="width: 4rem;" :src="gameStore.cdn + '/images/icons/ore/' + gameStore.modal2Data.sourceOre.id + '.png'" class="pb-2">
          <div class="bg-slate-900 p-1.5 rounded w-full flex justify-between">
            <GenericIcon :type="'ore' + gameStore.modal2Data.sourceOre.id"/><ColoredNumber :amount="-amount" :shortFormat="true" class="ps-2"/>
          </div>
        </div>
      </StandardCard>
      <div class="flex flex-col items-center justify-evenly px-2">
        <GenericIconWithColoredNumber :amount="-amount*gameStore.modal2Data.targetOre.tradeConversionCost" type="credit"/>
        <div class="flex justify-between items-center">
          <div class="px-2 w-24">
            <form-input type="number" v-model="amount"></form-input>
          </div>
          <div class="pe-2">
            <ActionButton @click="setOreTrade">Set</ActionButton>
          </div>
          <DeleteButton :for-icon="true" @click="resetOreTrade"><GenericIcon type="trash"/></DeleteButton>
        </div>
      </div>
      <StandardCard top-padding="0">
        <div class="flex flex-col items-center w-20">
          <div class="pb-2">{{ gameStore.modal2Data.targetOre.oreName }}</div>
          <img alt="" style="width: 4rem;" :src="gameStore.cdn + '/images/icons/ore/' + gameStore.modal2Data.targetOre.id + '.png'" class="pb-2">
          <div class="bg-slate-900 p-1.5 rounded w-full flex justify-between">
            <GenericIcon :type="'ore' + gameStore.modal2Data.targetOre.id"/><ColoredNumber :amount="amount" :shortFormat="true" class="ps-2"/>
          </div>
        </div>
      </StandardCard>
    </div>
    <StandardCard top-padding="2">
      Enter how much ore you wish to trade every week. Each ore costs <GenericIcon tooltip="Credits" type="credit"/> <span class="text-red-500">{{-gameStore.modal2Data.targetOre.tradeConversionCost}}</span> to trade. If not enough source ore is available, less or no ore will be traded.
    </StandardCard>
  </div>
</TinyModel2>
</template>

<style scoped lang="scss">
input {
  @apply bg-slate-600 p-1 text-slate-400 focus:outline-none
}
</style>