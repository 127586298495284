<script setup lang="ts">

import type MapCoordinate from '@/types/MapCoordinate.ts'
import {computed} from "vue";
import {useAssetStore} from "@/stores/assetStore.ts";
import StarAnimation from '@/components/Elements/Animations/Star/StarAnimation.vue'
import GenericIconWithColoredNumber from '@/components/Elements/Images/Icons/GenericIconWithColoredNumber.vue'
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'

const assetStore = useAssetStore()

const props = defineProps<{
  coordinate: MapCoordinate
}>();

const starTypeData = computed(() => {
  return assetStore.findStarTypeById(props.coordinate.typeVariant)
})

const starName = computed(() => {
  if(props.coordinate.typeVariant === 1) {
    return 'Neutron'
  } else if(props.coordinate.typeVariant === 2) {
    return 'White Dwarf'
  } else if(props.coordinate.typeVariant === 3) {
    return 'Red Giant'
  } else if(props.coordinate.typeVariant === 4) {
    return 'Red Dwarf'
  } else if(props.coordinate.typeVariant === 5) {
    return 'K Type'
  } else if(props.coordinate.typeVariant === 6) {
    return 'G Type'
  } else if(props.coordinate.typeVariant === 7) {
    return 'F Type'
  } else if(props.coordinate.typeVariant === 8) {
    return 'A Type'
  } else if(props.coordinate.typeVariant === 9) {
    return 'B Type'
  } else if(props.coordinate.typeVariant === 10) {
    return 'O Type'
  } else {
    return 'Unknown'
  }
})

</script>

<template>
  <div class="background border-slate-800 border">
    <div class="flex justify-between">
      <div class="text-center text-subtext p-1">
        {{ starName }}
      </div>
      <div class="text-center text-subtext p-1 mr-1" title="Star Energy Output">
        <GenericIconWithColoredNumber :amount="starTypeData?.energyOutput ?? 0" type="energy"/>
      </div>
    </div>
    <div class="flex justify-center items-center star-image">
      <StarAnimation :type="props.coordinate.typeVariant"/>
    </div>
  </div>
</template>

<style scoped lang="scss">
.star-image {
  height:9.9rem;
  width:9.9rem;
  overflow: hidden;
}
.background {
  background-image: url("https://cdn.galexion.com/ferion/images/backgrounds/space.webp");
}
</style>