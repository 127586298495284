<script setup lang="ts">
import TinyModel2 from '@/components/Interface/Modal/ModelSizes/Type2/TinyModel2.vue'
import { useGameStore } from '@/stores/gameStore.ts'
import { usePlayerRelationStore } from '@/stores/playerRelations.ts'
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import Backend from '@/models/backend.ts'

/**
 * Stores
 */
const gameStore = useGameStore()
const playerRelationStore = usePlayerRelationStore()

/**
 * Functions
 */
const setMission = (playerId:number) => {
  const backend = new Backend();
  backend.setSpyMission(gameStore.modal2Data.spyId,playerId,0, gameStore.modal2Data.missionCode).then(
    () => {
      gameStore.modal2Type = ''
    }
  )
}

</script>

<template>
  <TinyModel2 :title="gameStore.modal2Data.title" style="z-index: 100">

    <div class="p-2">
        <div class="grid grid-cols-2 gap-2">
          <template v-for="player in playerRelationStore.relations.values()" :key="player.playerId">
            <StandardCard top-padding="0">
            <div class="flex flex-col justify-center relative cursor-pointer" @click="setMission(player.playerId)">
              <div class="text-slate-300 p-1 absolute bottom-0 w-full bg-black bg-opacity-70 text-center rounded-b">{{ player.name }}</div>
              <img :src="player.profileImage" class="rounded object-cover" alt="">
            </div>
            </StandardCard>
          </template>
        </div>
        <StandardCard v-if="playerRelationStore.relations.size === 0" top-padding="0">
          <div>
            You have not met any empires yet.
          </div>
        </StandardCard>
    </div>

  </TinyModel2>
</template>


<style scoped lang="scss">

</style>