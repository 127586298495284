<script setup lang="ts">

import { usePlanetStore } from '@/stores/planetStore.ts'
import { computed, defineProps } from 'vue'
import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import ActionButton from '@/components/Elements/Buttons/ActionButton.vue'
import GenericIcon from '@/components/Elements/Images/Icons/GenericIcon.vue'
import { useGameStore } from '@/stores/gameStore.ts'
import { usePQAStore } from '@/stores/PQAStore.ts'
import Backend from '@/models/backend.ts'

/**
 * Props
 */
const props = defineProps({
  planetId: {
    type: Number,
    required: true
  }
});

/**
 * Stores / models
 */
const planetStore = usePlanetStore();
const gameStore = useGameStore();
const PQAStore = usePQAStore()
const backend = new Backend()

/**
 * Functions
 */
const createPQA = () => {
  gameStore.modalType='createPQA';
};

const enablePQA = (id:number) => {
  backend.planetSetPQA([props.planetId],id).then((result) => {
    gameStore.setTinySuccessToastMessage('Building queue enabled');
  })
};

const disablePQA = () => {
  backend.planetSetPQA([props.planetId],0).then((result) => {
    gameStore.setTinySuccessToastMessage('Building queue disabled');
  })
};

const configurePQA = (id:number) => {
  gameStore.modalType = 'configPQA';
  gameStore.modalData = id;
};

/**
 * Computed
 */
const planetData = computed(() => planetStore.findPlanetById(props.planetId)!)

const inActive = computed(() => {
  return [...PQAStore.queues.values()].filter(
    (queue) => queue.id !== planetData.value.planetQueueAutomationId
  );
});


</script>

<template>

  <StandardCard title="Building Queues" topPadding="1" help="Building queues allow you to automate the construction of buildings on a planet."></StandardCard>

  <StandardCard v-if="planetData.planetQueueAutomationId" title="Current" topPadding="2">
    <div class="flex">
      <div class="flex-grow text-subtext self-center">{{ PQAStore.queues.get(planetData.planetQueueAutomationId)!.name }}</div>
      <div class="px-2">
        <ActionButton @click="configurePQA(planetData.planetQueueAutomationId)"  :for-icon="true"><GenericIcon tooltip="Configure" type="cog" /></ActionButton>
      </div>
      <div class="">
        <ActionButton @click="disablePQA()"  :for-icon="true"><GenericIcon tooltip="Disable" type="off"/></ActionButton>
      </div>
    </div>
  </StandardCard>

  <StandardCard v-if="inActive.length>0" title="Available" topPadding="2">
    <template v-for="(pqa, index) in inActive" :key="pqa.id">
      <div :class="['flex', { 'pt-2 mt-2 border-t border-t-slate-700': index > 0 }]">
        <div class="flex-grow text-subtext self-center">{{ pqa.name }}</div>
        <div class="px-2">
          <ActionButton @click="configurePQA(pqa.id)"  :for-icon="true"><GenericIcon tooltip="Configure" type="cog" /></ActionButton>
        </div>
        <div>
          <ActionButton @click="enablePQA(pqa.id)"  :for-icon="true"><GenericIcon tooltip="Enable" type="on" /></ActionButton>
        </div>
      </div>
    </template>
  </StandardCard>

  <StandardCard top-padding="2">
    <ActionButton @click="createPQA" :full-width="true">Create New Building Queue</ActionButton>
  </StandardCard>

</template>

<style scoped lang="scss">

</style>