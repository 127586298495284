<script setup lang="ts">
import { computed, defineProps, ref, type PropType } from 'vue'
import type OreConversion from '@/types/OreConversion.ts'
import { formatShort } from '@/models/formatNumber.ts'

const props = defineProps({
  conversion: {
    type: Object as PropType<OreConversion>,
    required: true
  },
  objectImage: {
    type: String,
    required: true
  },
  width: {
    type: Number,
    required: true
  },
  height: {
    type: Number,
    required: true
  },
  top: {
    type: Number,
    required: true
  },
  left: {
    type: Number,
    required: false
  },
  right: {
    type: Number,
    required: false
  },
  debug: {
    type: Boolean,
    required: false,
    default: false
  }
})

const hover = ref(false)

const conveyorColor = computed(() => {
  return props.conversion.capacity == 0 ? '#151E31' : 'black'
})

const oreSpeed = computed(() => {
  if (props.conversion.capacity <= 0) {
    return 0
  }
  const logValue = Math.log10(1000 / props.conversion.capacity)
  return Math.max(1, logValue)
})

// Determine positioning based on `left` or `right`
const positionStyle = computed(() => {
  if (props.left !== undefined) {
    return `top: ${props.top}rem; left: ${props.left}rem; width: ${props.width}rem; height: ${props.height}rem;`
  } else if (props.right !== undefined) {
    return `top: ${props.top}rem; right: ${props.right}rem; width: ${props.width}rem; height: ${props.height}rem;`
  }
  return ''
})

// Determine tooltip position
const tooltipStyle = computed(() => {
  const base = `top: ${props.top + 1}rem; width: 5rem; z-index: 18;`
  if (props.left !== undefined) {
    return `${base} left: ${props.left + 1.5}rem;`
  } else if (props.right !== undefined) {
    return `${base} right: ${props.right + 1.5}rem;`
  }
  return base
})
</script>

<template>
  <svg
    :class="{ 'absolute': true, 'border': debug }"
    :style="positionStyle"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- Background rail -->
    <rect
      x="0"
      y="0"
      :width="width + 'rem'"
      height="1rem"
      :fill="conveyorColor"
      @mouseenter="hover = true"
      @mouseout="hover = false"
    />

    <!-- Moving ore image -->
    <image
      v-if="conversion.capacity > 0"
      :href="props.objectImage"
      x="0"
      y="0"
      width="1rem"
      height="1rem"
      @mouseenter="hover = true"
      @mouseout="hover = false"
    >
      <!-- Animate leftward movement -->
      <animate
        attributeName="x"
        :values="`0; ${width - 1}rem`"
        keyTimes="0;1"
        :dur="oreSpeed + 's'"
        repeatCount="indefinite"
      />
    </image>
  </svg>

<!--  &lt;!&ndash; Tooltip on hover &ndash;&gt;-->
<!--  <div-->
<!--    v-if="props.conversion.capacity > 0 && hover"-->
<!--    class="absolute text-green-500 border p-1 rounded border-slate-600 bg-slate-800 text-center"-->
<!--    :style="tooltipStyle"-->
<!--  >-->
<!--    {{ formatShort(props.conversion.capacity) }}-->
<!--    <small class="text-subtext"> / week</small>-->
<!--  </div>-->
</template>

<style scoped lang="scss">
</style>
