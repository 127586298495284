<script setup lang="ts">

import { defineProps, computed } from 'vue'
import { useAssetStore } from '@/stores/assetStore.ts'
import NamedNumbers from '@/components/Elements/Generic/NamedNumbers.vue'
import GenericIcon from '@/components/Elements/Images/Icons/GenericIcon.vue'
import { usePlayerRelationStore } from '@/stores/playerRelations.ts'
import { useGameStore } from '@/stores/gameStore.ts'

/**
 * Props
 */
const props = defineProps({
  spy: {
    type: Object,
    required: true
  },
  type: {
    type: String,
    required: true
  }
});

/**
 * Stores
 */
const assetStore = useAssetStore();
const gameStore = useGameStore();
const playerRelationStore = usePlayerRelationStore();

/**
 * Computed
 */
const mission = computed(() => assetStore.findSpyMissionByCode(props.spy.mission)!);

const image = computed(() => {
  if(!props.spy.targetPlayerId) return gameStore.player?.profileImage
  return playerRelationStore.getProfileImageByPlayerId(props.spy.targetPlayerId)
})

const playerName = computed(() => {
  if(!props.spy.targetPlayerId) return gameStore.player?.nickName
  return playerRelationStore.getNameByPlayerId(props.spy.targetPlayerId)
})


</script>

<template>
  <div v-if="type==='detailed'">
    <div class="flex flex-col">
      <div class="text-slate-300 pb-1">{{ mission.name }}</div>
      <div> {{ mission.description}} </div>
      <NamedNumbers text="Difficulty" class="w-full mt-2">
        <GenericIcon
          v-for="n in mission.difficulty"
          :key="n"
          type="spy"
          tooltip="Difficulty"
          class="ms-1"
        />
      </NamedNumbers>
      <NamedNumbers v-if="mission.cost>0" text="Difficulty" class="w-full mt-2">
        <GenericIcon
          v-for="n in mission.cost"
          :key="n"
          type="spy"
          tooltip="Cost"
          class="ms-1"
        />
      </NamedNumbers>
    </div>
  </div>
  <div v-else-if="type==='basic'">
    <div class="flex justify-between p-1 w-full bg-slate-900 rounded">
      <img :src="'https://galexion.com/cache?url=' + image + '&w=40&h=40'" class="inline fixed-size h-10 rounded" :alt="playerName" :title="playerName"/>
      <div class="flex flex-grow flex-col justify-center text-center">
        <span v-if="spy.killed" class="text-white">Killed</span>
        <span v-else class="text-green-500">{{ spy.missionName }}</span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>