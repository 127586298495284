<script setup lang="ts">

import { useNewsStore } from '@/stores/newsStore.ts'
import NewsItem from '@/components/Sections/News/NewsItem.vue'

const newsStore = useNewsStore();

</script>

<template>

<div class="fixed right-0 z-40 toaster">
  <div v-for="newsItem in newsStore.toasts.values()" :key="newsItem.id">
    <NewsItem :newsItem="newsItem" :toast="true"></NewsItem>
  </div>
</div>

</template>

<style scoped lang="scss">
.toaster {
  bottom: 3.5rem;
}
</style>