import numeral from 'numeral';

export const formatNumber = (value: number): string => numeral(value).format('0,0');

export const formatShort = (value: number): string => {
  if (Math.abs(value) < 100) {
    if (value % 1 === 0) {
      return numeral(value).format('0');
    } else {
      return numeral(value).format('0.00');
    }
  }

  if (Math.abs(value) < 1000) {
    return numeral(value).format('0');
  }

  if (value % 1000 === 0) {
    return numeral(value).format('0a');
  }

  return numeral(value).format('0.[0]a');
};
