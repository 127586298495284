import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import Init from "@/models/init";
import router from './router'
import PrimeVue from 'primevue/config';
import * as Sentry from "@sentry/vue";
import { version as appVersion } from '../package.json';
import { CDN } from '@/models/cdn.ts'
import { initializeApp } from 'firebase/app';

const app = createApp(App)

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    app,
    dsn: "https://41ab5a7116f743bee4b7bc6c072e4d2e@o1300993.ingest.us.sentry.io/4508050333958144",
    release: `ferion@${appVersion}`, // Dynamic version from package.json
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const firebaseConfig = {
  apiKey: "AIzaSyCxEfl1tl6uzODYCQGGaZUCWX8IS3_a9JE",
  authDomain: "ferion-1fc5e.firebaseapp.com",
  projectId: "ferion-1fc5e",
  storageBucket: "ferion-1fc5e.firebasestorage.app",
  messagingSenderId: "374146483378",
  appId: "1:374146483378:web:cb31fda7bf195f085cb5ab",
  measurementId: "G-XJECXWGRBR"
};

const firebaseApp = initializeApp(firebaseConfig);

// Listen for messages from the service worker
navigator.serviceWorker.addEventListener('message', event => {
  if (event.data && event.data.type === 'navigate' && event.data.route) {
    router.push(event.data.route);
  }
});

app.use(router)
app.use(PrimeVue);
app.use(createPinia())
app.mount('#app')

// Register the CDN object globally
app.config.globalProperties.CDN = CDN;

//Init the game
new Init();

