<script setup lang="ts">

import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import { computed } from 'vue'
import { usePlanetStore } from '@/stores/planetStore.ts'
import { useGameStore } from '@/stores/gameStore.ts'
import { CDN } from '@/models/cdn.ts'
import ModalTrigger from '@/components/Interface/Modal/ModalTrigger.vue'
import DeleteButton from '@/components/Elements/Buttons/DeleteButton.vue'
import { useAssetStore } from '@/stores/assetStore.ts'
import { useShipDesignStore } from '@/stores/shipDesignStore.ts'
import Backend from '@/models/backend.ts'
import BuildingEffects from '@/components/Sections/Planets/PlanetTabs/Generic/BuildingEffects.vue'
import SimpleProgressBar from '@/components/Elements/ProgressBar/SimpleProgressBar.vue'
import GenericIcon from '@/components/Elements/Images/Icons/GenericIcon.vue'
import { useFleetStore } from '@/stores/fleetStore.ts'

/**
 * Props
 */
const props = defineProps({
  planetId: {
    type: Number,
    required: true
  }
});

/**
 * Stores / models
 */
const planetStore = usePlanetStore()
const shipDesignStore = useShipDesignStore()
const assetStore = useAssetStore()
const gameStore = useGameStore()
const backend = new Backend()
const fleetStore = useFleetStore()

/**
 * Computed
 */
const planetData = computed(() => planetStore.findPlanetById(props.planetId)!)
const shipDesignData = computed(() => shipDesignStore.getShipDesignById(planetData.value.productionShipDesignId))
const productionObjectData = computed(() => assetStore.findProductionObjectById(planetData.value.productionObjectId))

const widthPercentage = computed(() => {
  let progress = 0;
  if (shipDesignData.value) {
    progress = (planetData.value.productionProgress / shipDesignData.value.productionCost);
  } else {
    progress = (planetData.value.productionProgress / productionObjectData.value.productionCost)
  }
  if(progress > 1) {
    progress = 1
  }
  return Math.floor(progress * 100)
});

const weeksLeft = computed(() =>
{
  let turns = 0;
  if (shipDesignData.value) {
    turns = (shipDesignData.value.productionCost - planetData.value.productionProgress)/planetData.value.productionPerTurn
  } else {
    turns = (productionObjectData.value.productionCost - planetData.value.productionProgress) / planetData.value.productionPerTurn
  }
  if(turns < 0) {
    turns = 0
  }
  return Math.ceil(turns)
});

const name = computed(() => {
  if(planetData.value.productionObjectId === 0) {
    return 'Planet Production'
  }
  if (shipDesignData.value) {
    return shipDesignData.value.name
  } else {
    return productionObjectData.value.productionObjectName
  }
});

const buildingData = computed(() => {
  return assetStore.findBuildingByCode(productionObjectData.value.objectId)
})

const constructionCost = computed(() => {
  if (shipDesignData.value) {
    return shipDesignData.value.productionCost
  } else {
    return productionObjectData.value.productionCost
  }
});

/**
 * Methods
 */
const cancelProduction = () => {
  backend.cancelPlanetProductionObject(planetData.value.id);
  gameStore.setTinySuccessToastMessage('Production cancelled');
}
</script>

<template>
  <StandardCard :title="name">
    <div class="flex flex-col"  v-if="planetData.productionObjectId>0">
      <!--- Image --->
      <div class="flex justify-between">
        <div class="flex-shrink-0">
          <ModalTrigger v-if="productionObjectData.type=='building'" type="building" :data="{'buildingCode': productionObjectData.objectId}">
            <img
              :src="CDN.building(128,productionObjectData.objectId)" class="rounded h-20" alt=""
            />
          </ModalTrigger>
          <img
            v-else
            :src="CDN.ship(128)"
            class="rounded h-20"
            alt="">
        </div>
        <div class="flex flex-grow flex-col">
          <div class="flex-grow text-subtext ps-2">
            <span v-if="planetData.productionShipFleetId>0" class="pt-3">Build ship and assign to fleet <b class="text-slate-400">{{ fleetStore.getFleetNameById(planetData.productionShipFleetId) }}</b></span>
            <span v-else>{{ productionObjectData.description }}</span>
          </div>
          <!--- Icons --->
          <div class="flex justify-between">
            <div class="flex justify-start">
              <div class="flex items-center pt-1 ps-2">
                <BuildingEffects v-if="buildingData" :building="buildingData"/>
              </div>
            </div>
            <div class="flex items-end">
              <DeleteButton @click="cancelProduction" :for-icon="true">
                <GenericIcon tooltip="Delete" type="trash" />
              </DeleteButton>
            </div>
          </div>
        </div>
      </div>
      <div class="pt-2 m-2 mt-3">
        <SimpleProgressBar :percentage="widthPercentage" color="blue" :height="5" :text="weeksLeft + ' Weeks'"/>
      </div>
    </div>
    <div v-else class="p-4 text-center text-subtext">
      Nothing is being produced at the moment, select something on the sidebar to build or setup an automation queue.
    </div>
  </StandardCard>
</template>

<style scoped lang="scss">

</style>