<script setup lang="ts">
import { ref, watch, onMounted } from 'vue'
import { useGameStore } from '@/stores/gameStore.ts'

const gameStore = useGameStore()
const videoElement = ref<HTMLVideoElement | null>(null)

const props = defineProps({
  size: {
    type: Number,
    required: true,
    validator: (value: number) => [32, 64, 96, 128, 160, 192].includes(value)
  }
})

const updateVideo = () => {
  if (videoElement.value) {
    videoElement.value.load() // Reload video
  }
}

watch(() => props.size, updateVideo)

onMounted(updateVideo)
</script>

<template>
  <video ref="videoElement" autoplay loop muted playsinline>
    <source :src="`${gameStore.cdn}ferion/videos/stars/blue_dark/${size}x${size}.webm`" type="video/webm">
  </video>
</template>