import {useGameStore} from "@/stores/gameStore";
import type Turn from "@/types/PublicRouter/Turn";

export default class PublicRouter {

    private gameStore = useGameStore()

    public route(event: string, data: Array<never>): void {
        console.log('Routing public event: ' + event)
        switch (event) {
            case 'turn':
                // Sync Initial Map Data
                this.turn(data)
                break
            default:
                console.log('Unknown event: ' + event)
                break
        }
    }

    private turn(data: Array<never>): void {
        const eventData = data as unknown as Turn
        this.gameStore.turn = eventData.turn
        this.gameStore.dayTurnedAt = (Date.now() / 1000) - eventData.dayOffset;
        this.gameStore.weekTurnedAt = (Date.now() / 1000) - eventData.weekOffset
    }
}
