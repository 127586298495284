<script setup lang="ts">

import type PlayerOreTrade from '@/types/PlayerOreTrade.ts'
import { type PropType } from 'vue'
import { useGameStore } from '@/stores/gameStore.ts'
import { formatShort } from '@/models/formatNumber.ts'
import GenericIconWithColoredNumber from '@/components/Elements/Images/Icons/GenericIconWithColoredNumber.vue'
import GenericIcon from '@/components/Elements/Images/Icons/GenericIcon.vue'
import ColoredNumber from '@/components/Elements/Generic/ColoredNumber.vue'

const props = defineProps({
  oreId: {
    type: Number,
    required: true
  },
  oreTrade: {
    type: Object as PropType<PlayerOreTrade>,
    required: true
  }
})

const gameStore = useGameStore()


</script>

<template>
<div class="p-1 flex justify-between flex-shrink-0">
  <GenericIcon :type="'ore' + props.oreTrade.fromOreId" />
  <GenericIcon type="right" class="grayscale"/>
  <GenericIcon :type="'ore' + props.oreTrade.toOreId" />
</div>
</template>

<style scoped lang="scss">

</style>