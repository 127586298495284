<script setup lang="ts">

import StandardCard from '@/components/Elements/Cards/StandardCard.vue'
import ActionButton from '@/components/Elements/Buttons/ActionButton.vue'
import DeleteButton from '@/components/Elements/Buttons/DeleteButton.vue'
import { getMessaging, getToken } from 'firebase/messaging'
import Backend from '@/models/backend.ts'
import { ref } from 'vue'
import { useGameStore } from '@/stores/gameStore.ts'
import { useNewsStore } from '@/stores/newsStore.ts'
import NewsNotificationDevice from '@/components/Sections/News/Notifications/Devices/NewsNotificationDevice.vue'

/**
 * Stores
 */
const gameStore = useGameStore()
const newsStore = useNewsStore()

/**
 * Variables
 */
const errorMessage = ref('');
const messaging = getMessaging();

/**
 * Functions
 */
async function requestPermissionAndGetToken() {
  errorMessage.value = ''
  try {
    const permission = await Notification.requestPermission()
    if (permission === 'granted') {
      const currentToken = await getToken(messaging, {
        vapidKey: 'BEHw7ok0SL3e4Nv3ZYbT3foyiZGeTvVcKi4t9KQ7-kHg6sutrL44Z7QyS7RItIjC5b_7d_NWrXmFkDLi2u8pVIk'
      })

      if (currentToken) {
        const backend = new Backend()
        await backend.postFireBaseToken(currentToken).then(() => {
          gameStore.setTinySuccessToastMessage('Device added')
        })
      } else {
        errorMessage.value = 'No registration token available.'
      }
    } else {
      errorMessage.value = 'Notification permission not granted.'
    }
  } catch (err) {
    console.error('Error retrieving token:', err)
    errorMessage.value = 'An error occurred while retrieving token.'
  }
}
</script>

<template>
  <div>
    <StandardCard title="Receive notifications">
      <div>
        <p class="pb-2">You can receive your news messages directly to your desktop or mobile device. Simply add a new device here, and select from with news messages you wish to receive notifications.</p>
        <ActionButton @click="requestPermissionAndGetToken">Add new device</ActionButton>
        <div class="pt-2 text-red-500" v-if="errorMessage">{{errorMessage}}</div>
      </div>
    </StandardCard>
    <StandardCard v-if="newsStore.firebaseTokens.size>0" title="Devices registered" :no-padding="true">
      <div class="pt-2 px-2">
        <template v-for="token in newsStore.firebaseTokens.values()" :key="token.id">
          <NewsNotificationDevice :id="token.id" :name="token.name"/>
        </template>
      </div>
    </StandardCard>
  </div>
</template>

<style scoped lang="scss">

</style>