<script setup lang="ts">

import type NewsItem from "@/types/NewsItem.ts";
import NewsItemBaseStructure from '@/components/Sections/News/Types/Base/NewsItemBaseStructure.vue'
import NewsItemBase from '@/components/Sections/News/Types/Base/NewsItemBase.vue'
import PlayerName from '@/components/Objects/PlayerName.vue'
import { usePlayerRelationStore } from '@/stores/playerRelations.ts'
import { computed } from 'vue'

const playerRelationStore = usePlayerRelationStore();

/**
 * Props
 */
const props = defineProps<{
  newsItem: NewsItem,
  toast: boolean
}>();

/**
 * Computed
 */
const playerImage = computed(() => {
  return playerRelationStore.getProfileImageByPlayerId(props.newsItem.contentData.playerId);
});

</script>

<template>
  <NewsItemBase
    :toast="toast"
    :toast-image="playerImage"
    :toast-text="'Enemy spy caught!'"
  >
    <NewsItemBaseStructure
      :newsItem="props.newsItem"
      :item-image="playerImage"
      :header="'Enemy spy caught!'"
    >
      A spy from <PlayerName :player-id="props.newsItem.contentData.playerId" /> was apprehended during a {{ props.newsItem.contentData.missionName }} mission against you. Thanks to your counter-espionage team, the spy was successfully neutralized.
    </NewsItemBaseStructure>
  </NewsItemBase>
</template>

<style scoped lang="scss">

</style>