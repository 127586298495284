<script setup lang="ts">

import ConveyorTLBR from '@/components/Sections/Resources/Elements/Conveyor/ConveyorTLBR.vue'

import { defineProps, computed } from 'vue'
import type { PropType } from 'vue';
import { useGameStore } from '@/stores/gameStore.ts'
import type OreConversion from '@/types/OreConversion.ts'
import ConveyorTRBL from '@/components/Sections/Resources/Elements/Conveyor/ConveyorTRBL.vue'
import ConveyorTB from '@/components/Sections/Resources/Elements/Conveyor/ConveyorTB.vue'
import Ore from '@/components/Sections/Resources/Elements/PlanetOre/Ore.vue'
import { usePlanetStore } from '@/stores/planetStore.ts'
import Icon from '@/components/Objects/Icons/Icon.vue'
import IconCount from '@/components/Objects/IconCount.vue'
import { CDN } from '@/models/cdn.ts'

const gameStore = useGameStore()
const planetStore = usePlanetStore()

const props = defineProps({
  left: {
    type: Number,
    required: true
  },
  top: {
    type: Number,
    required: true
  },
  height: {
    type: Number,
    required: true
  },
  width: {
    type: Number,
    required: true
  },
  oreLeft: {
    type: Number,
    required: true
  },
  oreRight: {
    type: Number,
    required: true
  },
  oreProcessed: {
    type: Number,
    required: true
  },
  conversionLeft: {
    type: Object as PropType<OreConversion>,
    required: true
  },
  conversionRight: {
    type: Object as PropType<OreConversion>,
    required: true
  },
  processed: {
    type: Object as PropType<OreConversion>,
    required: true
  },
  conveyorType: {
    type: String,
    required: false,
    default: 'level1'
  },
  buildingType: {
    type: String,
    required: true
  },
  debug: {
    type: Boolean,
    required: false,
    default: false
  }
})

const factoryCount = computed(() => {
  return planetStore.countBuildingsOfType(props.buildingType)
})

const onRefineryClick = () => {
  gameStore.modalType = 'oreRefinery'
  gameStore.modalData = {
    inputOreLeftId: props.oreLeft,
    inputOreRightId: props.oreRight,
    outputOreId: props.oreProcessed,
    capacity: props.processed.capacity,
    buildingType: props.buildingType
  }
}

</script>

<template>
<div :class="{
          'absolute':true,
          'border':debug
        }"
     :style="'top:' + top + 'rem;left:' + left + 'rem;width:' + width + 'rem;height:' + height + 'rem;'">

  <!-- Level 1 Conveyor -->
  <ConveyorTLBR
    v-if="conveyorType == 'level1'"
    :left="1.25"
    :top="1"
    :height="18"
    :width="2.5"
    :midTime="0.15"
    :min-speed="5"
    :object-image="CDN.icon('ore' + oreLeft)"
    :conversion="conversionLeft"
    :debug="false"
  />

  <ConveyorTRBL
    v-if="conveyorType == 'level1'"
    :left="4.25"
    :top="1"
    :height="18"
    :width="2.5"
    :midTime="0.15"
    :min-speed="5"
    :object-image="CDN.icon('ore' + oreRight)"
    :conversion="conversionRight"
    :debug="false"
  />

  <!-- Level 2 Conveyor -->

  <ConveyorTLBR
    v-if="conveyorType == 'level2'"
    :left="-4.25"
    :top="1"
    :height="18"
    :width="8"
    :midTime="0.3"
    :min-speed="5"
    :object-image="CDN.icon('ore' + oreLeft)"
    :conversion="conversionLeft"
    :debug="false"
  />

  <ConveyorTRBL
    v-if="conveyorType == 'level2'"
    :left="4.25"
    :top="1"
    :height="18"
    :width="8"
    :midTime="0.3"
    :min-speed="5"
    :object-image="CDN.icon('ore' + oreRight)"
    :conversion="conversionRight"
    :debug="false"
  />

  <!-- Level 3 Conveyor -->

  <ConveyorTLBR
    v-if="conveyorType == 'level3'"
    :left="-14.25"
    :top="1"
    :height="18"
    :width="18"
    :midTime="0.5"
    :min-speed="8"
    :object-image="CDN.icon('ore' + oreLeft)"
    :conversion="conversionLeft"
    :debug="false"
  />

  <ConveyorTRBL
    v-if="conveyorType == 'level3'"
    :left="4.25"
    :top="1"
    :height="18"
    :width="18"
    :midTime="0.5"
    :min-speed="8"
    :object-image="CDN.icon('ore' + oreRight)"
    :conversion="conversionRight"
    :debug="false"
  />

  <div :class="{
            'absolute rounded bg-slate-800':true,
            'opacity-50':debug
          }"
       style="top:18rem;left:0;width:8rem;z-index:15"
  >
    <div class="flex-col p-1 cursor-pointer" @click="onRefineryClick">
      <img
        :class="{
          'rounded object-cover' : true,
          'grayscale': factoryCount == 0
        }"
        alt=""
        :src="CDN.building(128,props.buildingType)">
      <div class="text-center pt-1" v-if="factoryCount > 0"><Icon type="ore_refinery"/> <IconCount :no-plus="true" :amount="factoryCount"></IconCount></div>
      <div class="text-center pt-1" v-else>No Factories</div>
    </div>
  </div>

  <ConveyorTB
    :left="3.5"
    :top="27"
    :height="6"
    :width="2.5"
    :object-image="CDN.icon('ore' + props.oreProcessed)"
    :conversion="processed"
  />

  <Ore
    :ore-id="props.oreProcessed"
    :top="32"
    :left="1.5"
    :debug="debug"
  />

</div>
</template>

<style scoped lang="scss">

</style>